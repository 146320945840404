import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Divider, Button, Table, Tag, Form, Modal } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { useStateValue } from "../../state";
import { manutencaoActions } from "../../actions";
import { ModalOutroContato } from "../../components/modals/";

export default function OutrosContatos(editando) {
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [dadosOutrosContatos, setDadosOutrosContatos] = useState(editando ? (manutencao?.dados?.pessoaoutrocontatos ?? []) : []);
    const [form] = Form.useForm();
    const tagsData = ['Ativo'];
    const [openModalOutroContato, setOpenModalOutroContato] = useState(false);

    const editaOutroContato = (record, indice) => {
        record.indice = indice;
        form.setFieldsValue(record);
        setOpenModalOutroContato(true);
    };

    const alterarPropriedadeOutrosContatos = (indice, coluna, checked) => {
        const newData = [...dadosOutrosContatos];
        newData[indice][coluna] = checked;
        setDadosOutrosContatos(newData);
    };

    const removeOutroContato = (record, indice) => {
        const newData = [...dadosOutrosContatos];
        record.indice = indice;
        const index = newData.findIndex((item) => indice === item.indice);
        newData.splice(index, 1)
        setDadosOutrosContatos(newData);
    };

    const modalExcluirOutroContato = (record, indice) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja excluir o Contato ${record.out_descricao} - ${record.toc_descricao} ?`,
            okText: 'Excluir',
            cancelText: 'Cancelar',
            zIndex: 2010,
            centered: true,
            onOk() {
                removeOutroContato(record, indice);
            }
        });
    };

    useEffect(() => {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ pessoaoutrocontatos: dadosOutrosContatos } } } });

    }, [dadosOutrosContatos]);

    return (

        <div>
            <div>
                <Row align="middle">
                    <Col span={24}>
                        <Typography.Title level={3}>
                            <img src={require("../../assets/i-contato.png")} alt="Outros Contatos" /> Outros Contatos - (OPCIONAL)
                        </Typography.Title>
                        <Divider orientation="left" plain>
                            Adicione o(s) outro(s) contato(s) do cliente aqui.
                        </Divider>
                    </Col>
                </Row>
                <Row align="middle" justify="end">
                    <Col>
                        <div className="i-Position p-relative">
                            <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => {
                                form.resetFields();
                                form.setFieldsValue({ out_ativo: true });
                                setOpenModalOutroContato(true);
                            }} />
                        </div>
                    </Col>
                </Row>
            </div>
            <div>
                <div className="tabela mt-dif">
                    <Table columns={
                        [
                            {
                                title: 'Tipo de Contato',
                                render: ({ toc_descricao }) => (
                                    <div>
                                        <b>{toc_descricao}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Descrição',
                                render: ({ out_descricao }) => (
                                    <div>
                                        <b>{out_descricao}</b>
                                    </div>
                                ),
                            },
                            {
                                title: '',

                                render: (record, obj, indice) => (
                                    tagsData.map(tag => (
                                        <Tag.CheckableTag
                                            key="out_ativo"
                                            checked={record.out_ativo}
                                            onChange={(checked) => alterarPropriedadeOutrosContatos(indice, "out_ativo", checked)}
                                        >
                                            {tag}
                                        </Tag.CheckableTag>
                                    ))
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                align: 'center',
                                key: 'x',
                                width: 65,
                                fixed: 'right',
                                render: (record, obj, indice) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button onClick={() => editaOutroContato(record, indice)} icon={<EditOutlined />} />
                                            </Col>
                                            <Col>
                                                <Button onClick={() => modalExcluirOutroContato(record, indice)} icon={<DeleteOutlined />} />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]} locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Ainda não possui Outro Contato Cadastrado
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }} scroll={{ x: 960 }} dataSource={dadosOutrosContatos}
                    />
                </div>
            </div>
            <ModalOutroContato form={form} listaOutrosContatos={{ dadosOutrosContatos, setDadosOutrosContatos }} exibirModalOutroContato={openModalOutroContato} fecharModalOutroContato={() => setOpenModalOutroContato(false)} />
        </div>

    );

}