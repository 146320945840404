import React, { useEffect, useState } from "react";
import { Tag, InputNumber, Typography, Row, Col, Table, Form, Button, Modal, notification } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, FormOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import moment from 'moment';

import api from "../../services/api";
import { FormatNumber } from "../../ValueObjects";
import { Data, InputPreco } from "../../components";
import { diferencaDatas, info } from "../../services/funcoes";

export default function ReparcelamentoParcelas({ aoSalvar, carregando, listaParcelasReparcelar, listaTotalizadoresReparcelar, formulario, dadosCliente, parcelasGeradas }) {

    const [dadosReparcelamento, setDadosReparcelamento] = useState({});
    const [editandoParc, setEditandoParc] = useState(false);
    const [renderizar, setRenderizar] = useState(false);

    useEffect(() => {
        setRenderizar(true);
    }, [editandoParc]);

    useEffect(() => {
        if (renderizar) {
            setRenderizar(false);
        }
    }, [renderizar]);

    function reparcelar(values) {
        values = { ...dadosReparcelamento };
        values.parcelas = parcelasGeradas.parcelasGeradas;
        values.parcelasQuitadas = listaParcelasReparcelar;
        values.pes_id = dadosCliente.pes_id;
        carregando(true);
         api.post('Reparcelamento/Incluir', values).then(res => {
            if (res.data) {
                Modal.success({
                    content: 'Reparcelamento realizado com sucesso!',
                });
            }
        }, err => {
            info('Não foi possivel realizar o reparcelamento!');
        }).finally(() => {
            aoSalvar();
            carregando(false);
        });
    };

    function gerarParcelas() {
        if (!!formulario.getFieldValue().numeroParcelas) {
            let valorParcela = parseFloat(listaTotalizadoresReparcelar.valorAReceber) / formulario.getFieldValue().numeroParcelas;
            let novaData = new Date();
            let dataInclusao = new Date();
            let somaDasParcelas = 0;
            let parcelarGeradas = [];
            let dados = {};
            dados.cpr_descricao = 'Reparcelamento de Parcelas';
            dados.ntf_id = null;
            dados.cpr_numerodocumento = '';
            dados.cpr_numeroparcelas = formulario.getFieldValue().numeroParcelas;
            dados.cpr_pagarreceber = 1;
            dados.cpr_observacao = '';
            dados.prc_periodicidade = null;
            dados.cpr_intervalodias = null;
            dados.cpr_datainclusao = dataInclusao;
            dados.cpr_contaestornadarelacionada = null;
            dados.pessoaAutorizadaId = null;
            dados.contaGerencial = { ctg_id: null };
            dados.contaCorrente = { ctc_id: null };
            setDadosReparcelamento(dados);

            for (let i = 0; i < formulario.getFieldValue().numeroParcelas; i++) {
                novaData.setDate(novaData.getDate() + 30);
                if (i !== 0) {
                    somaDasParcelas += parseFloat(valorParcela.toFixed(2));
                }
                let parcela = {
                    cpr_datainclusao: dataInclusao,
                    cpr_id: null,//não pode ser nulo...
                    cpr_numerodocumento: null,
                    cpr_numeroparcelas: formulario.getFieldValue().numeroParcelas,
                    loc_id: null,
                    mcc_datapagamento: null,
                    ntf_id: null,
                    prc_codigobarras: null,//criar no momento de incluir o registro no banco
                    prc_datavencimento: novaData,
                    prc_estornado: false,
                    prc_id: null,
                    prc_numeroparcela: i + 1,
                    statusParcela: null,
                    prc_valor: valorParcela,
                    valorDesconto: 0,
                    valorJuros: 0,
                    ctg_id: null,
                    ctc_id: null,
                    bol_id: null,
                    fpg_id: null,
                    cpg_id: null
                }
                novaData = new Date(parcela.prc_datavencimento);
                parcelarGeradas.push(parcela);
            }
            if (somaDasParcelas > 0) {
                parcelarGeradas[0].prc_valor = parseFloat((parseFloat(listaTotalizadoresReparcelar.valorAReceber) - somaDasParcelas).toFixed(2));
            }
            parcelasGeradas.setParcelasGeradas(parcelarGeradas);
        }
    };

    function ajustaDatasParcelas(record, idx) {
        let listaTemp = [...parcelasGeradas.parcelasGeradas];
        if (listaTemp[idx - 1] != null) {
            if (diferencaDatas(listaTemp[idx - 1].prc_datavencimento, new Date(formulario.getFieldValue(`prc_datavencimento${idx + 1}`)))); {
                notification.warning({ message: 'Aviso!', description: 'Intervalo das parcelas maior do que um mês!' });
                formulario.setFieldsValue({ [`prc_datavencimento${idx + 1}`]: listaTemp[idx].prc_datavencimento });
                return false;
            }
        }
        listaTemp[idx].prc_datavencimento = moment(new Date(formulario.getFieldValue(`prc_datavencimento${idx + 1}`)));
        for (let i = idx; i < listaTemp.length; i++) {
            if (listaTemp[i + 1] != null) {
                let dataVencimento = moment(new Date(new Date(formulario.getFieldValue(`prc_datavencimento${i + 1}`)).setDate(new Date(formulario.getFieldValue(`prc_datavencimento${i + 1}`)).getDate() + 30)));
                formulario.setFieldsValue({ [`prc_datavencimento${i + 2}`]: dataVencimento });
                listaTemp[i + 1].prc_datavencimento = moment(new Date(new Date(listaTemp[i].prc_datavencimento).setDate(new Date(listaTemp[i].prc_datavencimento).getDate() + 30)));
            }
        }
        parcelasGeradas.setParcelasGeradas(listaTemp);
    }

    function onChangeValorParc(record) {
        let valorJaUtilizado = 0;
        let valorRestante = 0;
        let valorRestanteTot = 0;
        let valorParcelas = 0;
        let qtdeTotParcelas = parcelasGeradas.parcelasGeradas.length;
        let valorTotal = parseFloat(listaTotalizadoresReparcelar.valorAReceber);
        let dados = formulario.getFieldsValue();
        for (let i = 1; i <= record; i++) {
            valorJaUtilizado += parseFloat(dados[`prc_valor${i}`]);
        }
        valorRestante = valorTotal - valorJaUtilizado;
        valorParcelas = parseFloat((valorRestante / (qtdeTotParcelas - record)).toFixed(2));
        for (let i = qtdeTotParcelas; i > record; i--) {
            valorRestanteTot += valorParcelas;
            formulario.setFieldsValue({ [`prc_valor${i}`]: valorParcelas });
        }
        let listaTemp = [...parcelasGeradas.parcelasGeradas];
        for (let a = 0; a < qtdeTotParcelas; a++) {
            listaTemp[a].prc_valor = formulario.getFieldValue(`prc_valor${a + 1}`);
        }
        parcelasGeradas.setParcelasGeradas(listaTemp);
        let valorSobra = valorTotal - (valorJaUtilizado + valorRestanteTot);
        formulario.setFieldsValue({ [`prc_valor${qtdeTotParcelas}`]: parseFloat((valorParcelas + valorSobra).toFixed(2)) });
    }



    return (
        <div>
            <Typography.Title level={5}>
                Parcelas Vencidas
            </Typography.Title>
            <div className="tabela">
                <Table pagination={false} dataSource={listaParcelasReparcelar} columns={
                    [
                        {
                            title: 'Parcela',
                            width: 75,
                            render: ({ prc_numeroparcela, cpr_numeroparcelas }) => (
                                <Tag color="processing" className="w-100">
                                    <b>{prc_numeroparcela} - {cpr_numeroparcelas}</b>
                                </Tag>
                            ),
                        },
                        {
                            title: 'Vencimento',
                            width: 100,
                            align: 'center',
                            render: ({ prc_datavencimento, vencida }) => (
                                <div>
                                    <b className={vencida ? "c-red" : ""}>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Valor Parcela (R$)',
                            align: 'right',
                            render: ({ valorOriginal }) => (
                                <div>
                                    <b>
                                        {FormatNumber(!!valorOriginal ? valorOriginal : 0, true)}
                                    </b>
                                </div>
                            ),
                        }, {
                            title: 'Valor Recebido Parcela (R$)',
                            align: 'right',
                            render: ({ valorQuitado }) => (
                                <div>
                                    <b>
                                        {FormatNumber(!!valorQuitado ? valorQuitado : 0, true)}
                                    </b>
                                </div>
                            ),
                        }, {
                            title: 'Juros (R$)',
                            align: 'right',
                            render: ({ valorJuros }) => (
                                <div>
                                    <b className={!!valorJuros && parseFloat(valorJuros) > 0 ? 'c-red' : ''}>
                                        {FormatNumber(!!valorJuros ? valorJuros : 0, true)}
                                    </b>
                                </div>
                            ),
                        }, {
                            title: 'Valor Total (R$)',
                            align: 'right',
                            render: ({ valorOriginal, valorQuitado }) => (
                                <div>
                                    <b>
                                        {FormatNumber((!!valorOriginal ? valorOriginal : 0) - (!!valorQuitado ? valorQuitado : 0), true)}
                                    </b>
                                </div>
                            ),
                        },
                    ]
                } expandable={{
                    expandedRowRender: ({ prc_codigobarras, cpr_numerodocumento }) =>
                        <div>
                            <Row gutter={[8, 0]}>
                                <Col>
                                    Cód. Barras: <b> {prc_codigobarras || 'Não Informado'}</b>
                                </Col>
                                <Col>
                                    Nº Documento: <b> {cpr_numerodocumento || 'Não Informado'}</b>
                                </Col>
                            </Row>
                        </div>,
                    expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                            <ArrowUpOutlined onClick={e => onExpand(record, e)} />
                        ) : (
                            <ArrowDownOutlined onClick={e => onExpand(record, e)} />
                        )
                }} columnWidth={30} scroll={{ x: 900 }} />
            </div>
            <Form layout="vertical" form={formulario} name="formRecebimentoParcelas" onFinish={reparcelar}>
                {parcelasGeradas.parcelasGeradas.length === 0 &&
                    <Row gutter={[8, 0]} className="m-t-16">
                        <Col xxl={4} xl={4} lg={5} md={5} sm={16} xs={24}>
                            <Form.Item label="Número de Parcelas" name="numeroParcelas">
                                <InputNumber
                                    step={1}
                                    min={1}
                                    controls={false}
                                    placeholder="Número de Parcelas"
                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={4} xl={5} lg={6} md={6} sm={8} xs={24} className="t-mob-573">
                            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.numeroParcelas !== currentValues.numeroParcelas}>
                                {({ getFieldValue }) => {
                                    if (getFieldValue('numeroParcelas') > 0) return (
                                        <Button onClick={() => gerarParcelas()} type="primary" icon={<FormOutlined />} block>
                                            Gerar Parcelas Novas
                                        </Button>
                                    );
                                    else return (
                                        <Button disabled onClick={() => gerarParcelas()} type="primary" icon={<FormOutlined />} block>
                                            Gerar Parcelas Novas
                                        </Button>
                                    );
                                }}
                            </Form.Item>
                        </Col>
                    </Row>
                }
                {parcelasGeradas.parcelasGeradas.length > 0 &&
                    <div className="m-t-16">
                        <Typography.Title level={5}>
                            Parcelas Geradas
                        </Typography.Title>
                        <div className="tabela">
                            <Table dataSource={parcelasGeradas.parcelasGeradas} columns={
                                [
                                    {
                                        title: 'Parcela',
                                        width: 75,
                                        align: 'center',
                                        render: ({ prc_numeroparcela, cpr_numeroparcelas }) => (
                                            <Tag color="processing" className="w-100">
                                                <b>{prc_numeroparcela} - {cpr_numeroparcelas}</b>
                                            </Tag>
                                        ),
                                    },
                                    {
                                        title: 'Vencimento',
                                        align: 'center',
                                        width: 110,
                                        shouldCellUpdate: (() => { return renderizar }),
                                        render: ({ prc_datavencimento, prc_numeroparcela }, record, idx) => (
                                            <div>
                                                {editandoParc ? <Data initialValue={prc_datavencimento} name={`prc_datavencimento${prc_numeroparcela}`} onBlur={() => ajustaDatasParcelas(record, idx)} /> : <b>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Valor Parcela (R$)',
                                        align: 'right',
                                        shouldCellUpdate: (() => { return renderizar }),
                                        render: ({ prc_valor, prc_numeroparcela }) => (
                                            <div>
                                                {editandoParc ? <InputPreco name={`prc_valor${prc_numeroparcela}`} initialValue={prc_valor} onBlur={() => onChangeValorParc(prc_numeroparcela)} disabled={prc_numeroparcela === parcelasGeradas.parcelasGeradas.length} /> : <b>{FormatNumber(prc_valor, true)}</b>}
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Valor Recebido Parcela (R$)',
                                        align: 'right',
                                        render: ({ valorQuitado }) => (
                                            <div>
                                                <b>
                                                    {FormatNumber(!!valorQuitado ? valorQuitado : 0, true)}
                                                </b>
                                            </div>
                                        )
                                    },
                                    {
                                        title: 'Juros (R$)',
                                        align: 'right',
                                        render: ({ valorJuros }) => (
                                            <div>
                                                <b className={!!valorJuros && parseFloat(valorJuros) > 0 ? 'c-red' : ''}>
                                                    {FormatNumber(!!valorJuros ? valorJuros : 0, true)}
                                                </b>
                                            </div>
                                        )
                                    },
                                    {
                                        title: 'Valor Total (R$)',
                                        align: 'right',
                                        render: ({ prc_valor }) => (
                                            <div>
                                                <b>
                                                    {FormatNumber(prc_valor, true)}
                                                </b>
                                            </div>
                                        )
                                    },
                                    {
                                        title: 'Ações',
                                        align: 'center',
                                        width: 65,
                                        fixed: 'right',
                                        render: () => (
                                            <Row align="center" gutter={[5, 0]}>
                                                <Col>
                                                    {!editandoParc ? <Button icon={<EditOutlined />} onClick={() => setEditandoParc(true)} /> : <Button icon={<SaveOutlined />} onClick={() => setEditandoParc(false)} />}
                                                </Col>
                                            </Row>
                                        )
                                    }
                                ]
                            } scroll={{ x: 900 }} />
                        </div>
                    </div>
                }
            </Form>
        </div>
    )
}