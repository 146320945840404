import React, { useEffect, useState } from "react";
import { Row, Col, Form, InputNumber, Select, Button, Tag, Radio, Card, Modal, notification, Input } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined, ExclamationCircleOutlined, CalculatorOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { FormatNumber } from "../../../ValueObjects";

export default function ModalCalcularLimite({ formulario, exibirModal, fecharModal }) {

    const [step, setStep] = useState(0);
    const [estadoCivil, setEstadoCivil] = useState([]);
    const [mostraRendaConj, setmostraRendaConj] = useState(false);
    const [valorsimulado, setvalorsimulado] = useState();
    const [listaQuestoes, setListaQuestoes] = useState([]);
    const [parametrosEmpresa, setParametrosEmpresa] = useState({});
    const [form] = Form.useForm();
    const [dadosResposta, setDadosResposta] = useState([]);
    const [valores, setValores] = useState({});

    useEffect(() => {
        listarEstadoCivil();
        buscarQuestoes();
        buscarParametrosEmpresa()
    }, []);

    const onNext = () => onChange(step + 1);
    const onPrevious = () => onChange(step - 1);
    const onChange = (nextStep) => {
        setStep(nextStep);
    };

    function showRendaConjunta(ecv_id) {
        if (ecv_id === 2) {
            setmostraRendaConj(true);
        } else {
            setmostraRendaConj(false);
        }
    }

    function listarEstadoCivil() {
        api.get(`EstadoCivil/Listar`).then(
            res => {
                if (res.status === 200) {
                    setEstadoCivil(res.data.items);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    }
    function buscarQuestoes() {
        api.get(`QuestaoLimiteCredito/Listar`).then(
            res => {
                setListaQuestoes(res.data.items);
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    }

    function buscarParametrosEmpresa() {
        api.get(`ParametroEmpresa/BuscarParametros`).then(
            res => {
                setParametrosEmpresa(res.data);
            }
        ).catch(
            erro => { console.log(erro); }
        )
    }

    function adicionarResposta(registro, pergunta) {
        let respostas = [...dadosResposta];
        let id = registro.target.value;
        let dadoPergunta = { ...pergunta };
        dadoPergunta.respostalimitecreditos = pergunta.respostalimitecreditos.filter(respostas => (respostas.rlc_id === id))[0];
        let respEditar = respostas.find((resp) => resp.qlc_id === pergunta.qlc_id);
        if (respEditar) {
            respEditar.respostalimitecreditos = pergunta.respostalimitecreditos.filter(respostas => (respostas.rlc_id === id))[0];
        } else {
            respostas.push(dadoPergunta);
        }
        setDadosResposta(respostas);
    };

    useEffect(() => {
        if (!!dadosResposta && dadosResposta.length > 0) {
            calculoSimulado()
        }
    }, [dadosResposta]);
    function calculoSimulado() {
        let dados = {
            pef_renda: (!!form.getFieldValue().pef_renda ? parseFloat(form.getFieldValue().pef_renda) : 0.0),
            pef_rendaconjuge: (!!form.getFieldValue().pef_rendaconjuge ? parseFloat(form.getFieldValue().pef_rendaconjuge) : 0.0),
            questionario: [dadosResposta]
        }
        let porcentagemBase = ((!!parametrosEmpresa.par_percentuallimitecredito) ? parametrosEmpresa.par_percentuallimitecredito : 30) / 100;
        let vlrbase = 0;
        let vlrsim = 0.0;
        if (!!dadosResposta) {
            let porcentagem = 0.0;
            dadosResposta.forEach((questao) => {
                if (questao.qlc_tiporesposta == 2) {
                    if (questao.respostalimitecreditos.rlc_somasubtrai == 1) {
                        porcentagem += (questao.respostalimitecreditos.rlc_percentual / 100);
                    } else {
                        porcentagem -= (questao.respostalimitecreditos.rlc_percentual / 100);
                    }
                }
            });

            if (!!dados.pef_rendaconjuge) {
                vlrbase = (dados.pef_renda + dados.pef_rendaconjuge) * porcentagemBase;
                vlrsim = vlrbase * porcentagem;
                vlrsim = (porcentagem !== 0) ? vlrbase + (vlrbase * porcentagem) : vlrbase;
            } else {
                vlrbase = dados.pef_renda * porcentagemBase;
                vlrsim = (porcentagem !== 0) ? vlrbase + (vlrbase * porcentagem) : vlrbase;
            }
        }

        setvalorsimulado(vlrsim);
        setValores(dados);
    };

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Realmente deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            zIndex: 2010,
            onOk() {
                fecharModal();
            }
        });
    };

    function salvarLimite() {
        api.post(`AnaliseCredito/GerarLimite`, valores).then(
            res => {
                notification.success({ message: 'Operação concluída', description: "Limite de Crédito foi gerado!" });
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        ).finally(
            () => {
                formulario.setFieldsValue({ cli_valorlimitecredito: valorsimulado });
                fecharModal();
            }
        );
    };

    return (
        <Modal centered title="Análise de Crédito"
            getContainer={false}
            open={exibirModal}
            onCancel={onCloseModal}
            onOk={salvarLimite}
            okText={
                <>
                    <CalculatorOutlined /> Calcular Limite
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            width={680}
        >
            <div>
                <Form layout="vertical" form={form} >
                    <Row gutter={[8, 0]}>
                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={24}>
                            <Form.Item label="Renda" name="pef_renda" onChange={() => calculoSimulado()} rules={[{ required: true, message: 'Informe a Renda.' }]}>
                                <InputNumber
                                    step={1.0}
                                    min={0}
                                    controls={false}
                                    formatter={value => `R$ ${value}`}
                                    parser={value => value.replace('R$ ', '')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={24}>
                            <Form.Item label="Estado Civil" name="ecv_id" rules={[{ required: true, message: 'Informe o Estado Civil.' }]}>
                                <Select allowClear onChange={(campo) => showRendaConjunta(campo)} placeholder="Selecione um Estado Civil">
                                    {estadoCivil.map((evc) => (
                                        <Select.Option value={evc.ecv_id} key={evc.ecv_id} label={evc.ecv_descricao}>{evc.ecv_descricao}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        {mostraRendaConj &&
                            <Col xxl={8} xl={8} lg={8} md={8} sm={8} xs={24}>
                                <Form.Item label="Renda Cônjuge" name="pef_rendaconjuge" rules={[{ required: mostraRendaConj, message: 'Informe a Renda do Conjuge.' }]}>
                                    <InputNumber
                                        step={1.0}
                                        min={0}
                                        formatter={value => `R$ ${value}`}
                                        parser={value => value.replace('R$ ', '')}
                                    />
                                </Form.Item>
                            </Col>
                        }
                    </Row>
                    <Row gutter={[0, 8]} className="m-t-8">
                        <Col span={24}>
                            <Card size="small" type="inner" title="Responda as perguntas abaixo"
                                extra={
                                    <Tag color="geekblue">
                                        {`${step + 1}/${listaQuestoes.length}`}
                                    </Tag>
                                }
                            >
                                <div>
                                    <Row gutter={[8, 8]}>
                                        <Col span={24}>
                                            {listaQuestoes.map((questoes, idx) => {
                                                return step === idx ?
                                                    <Form.Item label={questoes.qlc_questao} name={`questoes${questoes.qlc_id}`}>
                                                        {questoes.qlc_tiporesposta === 2 ?
                                                            <Radio.Group onChange={(registro) => { adicionarResposta(registro, questoes) }}>
                                                                {questoes.respostalimitecreditos.map((resposta => (
                                                                    <Radio value={resposta.rlc_id}>{resposta.rlc_resposta}</Radio>
                                                                )))}
                                                            </Radio.Group> :
                                                            <Input onChange={(registro) => { adicionarResposta(registro, questoes) }} />
                                                        }
                                                    </Form.Item> : null
                                            })}
                                        </Col>
                                    </Row>
                                    <Row align="middle" justify="space-between" gutter={[8, 8]}>
                                        <Col>
                                            <Button icon={<ArrowLeftOutlined />} onClick={onPrevious} disabled={step === 0}>
                                                Voltar
                                            </Button>
                                        </Col>
                                        <Col>
                                            <Button type="primary" onClick={onNext} disabled={step === listaQuestoes.length - 1} >
                                                Avançar <ArrowRightOutlined />
                                            </Button>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Row justify="end">
                                <Col>
                                    Simulação (R$): <b>{FormatNumber(valorsimulado, true)}</b>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}