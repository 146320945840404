import React, { useEffect, useState } from "react";
import { Layout, Menu, Row, Col } from "antd";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Icon } from '@ant-design/compatible';
import moment from "moment";

import "./index.less";
import { useStateValue } from '../../state';
import { getNomeEmpresa, getNomeLocal } from "../../services/auth";

export default function MenuPage() {

    const [{ manutencao, dadosPdv }, dispatch] = useStateValue();
    const [openKeys, setOpenKeys] = useState([]);
    const [dataAtual, setDataAtual] = useState(new Date());
    const rootKeys = ["sub1", "sub2", "sub3"];
    const navigation = useNavigate();
    const onOpenChange = (items) => {

        if (!window.caixaAberto) {
            navigation("/Home#");
            return false;
        }

        const latestOpenKey = items.find((key) => openKeys.indexOf(key) === -1);
        if (rootKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(items);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : openKeys);
        }
    };

    useEffect(() => {
        setInterval(horaAtual, 1000);
    }, []);

    function horaAtual() {
        setDataAtual(new Date());
    }

    return (
        <div>

            <Layout.Sider breakpoint="lg" collapsedWidth="0" className="menu-lateral">
                <Menu mode="inline" defaultSelectedKeys={["0"]} onOpenChange={onOpenChange} openKeys={openKeys} defaultOpenKeys={['sub1']}>
                    <Menu.Item>
                        <Link to="/home">
                            <Row justify="center">
                                <Col>
                                    <Icon type="Home" disable={true} />
                                </Col>
                                <Col span={24}>
                                    <span>Home</span>
                                </Col>
                            </Row>
                        </Link>
                    </Menu.Item>
                    <Menu.SubMenu key="sub1" title={
                        <Row justify="center">
                            <Col>
                                <Icon type="ShoppingCart" />
                            </Col>
                            <Col span={24}>
                                <span>Venda</span>
                            </Col>
                        </Row>
                    }>
                        <Menu.Item key="1">
                            <Link to="/venda">
                                <Icon type="DoubleRight" />
                                Lançar Vendas
                            </Link>
                        </Menu.Item>
                        <Menu.Item key="2">
                            <Link to="/vendaRealizada">
                                <Icon type="DoubleRight" />
                                Consultar Vendas Realizadas
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu key="sub2" title={
                        <Link to="/parcelas">
                            <Row justify="center">
                                <Col>
                                    <Icon type="ShoppingCart" />
                                </Col>
                                <Col span={24}>
                                    <span>Parcelas</span>
                                </Col>
                            </Row>
                        </Link>
                    }>
                        <Menu.Item key="1">
                            <Link to="/parcelas">
                                <Icon type="DoubleRight" />
                                Recebimento de Parcelas
                            </Link>
                        </Menu.Item>
                    </Menu.SubMenu>
                    <Menu.SubMenu key="sub3" title={
                        <Row justify="center">
                            <Col>
                                <Icon type="User" />
                            </Col>
                            <Col span={24}>
                                <span>Pessoa</span>
                            </Col>
                        </Row>
                    }>
                        <Menu.Item key="1" onClick={() => navigation("../clientes")}>
                            <Icon type="DoubleRight" />
                            Clientes
                        </Menu.Item>
                    </Menu.SubMenu>
                </Menu>
                <div className="info-PDV">
                    <Row align="middle" gutter={[0, 3]}>
                        <Col span={24}>
                            <b>CAIXA {dadosPdv?.pdv?.pdv_numerocaixa}</b>
                        </Col>
                        <Col span={24} className="f-11">
                            ONLINE
                        </Col>
                        <Col span={24} className="f-11">
                            {`${getNomeEmpresa()} / ${getNomeLocal()}`}
                        </Col>
                        <Col>
                            <b>{moment(dataAtual).format('DD/MM/YYYY')}</b> | <b>{moment(dataAtual).format('HH:mm:ss')}</b>
                        </Col>
                    </Row>
                </div>
            </Layout.Sider>
        </div>

    );

}