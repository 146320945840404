import React, { useState } from "react";
import { Result, Row, Col, Button, Modal } from "antd";
import { ExclamationCircleOutlined, CustomerServiceOutlined } from "@ant-design/icons";

import { AberturaChamado } from "../../../components";

export default function ModalAjudaRejeicaoNfe({ exibirModalAjudaRejeicaoNfe, fecharModalAjudaRejeicaoNfe }) {

    const [activeOpen, setActiveOpen] = useState(false);

    const handleOnClickOpen = () => {
        setActiveOpen(true);
    }

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalAjudaRejeicaoNfe();
            }
        });
    };

    return (
        <Modal centered
            title="Resolução de Problemas da NF-e"
            open={exibirModalAjudaRejeicaoNfe}
            getContainer={false}
            onCancel={onCloseModal}
            onOk={onCloseModal}
            maskClosable={false}
            footer={false}
        >
            <div>
                <Result title="Buscando informações sobre o código de retorno da Sefaz."
                    extra={
                        <Button type="primary" key="console"> Aguarde... </Button>
                    }
                />
                <div>
                    <Row align="middle">
                        <Col> Código - Motivo </Col>
                    </Row>
                    <Row align="middle" justify="center" className="m-t-16">
                        <Col>
                            No momento não temos nenhuma possivel solução para a rejeição da NF-e ocorrida nesta nota fiscal (cStat = 900) cadastrada em nossa base de dados.
                        </Col>
                    </Row>
                    <Row justify="center" className="m-t-16">
                        <Col>
                            <Button type="primary" icon={<CustomerServiceOutlined />} size="large" onClick={() => handleOnClickOpen()}>
                                Abrir Chamado com o Suporte
                            </Button>
                        </Col>
                    </Row>
                </div>
            </div>
            <AberturaChamado activeOpen={activeOpen} setActiveOpen={setActiveOpen} />
        </Modal>
    );
}