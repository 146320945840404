import React, { useEffect, useState } from "react";
import moment from 'moment';
import { Row, Col, Modal, Typography, Table, Divider, Tag, Tooltip, Button } from "antd";
import { ExclamationCircleOutlined, PrinterOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { useStateValue } from "../../../state";
import { FormatNumber } from '../../../ValueObjects';
import { novaAbaNavegador } from "../../../services/funcoes";

export default function ModalControleCaixa({ exibirModalControleCaixa, fecharModalControleCaixa }) {

    const [listaCaixas, setListaCaixas] = useState([]);
    const [{ dadosPdv }, dispatch] = useStateValue();
    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalControleCaixa();
            }
        });
    };

    useEffect(() => {
        if (exibirModalControleCaixa) {
            carregarDados();
        }
    }, [exibirModalControleCaixa])

    function carregarDados() {
        api.get(`ControleCaixa/ListaControleCaixa?idPdv=${dadosPdv?.pdv?.pdv_id}&SomenteFechados=true&Order=-ctx_datahorafechamento`).then(
            res => {
                setListaCaixas(res.data.items);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }

    return (
        <Modal centered
            title="Controle de Caixa"
            open={exibirModalControleCaixa}
            getContainer={false}
            onCancel={onCloseModal}
            maskClosable={false}
            width={990}
            footer={null}
        >
            <>
                <Row>
                    <Col span={24}>
                        <Typography.Text>
                            Abaixo encontra-se a lista para controle dos caixas.
                        </Typography.Text>
                    </Col>
                </Row>
                <Row className="tabela m-t-16">
                    <Col span={24}>
                        <Table
                            columns={[
                                {
                                    title: 'Nº Caixa',
                                    width: 75,
                                    align: 'center',
                                    render: ({ ctx_id }) => (
                                        <div>
                                            <Tag color="processing" className="w-100">
                                                <b>{ctx_id}</b>
                                            </Tag>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Data Abertura',
                                    width: 132,
                                    render: ({ ctx_datahoraabertura }) => (
                                        <div>
                                            <b>{moment(ctx_datahoraabertura).format("DD/MM/YYYY HH:mm:ss")}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Valor Abertura (R$)',
                                    align: 'right',
                                    render: ({ ctx_valorabertura }) => (
                                        <div>
                                            <b>{FormatNumber(ctx_valorabertura, true)}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Data Fechamento',
                                    width: 132,
                                    render: ({ ctx_datahorafechamento }) => (
                                        <div>
                                            <b>{moment(ctx_datahorafechamento).format("DD/MM/YYYY HH:mm:ss")}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Valor Fechamento (R$)',
                                    align: 'right',
                                    render: ({ ctx_valorfechamento }) => (
                                        <div>
                                            <b>{FormatNumber(ctx_valorfechamento, true)}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Quebra de Caixa (R$)',
                                    align: 'right',
                                    render: ({ ctx_quebracaixa }) => (
                                        <div>
                                            <b>{FormatNumber(ctx_quebracaixa, true)}</b>
                                        </div>
                                    ),
                                },
                                {
                                    title: 'Operação',
                                    dataIndex: '',
                                    align: 'center',
                                    key: 'x',
                                    width: 65,
                                    render: (record) => (
                                        <div>
                                            <Tooltip title="Imprimir" placement="top">
                                                <Button onClick={() => novaAbaNavegador(`ControleCaixa/Imprimir?CaixaId=${record.ctx_id}`)} block type="primary" icon={<PrinterOutlined />} />
                                            </Tooltip>
                                        </div>
                                    ),
                                },
                            ]} locale={{
                                emptyText: (
                                    <Row>
                                        <Col span={24}>
                                            <Divider orientation="center">
                                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                            </Divider>
                                        </Col>
                                    </Row>)
                            }} dataSource={listaCaixas} scroll={{ y: 297, x: 850 }} pagination={false}
                        />
                    </Col>
                </Row>
            </>
        </Modal>
    );
}