import React, { useState, useEffect } from "react";
import { Row, Col, Button, Form, Input, Popover, Tag, Tooltip } from "antd";
import { PlusOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import moment from "moment";

import api from '../../services/api';
import { useStateValue } from '../../state';
import { FormatFone, MaskFormat } from "../../ValueObjects";
import { FiltroSearch, TabelaDados, DrawerCliente, BreadcrumbPage } from "../../components";
import { ModalContatoCliente } from "../../components/modals/";
import { drawerActions, listagemActions, manutencaoActions } from "../../actions";

export default function Clientes() {

    const [{ manutencao }, dispatch] = useStateValue();
    const [dadosCliente, setDadosCliente] = useState({});
    const [editando, setEditando] = useState(false);
    const [openModalContatoCliente, setOpenModalContatoCliente] = useState(false);

    useEffect(() => {
        dispatch({ type: listagemActions.CHANGE, data: { outrosFiltros: '', filtro: '', ordem: '+pes_nome' } });
    }, []);

    function editarCliente(record) {
        record.pef_datanascimento = moment(record.pef_datanascimento);
        record.pej_datafundacao = !!record.pej_datafundacao ? moment(record.pej_datafundacao) : null;
        record.cpfCnpj = !!record.pef_cpf ? record.pef_cpf : (!!record.pej_cnpj ? record.pej_cnpj : '');
        setEditando(true);
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: record } });
        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
    };

    function abrirModalContatoCliente(dados) {
        setDadosCliente(dados)
        setOpenModalContatoCliente(true)
    }

    return (
        <Form layout="vertical">
            <BreadcrumbPage pagina="Pessoa" pagina_seleciona="Cliente" />
            <Row gutter={[16, 0]}>
                <Col xs={18} sm={18} md={20} lg={20} xl={21} xxl={22}>
                    <FiltroSearch />
                </Col>
                <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={2}>
                    <Button type="primary" icon={<PlusOutlined />} size="large" block onClick={() => {
                        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
                        dispatch({ type: drawerActions.CHANGE, data: { showDrawer: true } });
                    }} className="bt-novo">
                        NOVO
                    </Button>
                </Col>
            </Row>
            <div className="tabela">
                <TabelaDados url="cliente/listar" colunas={[
                    {
                        title: 'Código',
                        width: 75,
                        align: 'center',
                        render: ({ cli_codigo, cli_ativo }) => (
                            <Popover content={cli_ativo ? 'Ativo' : 'Inativo'} placement="right">
                                <Tag color={cli_ativo ? 'processing' : 'default'} className="w-100">
                                    <b>{cli_codigo}</b>
                                </Tag>
                            </Popover>
                        ),
                    },
                    {
                        title: 'Clientes',
                        render: ({ pes_nome }) => (
                            <div>
                                <b>{pes_nome}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Nome Fantasia',
                        render: ({ pej_nomefantasia, pef_apelido }) => (
                            <div>
                                <b>{pej_nomefantasia ?? (pef_apelido ?? 'Não Informado')}</b>
                            </div>
                        )
                    },
                    {
                        title: 'CPF/CNPJ',
                        width: 130,
                        render: ({ pef_cpf, pej_cnpj }) => (
                            <div>
                                <b>{!!pef_cpf ? MaskFormat(pef_cpf, 'Não Informado', true) : MaskFormat(pej_cnpj, 'Não Informado', true)}</b>
                            </div>
                        ),
                    }, ,
                    {
                        title: 'Telefone',
                        width: 115,
                        render: ({ psc_numero }) => (
                            <div>
                                <b>{FormatFone(psc_numero) || 'Não Informado'}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Endereço',
                        render: ({ log_logradouro, pee_numero, bai_nome, cep_cep, cid_descricao, est_sigla }) => (
                            <div>
                                <b>{log_logradouro || 'Não Informado'}{(!!pee_numero) ? (', ' + pee_numero) : ''}{!!(bai_nome) ? (', ' + bai_nome) : ''} {!!(cep_cep) ? ('- ' + cep_cep) : ''} {!!(cid_descricao) ? ('- ' + cid_descricao) : ''} {!!(est_sigla) ? ('- ' + est_sigla) : ''}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        align: 'center',
                        fixed: 'right',
                        key: 'x',
                        width: 65,
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button onClick={() => editarCliente(record)} icon={<EditOutlined />} />
                                    </Col>
                                    <Col>
                                        <Tooltip title="Ver Outros Contatos" placement="left">
                                            <Button icon={<EyeOutlined />} onClick={() => abrirModalContatoCliente(record)} />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} />
            </div>
            <DrawerCliente editando={editando} setEditando={setEditando} />
            <ModalContatoCliente dadosCliente={dadosCliente} setDadosCliente={setDadosCliente} exibirModalContatoCliente={openModalContatoCliente} fecharModalContatoCliente={() => setOpenModalContatoCliente(false)} />
        </Form>
    );
}