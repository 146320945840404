import React, { useEffect, useState } from "react";
import { Row, Col, Form, Collapse, InputNumber, Button, Typography } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

export default function Cedulas() {

    const [open, setOpen] = useState(false);
    const [form] = Form.useForm();
    const [totalMoedas, setTotalMoedas] = useState(0);
    const [totalCedulas, setTotalCedulas] = useState(0);
    const [cedulasPapel, setCedulasPapel] = useState([
        { descricao: 'R$ 100,00', valor: 0, valorFixo: 100, key: 'cedula0' },
        { descricao: 'R$ 50,00', valor: 0, valorFixo: 50.00, key: 'cedula1' },
        { descricao: 'R$ 20,00', valor: 0, valorFixo: 20.00, key: 'cedula2' },
        { descricao: 'R$ 10,00', valor: 0, valorFixo: 10.00, key: 'cedula3' },
        { descricao: 'R$ 5,00', valor: 0, valorFixo: 5.00, key: 'cedula4' },
        { descricao: 'R$ 2,00', valor: 0, valorFixo: 2.00, key: 'cedula5' }
    ])
    const [cedulasMoedas, setCedulasMoedas] = useState([
        { descricao: 'R$ 1,00', valor: 0, valorFixo: 1.00, key: 'moeda0' },
        { descricao: 'R$ 0,50', valor: 0, valorFixo: 0.50, key: 'moeda1' },
        { descricao: 'R$ 0,25', valor: 0, valorFixo: 0.25, key: 'moeda2' },
        { descricao: 'R$ 0,10', valor: 0, valorFixo: 0.10, key: 'moeda3' },
        { descricao: 'R$ 0,05', valor: 0, valorFixo: 0.05, key: 'moeda4' },
        { descricao: 'R$ 0,01', valor: 0, valorFixo: 0.01, key: 'moeda5' }
    ])

    const togglePesquisa = () => {
        setOpen(!open);
    };

    function calculaValor(cedulakey, cedula) {
        let dados = [];
        let total = 0;
        let dadosTemp = {};
        if (cedula === 'MOEDA') {
            dados = [...cedulasMoedas];
            dadosTemp = dados.filter((item) => (item.key === cedulakey))[0];
            dadosTemp.valor = dadosTemp.valorFixo * form.getFieldValue(cedulakey);
            dados.forEach((item) => {
                if (item.valor > 0) {
                    total += item.valor;
                }
            })
            setTotalMoedas(total);
            setCedulasMoedas(dados);
        } else if (cedula === 'CEDULA') {
            dados = [...cedulasPapel];
            dadosTemp = dados.filter((item) => (item.key === cedulakey))[0]
            dadosTemp.valor = dadosTemp.valorFixo * (!!form.getFieldValue(cedulakey) ? form.getFieldValue(cedulakey) : 0);
            dados.forEach((item) => {
                if (item.valor > 0) {
                    total += item.valor;
                }
            })
            setTotalCedulas(total);
            setCedulasPapel(dados);
        }
    }

    return (
        <Form form={form}>
            <Row align="middle" gutter={[0, 8]} className="page">
                <Col span={24}>
                    <Collapse accordion ghost destroyInactivePanel={true}>
                        <Collapse.Panel key="1" showArrow={false} extra={
                            <Button type="primary" onClick={togglePesquisa}>
                                {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Para especificar o valor em cédulas, Clique Aqui.
                            </Button>
                        }>
                            <Row align="middle" gutter={[8, 8]}>
                                <Col span={24}>
                                    <Typography.Text>
                                        Informe a quantidade de cada cédula.
                                    </Typography.Text>
                                </Col>
                                <Col span={24}>
                                    <Row gutter={[16, 8]}>
                                        <Col span={12} className="lista-cedulas">
                                            {cedulasPapel.map((item, idx) => (
                                                <Row align="middle">
                                                    <Col span={8}>
                                                        <Typography.Text>
                                                            {item.descricao}
                                                        </Typography.Text>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item name={`cedula${idx}`} >
                                                            <InputNumber min={0} onBlur={() => calculaValor(`cedula${idx}`, 'CEDULA')}/>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8} className="text-right">
                                                        R$ {item.valor === 0 ? item.valorFixo.toFixed(2) : item.valor.toFixed(2)}
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Row>
                                                <Col span={24} className="text-right">
                                                    <small> R$ </small>
                                                    <b className="f-16">{totalCedulas.toFixed(2)}</b>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={12} className="lista-cedulas">
                                            {cedulasMoedas.map((item, idx) => (
                                                <Row align="middle">
                                                    <Col span={8}>
                                                        <Typography.Text>
                                                            {item.descricao}
                                                        </Typography.Text>
                                                    </Col>
                                                    <Col span={8}>
                                                        <Form.Item name={`moeda${idx}`} onChange={() => calculaValor(`moeda${idx}`, 'MOEDA')}>
                                                            <InputNumber min={0} />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={8} className="text-right">
                                                        R$ {item.valor === 0 ? item.valorFixo.toFixed(2) : item.valor.toFixed(2)}
                                                    </Col>
                                                </Row>
                                            ))}
                                            <Row>
                                                <Col span={24} className="text-right">
                                                    <small> R$ </small>
                                                    <b className="f-16">{totalMoedas.toFixed(2)}</b>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Collapse.Panel>
                    </Collapse>
                </Col>
            </Row>
        </Form>
    );
}