import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Divider, Button, Table, Form, Tag, Input, InputNumber, Modal } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { useStateValue } from "../../state";
import { manutencaoActions } from "../../actions";
import ModalEmail from "../../components/modals/modalEmail";

const EditableCell = ({ editing, key, title, inputType, record, index, children, ...restProps }) => {
    const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item name={key} className="m-0"
                    rules={[
                        {
                            required: true,
                            message: `Por favor preencha o ${title}!`,
                        },
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
}

export default function Email(editando) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [dadosEmail, setDadosEmail] = useState(editando ? (manutencao?.dados?.emails ?? []) : []);
    const [form] = Form.useForm();
    const [openModalEmail, setOpenModalEmail] = useState(false);

    const alterarPropriedadeEmail = (key, coluna, checked) => {
        const newData = [...dadosEmail];
        if (coluna === 'pem_emailprincipal' && checked === true) {
            const indexPrincipal = newData.findIndex((item) => true === item.pem_emailprincipal);
            if (indexPrincipal !== -1) newData[indexPrincipal][coluna] = false;
        }
        newData[key][coluna] = checked;
        setDadosEmail(newData);
    }

    const editEmail = (record, indice) => {
        record.indice = indice;
        form.setFieldsValue(record);
        setOpenModalEmail(true);
    };

    const removeEmail = (id) => {
        const newData = [...dadosEmail];
        const index = newData.findIndex((item) => id === item.pem_id);
        newData.splice(index, 1)
        setDadosEmail(newData);
    };

    useEffect(() => {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ pessoaemails: dadosEmail } } } });
    }, [dadosEmail]);

    const modalConfirmaExclusao = (record) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja excluir o email ${record.pem_email} ?`,
            okText: 'Excluir',
            cancelText: 'Cancelar',
            zIndex: 2010,
            centered: true,
            onOk() {
                removeEmail(record.pem_id);
            }
        });
    };

    function adicionarEmail() {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ pessoaemails: dadosEmail } } } });
        setOpenModalEmail(false);
    }

    const saveEmail = async (key, record) => {
        try {
            const row = await form.validateFields();
            const newData = [...dadosEmail];
            const index = newData.emails.findIndex((item) => key === item.key);

            if (index > -1) {
                const item = newData[index];
                newData.splice(index, 1, { ...item, ...row });
            } else {
                newData.emails.push(row);
            }
            setDadosEmail(newData);
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    return (

        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-email.png")} alt="E-mail(s)" /> E-mail(s) - (OPCIONAL)
                    </Typography.Title>
                    <Divider orientation="left" plain> Adicione o(s) e-mail(s) do cliente aqui. </Divider>
                </Col>
            </Row>
            <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => {
                            form.resetFields();
                            form.setFieldsValue({ pem_ativo: true });
                            setOpenModalEmail(true);
                        }} />
                    </div>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Form form={form} component={false}>
                    <Table
                        columns={[
                            {
                                title: 'E-mail',
                                Key: "pem_email",
                                editable: true,
                                width: 250,
                                render: ({ pem_email }) => (
                                    <div>
                                        <b>{pem_email}</b>
                                    </div>
                                ),
                            },
                            {
                                title: '',
                                align: 'center',
                                width: 95,
                                render: (record, obj, indice) => (
                                    <Tag.CheckableTag
                                        key="pem_emailprincipal"
                                        checked={record.pem_emailprincipal}
                                        onChange={(checked) => alterarPropriedadeEmail(indice, "pem_emailprincipal", checked)}
                                    >
                                        Principal
                                    </Tag.CheckableTag>
                                ),
                            },
                            {
                                title: '',
                                align: 'center',
                                width: 80,
                                render: (record, obj, indice) => (
                                    <Tag.CheckableTag
                                        key="pem_ativo"
                                        checked={record.pem_ativo}
                                        onChange={(checked) => alterarPropriedadeEmail(indice, "pem_ativo", checked)}
                                    >
                                        Ativo
                                    </Tag.CheckableTag>
                                ),
                            },
                            {
                                title: '',
                                align: 'center',
                                width: 110,
                                render: (record, obj, indice) => (
                                    <Tag.CheckableTag
                                        key="pem_enviarboleto"
                                        checked={record.pem_enviarboleto}
                                        onChange={(checked) => alterarPropriedadeEmail(indice, "pem_enviarboleto", checked)}
                                    >
                                        Enviar Boleto
                                    </Tag.CheckableTag>
                                ),
                            },
                            {
                                title: '',
                                align: 'center',
                                width: 150,
                                render: (record, obj, indice) => (
                                    <Tag.CheckableTag
                                        key="pem_enviarcartacobranca"
                                        checked={record.pem_enviarcartacobranca}
                                        onChange={(checked) => alterarPropriedadeEmail(indice, "pem_enviarcartacobranca", checked)}
                                    >
                                        Enviar Carta Cobrança
                                    </Tag.CheckableTag>
                                ),
                            },
                            {
                                title: '',
                                align: 'center',
                                width: 100,
                                render: (record, obj, indice) => (
                                    <Tag.CheckableTag
                                        key="pem_enviarnfe"
                                        checked={record.pem_enviarnfe}
                                        onChange={(checked) => alterarPropriedadeEmail(indice, "pem_enviarnfe", checked)}
                                    >
                                        Enviar NFE
                                    </Tag.CheckableTag>
                                ),
                            },
                            {
                                title: '',
                                align: 'center',
                                width: 150,
                                render: (record, obj, indice) => (
                                    <Tag.CheckableTag
                                        key="pem_enviarpromocao"
                                        checked={record.pem_enviarpromocao}
                                        onChange={(checked) => alterarPropriedadeEmail(indice, "pem_enviarpromocao", checked)}
                                    >
                                        Enviar Promoção
                                    </Tag.CheckableTag>
                                ),
                            },
                            {
                                title: '',
                                align: 'center',
                                width: 160,
                                render: (record, obj, indice) => (
                                    <Tag.CheckableTag
                                        key="pem_enviarvencparcela"
                                        checked={record.pem_enviarvencparcela}
                                        onChange={(checked) => alterarPropriedadeEmail(indice, "pem_enviarvencparcela", checked)}
                                    >
                                        Enviar Venc. Parcela
                                    </Tag.CheckableTag>
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                align: 'center',
                                fixed: 'right',
                                key: 'x',
                                width: 65,
                                render: (record, obj, indice) => {
                                    return <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button onClick={() => editEmail(record, indice)} icon={<EditOutlined />} />
                                            </Col>
                                            <Col>
                                                <Button onClick={() => modalConfirmaExclusao(record)} icon={<DeleteOutlined />} />
                                            </Col>
                                        </Row>
                                    </div>
                                },
                            },
                        ]}
                        rowClassName="editable-row"
                        locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Ainda não possui E-mail Cadastrado
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }} components={{
                            body: {
                                cell: EditableCell,
                            },
                        }} scroll={{ x: 850 }} dataSource={dadosEmail}
                    />
                </Form>
            </div>
            <ModalEmail form={form} listaEmail={{ dadosEmail, setDadosEmail }} exibirModalEmail={openModalEmail} fecharModalEmail={() => adicionarEmail()} />
        </div>

    );

}