import React, { useState, useEffect } from "react";
import moment from 'moment';
import { Row, Col, Form, Modal, Table, Input } from "antd";
import { ExclamationCircleOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";

import { useStateValue } from "../../../state";
import { InputPreco } from "../../../components";
import { manutencaoActions } from "../../../actions";
import { FormatNumber } from '../../../ValueObjects';

export default function ModalAntecipacao({ formAnterior, exibirModalAntecipacao, fecharModalAntecipacao, dadosAntecipacao }) {

    const [form] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();
    const [listaAntecipacao, setListaAntecipacao] = useState([]);
    const [valorAntecipacao, setValorAntecipacao] = useState(0);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalAntecipacao();
            }
        });
    }
    
    useEffect(() => {
        if (exibirModalAntecipacao && !!formAnterior.getFieldValue() && formAnterior.getFieldValue().pfp_valor) {
            form.setFieldsValue({ 'valorPagar': formAnterior.getFieldValue().pfp_valor });
        }
    }, [exibirModalAntecipacao])

    useEffect(() => {
        let totalAntecipacao = 0;
        if (listaAntecipacao.length > 0) {
            listaAntecipacao.forEach((antecipacao) => {
                totalAntecipacao += parseFloat(antecipacao.acf_valor - parseFloat(antecipacao.anm_valor));
                antecipacao.valorUtilizado = 0;
            })
        }
        setValorAntecipacao(totalAntecipacao);
    }, [listaAntecipacao])

    function selecionarAntecipacoes(selectedRowKeys, selectedRows) {
        setListaAntecipacao(selectedRows);
    }

    function salvarAntecipacao() {
        let valorNota = 0;
        let valorRestante = 0;
        if (!!formAnterior.getFieldValue().pfp_valor) {
            valorNota = parseFloat(formAnterior.getFieldValue().pfp_valor);
            valorRestante = valorNota;
        }
        let valorAntecipacao = 0;
        listaAntecipacao.forEach((antecipacao, index, array) => {
            let saldoAUtilizar = (parseFloat(antecipacao.acf_valor) - parseFloat(antecipacao.anm_valor))
            if (valorRestante - saldoAUtilizar >= 0) {
                antecipacao.valorUtilizado = saldoAUtilizar;
            } else {
                antecipacao.valorUtilizado = saldoAUtilizar + (valorRestante - saldoAUtilizar);
            }
            valorRestante -= antecipacao.valorUtilizado;
            valorAntecipacao += antecipacao.valorUtilizado;
            if (valorAntecipacao >= valorNota) {
                array.length = index + 1;
            }
        });
        listaAntecipacao.forEach(antecipacao => {
            antecipacao.valorAntecipacao = valorAntecipacao
        });
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ antecipacao: listaAntecipacao } } } });
        fecharModalAntecipacao();
    }

    return (
        <Modal centered
            title="Antecipações"
            open={exibirModalAntecipacao}
            getContainer={false}
            onCancel={onCloseModal}
            onOk={salvarAntecipacao}
            okText={
                <>
                    <CheckOutlined /> Confirmar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            width={720}
        >
            <div className="tabela">
                <Form layout="vertical" name="manutencaoAntecipacao" form={form}>
                    <Row>
                        <Col span={24}>
                            Abaixo valor referente ANTECIPAÇÃO gerada por: <b>{formAnterior.getFieldValue().pes_nome}</b>
                        </Col>
                    </Row>
                    <Row className="m-t-8">
                        <Col span={24}>
                            <Form.Item label="Localize a Antecipação" name="acf_codigovalepresente">
                                <Input placeholder="Informe o Código do Crédito/Antecipação" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Table pagination={false}
                        columns={[
                            {
                                title: 'Nota',
                                width: 75,
                                align: 'center',
                                render: ({ ntf_id }) => (
                                    <div>
                                        <b>{ntf_id ? ntf_id : '-'}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Data',
                                width: 85,
                                align: 'center',
                                render: ({ acf_datalancamento }) => (
                                    <div>
                                        <b>{moment(acf_datalancamento).format('DD/MM/YYYY')}</b>
                                    </div>
                                ),
                            },

                            {
                                title: 'Vlr. Antecipação (R$)',
                                align: 'right',
                                render: ({ acf_valor }) => (
                                    <div>
                                        <b>{FormatNumber(acf_valor, true)}</b>
                                    </div>
                                ),
                            },

                            {
                                title: 'Vlr. Utilizado (R$)',
                                align: 'right',
                                render: ({ anm_valor }) => (
                                    <div>
                                        <b>{FormatNumber(anm_valor, true)}</b>
                                    </div>
                                ),
                            },

                            {
                                title: 'Saldo a Utilizar (R$)',
                                align: 'right',
                                render: ({ acf_valor, anm_valor }) => (
                                    <div>
                                        <b>{FormatNumber(acf_valor - anm_valor, true)}</b>
                                    </div>
                                ),
                            },
                        ]} dataSource={dadosAntecipacao} rowSelection={{
                            onChange: (selectedRowKeys, selectedRows) => {
                                selecionarAntecipacoes(selectedRowKeys, selectedRows);
                            }
                        }}
                        footer={
                            () => {
                                return (
                                    <Row align="middle" justify="end" className="p-5-10">
                                        <Col>
                                            Antecipação Selecionada (R$): <b>{FormatNumber(valorAntecipacao, true)}</b>
                                        </Col>
                                    </Row>
                                )
                            }
                        } scroll={{y: 200}}
                    />
                    <Row gutter={[8, 0]} align="middle" justify="space-between" className="p-t-b-5">
                        <Col>
                            <InputPreco disabled name="valorPagar" label="Saldo a Pagar" />
                        </Col>
                        <Col>
                            Total da Nota (R$): <b>{FormatNumber(formAnterior.getFieldValue().valorTotal, true)}</b>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}