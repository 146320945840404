import React from "react";
import { Row, Col, Table, Tag, Divider } from "antd";
import { PhoneOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { FormatFone } from "../../../ValueObjects";

export default function Telefone({ dadosTelefone }) {

    return (
        <Row className="tabela">
            <Col span={24}>
                <Table columns={[
                    {
                        title: 'Telefone(s)',
                        render: ({ psc_numero, tpt_descricao, psc_observacao }) => (
                            <div>
                                <Row align="middle" gutter={[5, 0]}>
                                    <Col>
                                        <PhoneOutlined />
                                    </Col>
                                    <Col>
                                        <Tag color="processing">
                                            <b> {tpt_descricao} </b>
                                        </Tag>
                                    </Col>
                                    <Col>
                                        <b>{FormatFone(psc_numero) || 'Não Informado'}</b>
                                    </Col>
                                    <Col span={24}>
                                        <b>{psc_observacao}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} locale={{
                    emptyText: (
                        <Row>
                            <Col span={24}>
                                <Divider orientation="center">
                                    <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                </Divider>
                            </Col>
                        </Row>)
                }} dataSource={dadosTelefone} scroll={{ y: 420 }} pagination={false}/>
            </Col>
        </Row>
    );
}