import React, { useState, useEffect } from "react";
import { Row, Col, Table, Tag, Button, Tooltip } from "antd";

import { FormatNumber } from '../../../ValueObjects';

export default function Itens({ itensNota }) {

    const [descontoItem, setDescontoItem] = useState(false);
    const [itens, setItens] = useState([]);
    const togglePesquisa = () => {
        setDescontoItem(!descontoItem);
    };

    useEffect(() => {
        if (!!itensNota && itensNota.length > 0) {
            carregarTotal();
        }
    }, [itensNota])

    function carregarTotal() {
        let dadosTemp = [...itensNota];
        let totalItemSemDesconto = 0;
        let valorDesconto = 0;
        dadosTemp.forEach((item) => (
            item.totalItemSemDesconto = parseFloat((item.nfi_qtde * item.nfi_valorunitario).toFixed(2)),
            item.perc_desconto = parseFloat(((item.nfi_valordesconto / item.totalItemSemDesconto) * 100).toFixed(2)),
            item.valorDesconto = item.nfi_valordesconto,
            item.valor_item_desconto = totalItemSemDesconto - valorDesconto
        ))
        setItens(dadosTemp);
    }

    return (
        <div className="tabela">
            <Table
                columns={[
                    {
                        title: 'Produto',
                        width: 450,
                        render: ({ pro_codigo, pro_descricao, mgp_descricao }) => (
                            <div>
                                <Row align="middle" gutter={[8, 0]}>
                                    <Col>
                                        <Tag color="processing" className="w-58">
                                            <b>{pro_codigo}</b>
                                        </Tag>
                                    </Col>
                                    <Col className="over-text">
                                        <b>{pro_descricao}</b>
                                    </Col>
                                    <Col span={24} className="over-text">
                                        <b>{mgp_descricao}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Qtde.',
                        align: 'right',
                        width: 75,
                        render: ({ nfi_qtde, nfi_unidademedida }) => (
                            <div>
                                <b>{nfi_qtde} {nfi_unidademedida}.</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Vlr. Unit.',
                        align: 'right',
                        width: 100,
                        render: ({ nfi_valorunitario }) => (
                            <div>
                                <b>{FormatNumber(nfi_valorunitario, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: () => {
                            return (
                                <div>
                                    <Row align="middle" justify="end" gutter={[5, 0]}>
                                        <Col>
                                            <Tooltip title={!descontoItem ? 'Valor de Desconto' : 'Percentual'} placement="top">
                                                <Button type="primary" onClick={togglePesquisa}>
                                                    {descontoItem ? '%' : 'R$'}
                                                </Button>
                                            </Tooltip>
                                        </Col>
                                        <Col>
                                            Desc.
                                        </Col>
                                    </Row>
                                </div>
                            )
                        },
                        align: 'right',
                        width: 110,
                        render: ({ perc_desconto, nfi_valordesconto }) => (
                            <div>
                                <Row gutter={[5, 0]} align="middle" justify="end">

                                    {!descontoItem &&
                                        <Col>
                                            <b>{FormatNumber(nfi_valordesconto, true)}</b>
                                        </Col>
                                    }
                                    {descontoItem &&
                                        <Col>
                                            <b>{perc_desconto}%</b>
                                        </Col>
                                    }
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Total (R$)',
                        align: 'right',
                        fixed: 'right',
                        width: 120,
                        render: ({ nfi_qtde, nfi_valorunitario, nfi_valordesconto }) => (
                            <div>
                                <b>{FormatNumber(((nfi_qtde * nfi_valorunitario) - nfi_valordesconto), true)}</b>
                            </div>
                        ),
                    }
                ]} dataSource={itens} scroll={{ x: 650, y: 420 }} pagination={false}
            />
        </div>
    );
}