import React, { useEffect, useState } from "react";
import { Row, Col, Form, Typography, Card, Tooltip, Button, Input, Switch, Modal } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { manutencaoActions } from "../../actions";
import { isObjetoDiffVazio } from "../../services/funcoes";
import { ModalCliente, ModalBuscaPessoaAutorizada, ModalTransferencia, ModalTransportadora } from "../../components/modals/";

export default function DadosCliente({ setAbreModalAntecipacao, dadosAntecipacao, setDadosAntecipacao, possuiAntecipacao, setPossuiAntecipacao, cliente, setCliente, setFrete, switchState, setSwitchState, setEnderecoDestino }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [openModalBuscaCliente, setOpenModalBuscaCliente] = useState(false);
    const [openModalBuscaPessoaAutorizada, setOpenModalBuscaPessoaAutorizada] = useState(false);
    const [dadosEmail, setDadosEmail] = useState(false);
    const [dadosCliente, setDadosCliente] = useState({});
    const [openModalTransportadora, setOpenModalTransportadora] = useState(false);

    const switchEmail = () => {
        setDadosEmail(!dadosEmail)
    };

    useEffect(() => {
        if (!!manutencao.dados && !!manutencao.dados.cliente && isObjetoDiffVazio(manutencao.dados.cliente) && (!!!cliente.pes_id || cliente.pes_id !== manutencao.dados.cliente.pes_id)) {
            setCliente(manutencao.dados.cliente);
            setDadosCliente(manutencao.dados.cliente);
            setDadosAntecipacao([]);
        }
    }, [manutencao.dados]);

    function removerCliente() {
        setPossuiAntecipacao(false);
        let clienteConsumidor = {
            pes_nome: 'CONSUMIDOR FINAL',
            cpfCnpj: ''
        }
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ cliente: clienteConsumidor } } } });
        setCliente(clienteConsumidor);
        setDadosAntecipacao([]);
        setSwitchState(false);
    }

    async function buscarDadosAntecipacao() {
        let dadosTemp = manutencao.dados.cliente;
        if (!!dadosTemp.pes_id) {
            let retorno = await api.get(`AntecipacaoClienteFornecedor/ListaAntecipacaoCliente?pessoaId=${dadosTemp.pes_id}`);
            if (!!retorno.data && retorno.data.length > 0) {
                let seq = 0;
                retorno.data.forEach((ant) => {
                    ant.key = seq;
                    seq++;
                });
                setPossuiAntecipacao(true);
                setDadosAntecipacao(retorno.data);
                setAbreModalAntecipacao(true);
            }
        }
    }

    useEffect(() => {
            if (switchState) {
                modalNFCEDestination();
            } 
        }, [switchState]);

    function modalNFCEDestination() {
        Modal.confirm({
            icon: (
                <div style={{textAlign: 'center'}}>
                    <ExclamationCircleOutlined style={{fontSize: '80px', marginBottom: '20px', color: 'sandybrown'}}/>
                    <div>    
                        <div style={{fontSize: '17px', marginBottom: '3px'}}>Atenção!</div>
                        <div style={{fontSize: '14px', lineHeight: '1.2', color: 'gray'}}>
                            Para entrega a domicílio é necessário informar o Endereço de Entrega e o Transportador, deseja continuar?
                        </div>
                    </div>
                </div>            
            ),
            okText: 'Informar Transportador',
            cancelText: 'Não',
            onOk() {
                setOpenModalTransportadora(true);
            },
            onCancel () {
                setSwitchState(false);
            },
        })
    }
    
    return (
        <>
            <Card title={
                <Typography.Title level={4} className="m-b-0">
                    <img src={require("../../assets/i-info.png")} alt="Dados do Cliente" /> DADOS DO CLIENTE
                </Typography.Title>
            }>
                <Row align="middle" gutter={[8, 5]}>
                    <Col span={24}>
                        <Row align="middle" justify="space-between" gutter={[8, 0]}>
                            <Col xs={17} sm={17} md={17} lg={17} xl={17} xxl={17} className="over-text">
                                Cliente: <b>{cliente.pes_nome}</b>
                            </Col>
                            <Col className="btn-dados-cli">
                                <Tooltip title="Editar Cliente" placement="top">
                                    <Button type="text" icon={<EditOutlined />} onClick={() => {
                                        setOpenModalBuscaCliente(true);
                                    }} />
                                </Tooltip>
                                <Tooltip title="Remover Cliente" placement="top">
                                    <Button type="text" icon={<DeleteOutlined />} onClick={() => removerCliente()} />
                                </Tooltip>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        CPF/CNPJ: <b>{!!cliente.pef_cpf ? cliente.pef_cpf : (!!cliente.pej_cnpj ? cliente.pej_cnpj : !!cliente.cpfCnpj ? cliente.cpfCnpj : 'Não Informado')}</b>
                    </Col>
                    <Col span={24}>
                        {cliente?.pes_id && 
                        <Row gutter={[8, 0]}>
                            <Col>
                                <Switch onClick={(value) => setSwitchState(value)} checked={switchState}/>
                            </Col>
                            <Col>
                                NFC-e com entrega a domicílio?
                            </Col>
                        </Row>
                        }
                        <Row gutter={[8, 0]}>
                            <Col>
                                <Switch onClick={switchEmail} />
                            </Col>
                            <Col>
                                Enviar NFC-e por e-mail
                            </Col>
                        </Row>
                    </Col>
                    {!!dadosEmail === true &&
                        <Col span={24}>
                            <Form.Item className="m-b-0">
                                <Input placeholder="Informe o e-mail" />
                            </Form.Item>
                        </Col>
                    }
                    {possuiAntecipacao &&
                        <Col span={24} align="middle">
                            <Button type="primary" justify="center" onClick={() => buscarDadosAntecipacao()} className="m-t-5">
                                Possui Antecipação
                            </Button>
                        </Col>
                    }
                </Row>
            </Card>
            <ModalBuscaPessoaAutorizada exibirModalBuscaPessoaAutorizada={openModalBuscaPessoaAutorizada} fecharModalBuscaPessoaAutorizada={() => setOpenModalBuscaPessoaAutorizada(false)} dadosCliente={dadosCliente} />
            <ModalCliente exibirModalBuscaCliente={openModalBuscaCliente} fecharModalBuscaCliente={() => setOpenModalBuscaCliente(false)} setDadosCliente={setDadosCliente} dadosCliente={dadosCliente} />
            <ModalTransportadora dadosCliente={dadosCliente} exibirModalTransportadora={openModalTransportadora} setFrete={setFrete} fecharModalTransportadora={() => {setOpenModalTransportadora(false)}} 
                setEnderecoDestino={setEnderecoDestino} setSwitchState={setSwitchState} />
        </>
    );
}