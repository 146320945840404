import React from 'react';
import moment from 'moment'
import 'moment/locale/pt-br';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/lib/locale/pt_BR';
import { BrowserRouter as Router, Route, Routes, Outlet, Navigate, BrowserRouter } from "react-router-dom";

import "./App.less";
import { Login, Home, Venda } from "./pages";
import { StateProvider } from './state';
import { isAuthenticated } from './services/auth';
import { reducer } from './state/reducer';

function App() {

    moment.locale('pt-br');

    const initialState = {
        userConfig: { size: 'small' },
        ui: { showDrawer: false, showDrawerExtra: false },
        manutencao: { dados: null },
        dadosPdv: { pdv: null },
        listaSelectPaginado: { itens: [] },
        listaTabelaDados: { itens: [] },
        listagem: { filtro: '', ordem: '', outrosFiltros: '' },
        tributacao: { empRegimeTributario: null, empEnquadramentoFiscal: null },
        parametrosEmpresa: { markupProduto: null, par_percjuromesparcelavencida: '', par_diastoleranciacobrancajuro: '' }
    };

    /*   const PrivateRoute = () => {
          return isAuthenticated() ? (
              <Outlet />
          ) : (
              <Navigate to="/login" />
          )
      }; */
    const PrivateRoute = ({ children }) => {
        return isAuthenticated() ? children : <Navigate to="/login" />;
    };


    return (
        <StateProvider initialState={initialState} reducer={reducer}>
            <ConfigProvider locale={ptBR} componentSize="small">
                <Router>
                    <Routes>
                        <Route path="/" element={<Login />} />
                        <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
                        <Route path="*" element={<Home />} />
                    </Routes>
                </Router>
            </ConfigProvider>
        </StateProvider >
    );
}

export default App;