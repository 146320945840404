import React, { useState } from "react";
import { Row, Col, Typography, Divider, Button } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { TabelaDados } from "../../components";
import ModalOrgaoSubjacente from "../../components/modals/modalOrgaoSubjacente";

export default function OrgaoSubjacente() {

    const [ openModalOrgaoSubjacente, setOpenModalOrgaoSubjacente ] = useState(false);
  
    return (

        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}> 
                    <img src={require("../../assets/i-subjacentes.png")} alt="Órgãos Subjacentes" /> Órgãos Subjacentes 
                    </Typography.Title>
                    <Divider orientation="left" plain> Adicione o(s) órgão(s) público(s) Aqui. </Divider>
                </Col>
            </Row>
            <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => setOpenModalOrgaoSubjacente(true)}/>
                    </div>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <TabelaDados url="" colunas={[
                    { 
                        title: 'Nome', 
                        dataIndex: 'pop_nome',
                        render: ({pop_nome}) => (
                            <div>
                                <b>{pop_nome}</b>            
                            </div>
                        ),
                    },
                    { 
                        title: 'Endereço', 
                        dataIndex: 'endereco',
                        render: ({pop_endereco, pop_numero, pop_bairro, pop_complemento, pop_cep, cep }) => (
                            <div>
                                <b>
                                    {pop_endereco}{((pop_numero) !== '' && (pop_numero) !== null)} {(pop_numero) ? (', ' +pop_numero) : ''}
                                    {((pop_bairro) !== '' && (pop_bairro) !== null)} {(pop_bairro) ? (', ' +pop_bairro) : ''}
                                    {((pop_complemento) !== '' && (pop_complemento) !== null)} {(pop_complemento) ? (', ' +pop_complemento) : ''}
                                    {((pop_cep) !== '' && (pop_cep) !== null)} {(pop_cep) ? ('- ' +pop_cep) : '' | cep}
                                </b>   
                            </div>     
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        align: 'center',
                        key: 'x',
                        width: 65,
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        <Button onClick={() => setOpenModalOrgaoSubjacente(true)} icon={<EditOutlined />}/>
                                    </Col>
                                    <Col>
                                        <Button icon={<DeleteOutlined />}/>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} scroll={{ x: 850 }} />
            </div>
            <ModalOrgaoSubjacente exibirModalOrgaoSubjacente={openModalOrgaoSubjacente} fecharModalOrgaoSubjacente={() => setOpenModalOrgaoSubjacente(false)}/>
        </div>

    );
    
}