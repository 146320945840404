import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Select, notification } from 'antd';

import api from '../../services/api';
import { getTelaAnterior, login, setCnpjEmpresa, setEmpresa, setLocal, setNomeEmpresa, setNomeLocal, setRegimeTributario, setStatusFilial } from '../../services/auth';
import SelectPaginado from '../../components/selects/selectPaginado';
import { dadosPdvActions } from '../../actions';
import { useStateValue } from '../../state';
import { carregarDadosPdv } from '../../services/funcoes';

export default function SelecoesEmpresa() {

    const acessar = values => {
        buscarTela();
    };
    const [{ dadosPdv }, dispatch] = useStateValue();
    const [codigoEmpresa, setCodigoEmpresa] = useState(null);
    const [codigoLocal, setCodigoLocal] = useState(null);
    const [listarLocaisUsuario, setListaLocaisUsuario] = useState([]);
    const [formLogin] = Form.useForm();

    function carregarDados() {
        api.get('/Empresa/ListarEmpresaUsuario').then(res => {
            if (res.status === 200) {
                if (res.data.length === 1) setCodigoEmpresa(res.data.items[0].emp_id)
            }
        }).catch(
            (erro) =>
                console.error(erro)
        );
    }

    function carregarLocais() {
        if (codigoEmpresa) {
            api.get(`/Local/ListarLocalUsuario?codigoEmpresa=${codigoEmpresa}`).then(
                res => {
                    if (res.status === 200) {
                        setListaLocaisUsuario(res.data.items);
                        if (res.data.items.length === 1) {
                            setCodigoLocal(res.data.items[0].loc_id);
                            setEmpresa(codigoEmpresa);
                            setLocal(res.data.items[0].loc_id)
                            carregarPdv(res.data.items[0].loc_id);
                        }
                    }
                }).catch(
                    (erro) =>
                        console.error(erro)
                );
        }
    }

    async function carregarDadosEmpresa() {
        if (!!codigoLocal) {
            let values = {};
            values.emp_id = codigoEmpresa;
            values.loc_id = codigoLocal;
            const listaEmpresa = (await api.get(`Empresa/Buscar/${codigoEmpresa}`)).data;
            await api.post('Local/GerarTokenLogin', values).then(res => {
                if (res.status === 200) {
                    login(res.data);
                    setEmpresa(codigoEmpresa);
                    setLocal(codigoLocal);
                    setNomeEmpresa(listaEmpresa.emp_razaosocial);
                    setRegimeTributario(listaEmpresa.emp_regimetributario)
                    const listaLocal = listarLocaisUsuario.filter(u => u.loc_id === codigoLocal)[0];
                    setCnpjEmpresa(listaLocal.loc_cnpj?.numero);
                    setNomeLocal(listaLocal.loc_descricao);
                    setStatusFilial(listaLocal.loc_ativo);
                    buscarTela();
                }
            }, err => {
                if (!!err) {
                    if (!!err.response && !!err.response.data) {
                        notification.error({ message: err.response.data || 'Falha ao efetuar o login' });
                    } else {
                        notification.error({ message: 'Falha ao efetuar o login', description: 'Verifique os dados informados e tente novamente.' });
                    }
                } else {
                    notification.error({ message: 'Falha ao efetuar o login', description: 'Verifique os dados informados e tente novamente.' });
                }
            });
        }
    }


    useEffect(() => carregarDados(), []);
    useEffect(() => carregarLocais(), [codigoEmpresa]);
    useEffect(() => {
        carregarDadosEmpresa();
    }, [codigoLocal]);

    function buscarTela() {
        if (!!getTelaAnterior()) {
            if (window.location.hash.replace('#', '') === getTelaAnterior()) {
                window.location.reload();
            } else {
                window.location = `/Home#${getTelaAnterior()}`;
                if (window.location.hash !== '' && window.location.hash !== '#/') {
                    window.location.reload();
                }
            }
        } else {
            window.location = "/Home";
        }
    }

    async function carregarPdv(valor) {
        setCodigoLocal(valor);
        let res = await carregarDadosPdv();
        if (!res) {
            let pdvDados = {
                pdv_id: null,
                pdv_configuracao: 0,
                pdv_numerocaixa: 1,
                pdv_registro: 'null.null',
                pdv_disco: 0,
                pdv_ram: 0,
                pdv_resolucaotela: '',
                pdv_nomecomputador: '',
                pdv_cpu: '',
                pdv_ipredelocal: '8.8.8.8',
                pdv_sistemaoperacional: '',
                tipoPdv: 1,
                loc_id: valor
            }
            res = (await api.post(`Pdv/Incluir`, pdvDados)).data;
        }
        dispatch({ type: dadosPdvActions.CHANGE, data: { pdv: res } });
    }

    return (
        <div>
            <Form layout="vertical" name="login" size="middle" onFinish={acessar} form={formLogin}>
                <Row align="middle" gutter={[0, 16]}>
                    <Col span={24}>
                        <Form.Item name="empresa" label="Selecione sua Empresa">
                            <SelectPaginado url="/Empresa/ListarEmpresaUsuario" placeholder="Selecione uma Empresa" form={formLogin} name="emp_id" onChangeFunction={(value) => setCodigoEmpresa(value)}
                                conteudo={
                                    e => (<Select.Option value={e.emp_id} key={e.emp_id} label={e.emp_nomefantasia}>{e.emp_nomefantasia} <br /> Razao Social: {e.emp_razaosocial} </Select.Option>)
                                } />
                        </Form.Item>
                    </Col>
                </Row>
                {codigoEmpresa && <Row align="middle" gutter={[0, 16]}>
                    <Col span={24}>
                        <Form.Item name="filial" label="Selecione sua Filial">
                            <Select
                                placeholder="Selecione uma Filial"
                                optionFilterProp="children"
                                onChange={(valor) => carregarPdv(valor)}
                                value={codigoLocal}
                            >
                                {listarLocaisUsuario.filter(ativa => (ativa.loc_ativo === true)
                                ).map(Local => (
                                    <Select.Option key={Local.loc_id} value={Local.loc_id} label={Local.loc_descricao}> {Local.loc_descricao} </Select.Option>
                                )
                                )}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>}
            </Form>
        </div>
    );
}