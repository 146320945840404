import React, { useState, useEffect } from "react";
import { Row, Col, Modal, Tabs } from "antd";
import moment from "moment";

import api from "../../../services/api";
import { Itens, FormaPagamento } from "./pages";
import { isObjetoDiffVazio } from "../../../services/funcoes";
import { FormatFone, MaskFormat } from "../../../ValueObjects";

export default function ModalDetalhesNotaFiscal({ detalhesNF, setDetalhesNF, exibirModalDetalhesNotaFiscal, fecharModalDetalhesNotaFiscal }) {

    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [arrFinalidade] = useState(["NF-E NORMAL", "NF-E COMPLEMENTAR", "NF-E DE AJUSTE", "DEVOLUÇÃO"]);
    const [arrStatus] = useState(["EM DIGITAÇÃO", "AUTORIZADA", "CANCELADA", "DENEGADA", "REJEITADA", "CONTINGÊNCIA"]);
    const [arrAmbiente] = useState(["PRODUÇÃO", "HOMOLOGAÇÃO"]);
    const [detalhesNota, setDetalhesNota] = useState({});
    const onCloseModal = () => {
        setDetalhesNota({});
        setDetalhesNF({});
        fecharModalDetalhesNotaFiscal();
    };

    useEffect(() => {
        if (isObjetoDiffVazio(detalhesNF)) {
            carregarDadosDestinatario(detalhesNF);
        }
    }, [detalhesNF])

    async function carregarDadosDestinatario(dados) {
        let dadosTemp = { ...detalhesNF };
        let vendedor = [];
        if (!!dadosTemp.ven_id) {
            vendedor = await api.get(`Vendedor/RetornaVendedor?idVendedor=${dadosTemp.ven_id}`);
            dadosTemp.nomeVendedor = vendedor.data.pes_nome;
        }
        setDetalhesNota(dadosTemp);
    }

    return (
        <Modal destroyOnClose
            title="Detalhes da Nota Fiscal"
            open={exibirModalDetalhesNotaFiscal}
            getContainer={false}
            onCancel={onCloseModal}
            maskClosable={false}
            width="65%"
            footer={null}
        >
            <div>
                <Row align="middle" gutter={[16,5]} justify="space-between">
                    <Col>
                        <Col>
                            Chave: <b>{(!!detalhesNota.ntf_chavenfe ? detalhesNota.ntf_chavenfe : 'Não Disponível')}</b>
                        </Col>
                    </Col>
                </Row>
                <Row gutter={[16,5]} justify="space-between"> 
                    <Col>
                        <Col>
                            Data de Emissão: <b>{!!detalhesNota.ntf_dataemissao ? moment(detalhesNota.ntf_dataemissao).format('DD/MM/YYYY') : null}</b>
                        </Col>
                        <Col>
                            Ambiente: <b>{arrAmbiente[detalhesNota.ntf_ambiente - 1]}</b>
                        </Col> 
                        <Col>
                            CPF/CNPJ: <b>{(!!detalhesNota.ntf_cnpjcpfdest ? MaskFormat(detalhesNota.ntf_cnpjcpfdest, '', true) : (!!detalhesNota.ntf_cnpjcpfdest ? MaskFormat(detalhesNota.ntf_cnpjcpfdest, '', true) : 'NÃO INFORMADO'))}</b>
                        </Col>
                        <Col>
                            Status: <b>{arrStatus[detalhesNota.ntf_status]}</b>
                        </Col>
                    </Col>
                    <Col>
                        <Col>
                            Nº: <b>{detalhesNota.ntf_numero}</b>
                        </Col>
                        <Col>
                            Destinatário: <b>{(!!detalhesNota.ntf_nomedest ? detalhesNota.ntf_nomedest : 'Não Informado')}</b>
                        </Col>
                        <Col>
                            IE: <b>{(!!detalhesNota.ntf_iedest ? detalhesNota.ntf_iedest : 'Não Informado')}</b>
                        </Col>
                    </Col>
                    <Col>
                        <Col>
                            Operação: <b>{detalhesNota.ope_descricao}</b>
                        </Col>
                        <Col>
                            Pessoa Autorizada: <b>{(!!detalhesNota.nomepessoaautorizada ? detalhesNota.nomepessoaautorizada : 'Não Informado')}</b>
                        </Col>
                        <Col>
                            E-mail: <b>{(!!detalhesNota.ntf_emaildest ? detalhesNota.ntf_emaildest : 'Não Informado')}</b>
                        </Col>
                    </Col>
                    <Col>
                        <Col>
                            Finalidade: <b>{arrFinalidade[detalhesNota.ntf_finalidade - 1]}</b>
                        </Col>
                        <Col>
                            Vendedor: <b>{(!!detalhesNota.nomeVendedor ? detalhesNota.nomeVendedor : 'Não Informado')}</b>
                        </Col>
                        <Col>
                            Telefone: <b>{!!detalhesNota.ntf_telefonedest ? FormatFone(detalhesNota.ntf_telefonedest) : 'Não Informado'}</b>
                        </Col>
                    </Col>
                    <Col style={{ marginTop: "-6px"}}>
                        <Col>
                            Endereço: 
                            <b>
                                {(!!detalhesNota.ntf_logradourodest ? detalhesNota.ntf_logradourodest : ' Não Informado')},
                                {(!!detalhesNota.ntf_bairrodest ? detalhesNota.ntf_bairrodest : ' Não Informado')},
                                {(!!detalhesNota.ntf_municipiodest ? detalhesNota.ntf_municipiodest : ' Não Informado')},
                                {(!!detalhesNota.ntf_cepdest ? MaskFormat(detalhesNota.ntf_cepdest, ' 00000-000') : ' Não Informado')}.
                            </b>
                        </Col>
                    </Col>
                </Row>
                <div className="m-t-16">
                    <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                        <Tabs.TabPane tab="Itens" key="1">
                            <Itens itensNota={detalhesNota.itensNf} />
                        </Tabs.TabPane>
                        <Tabs.TabPane tab="Forma de Pagamento" key="2">
                            <FormaPagamento formaPagamento={detalhesNota.formaPgto} />
                        </Tabs.TabPane>
                    </Tabs>
                </div>
            </div>
        </Modal>
    );
}