import React from 'react';
import { Drawer } from "antd";

import { DetalheNotaFiscal } from '../../components';

export default function DrawerDetalheNotaFiscal({ openModal, closeModal, dadosDrawer }) {

    const onClose = () => {
        closeModal();
    };

    return (

        <Drawer title="Detalhes da Nota Fiscal"
            width="55%"
            visible={openModal}
            onClose={onClose}
            destroyOnClose>
            <DetalheNotaFiscal dadosModal={dadosDrawer}/>
        </Drawer>

    )
}