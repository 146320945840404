import axios from 'axios';

const apiPayer = axios.create({
  baseURL: 'http://localhost:6060/',
});

apiPayer.interceptors.request.use(async config => {
    return config;
});

apiPayer.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    if (error.response) { 
        console.log(error)
    }
    return Promise.reject(error);
  });

export default apiPayer;