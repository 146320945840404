import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Divider, Button, Table, Form, Tag, Modal } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { useStateValue } from "../../state";
import { manutencaoActions } from "../../actions";
import ModalOcupacao from "../../components/modals/modalOcupacao";

export default function Ocupacao(editando) {

    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [dadosOcupacoes, setDadosOcupacoes] = useState(editando ? (manutencao?.dados?.pessoasocupacoes ?? []) : []);
    const [form] = Form.useForm();
    const [openModalOcupacao, setOpenModalOcupacao] = useState(false);


    const alterarPropriedadeOcupacao = (key, coluna, checked) => {
        const newData = [...dadosOcupacoes];
        if (coluna === 'poc_principal' && checked) {
            const indexPrincipal = newData.findIndex((item) => true === item.poc_principal);
            if (indexPrincipal !== -1) {
                newData[indexPrincipal][coluna] = false;
            }
        }
        newData[key][coluna] = checked;
        setDadosOcupacoes(newData);
    };

    const editaOcupacao = (record, indice) => {
        record.indice = indice;
        form.setFieldsValue(record);
        setOpenModalOcupacao(true);
    };

    const removeOcupacao = (id) => {
        const newData = [...dadosOcupacoes];
        const index = newData.findIndex((item) => id === item.poc_id);
        newData.splice(index, 1)
        setDadosOcupacoes(newData);
    };

    const modalExcluirOcupacao = (record) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja excluir a ocupação ${record.cbo_descricao} ?`,
            okText: 'Excluir',
            cancelText: 'Cancelar',
            zIndex: 2010,
            centered: true,
            onOk() {
                removeOcupacao(record.poc_id);
            }
        });
    };

    useEffect(() => {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ pessoasocupacoes: dadosOcupacoes } } } });
    }, [dadosOcupacoes]);

    return (
        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-ocupacoes.png")} alt="Ocupações" /> Ocupações - (OPCIONAL)
                    </Typography.Title>
                    <Divider orientation="left" plain> Adicione a(s) ocupação(ões) do cliente aqui. </Divider>
                </Col>
            </Row>
            <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => setOpenModalOcupacao(true)} />
                    </div>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Form form={form} component={false}>
                    <Table columns={
                        [
                            {
                                title: 'Ocupação',
                                dataIndex: '',
                                Key: 'cbo_descricao',
                                render: ({ cbo_descricao }) => (
                                    <div>
                                        <b>{cbo_descricao}</b>
                                    </div>
                                ),
                            },
                            {
                                title: '',

                                align: 'center',
                                render: (record, obj, indice) => (
                                    <Tag.CheckableTag
                                        key="poc_principal"
                                        checked={record.poc_principal}
                                        onChange={(checked) => alterarPropriedadeOcupacao(indice, "poc_principal", checked)}
                                    >
                                        Principal
                                    </Tag.CheckableTag>
                                ),
                            },
                            {
                                title: 'Ações',
                                dataIndex: '',
                                align: 'center',
                                key: 'x',
                                width: 65,
                                fixed: 'right',
                                render: (record, obj, indice) => (
                                    <div>
                                        <Row align="middle" justify="center" gutter={[5, 0]}>
                                            <Col>
                                                <Button onClick={() => editaOcupacao(record, indice)} icon={<EditOutlined />} />
                                            </Col>
                                            <Col>
                                                <Button onClick={() => modalExcluirOcupacao(record)} icon={<DeleteOutlined />} />
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                        ]}
                        locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Ainda não possui Ocupação Cadastrada
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }} scroll={{ x: 850 }} dataSource={dadosOcupacoes}
                    />
                </Form>
            </div>
            <ModalOcupacao form={form} listaOcupacoes={{ dadosOcupacoes, setDadosOcupacoes }} exibirModalOcupacao={openModalOcupacao} fecharModalOcupacao={() => setOpenModalOcupacao(false)} />
        </div>
    );
}