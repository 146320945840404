import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Divider, Button, Table, Tag, Modal, Form } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";

import { useStateValue } from "../../state";
import { MaskFormat } from "../../ValueObjects";
import { manutencaoActions } from "../../actions";
import { ModalPessoaAutorizada } from "../../components/modals/";

export default function PessoasAutorizadas(editandoPessoa) {

    const [form] = Form.useForm();
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [openModalPessoaAutorizada, setOpenModalPessoaAutorizada] = useState(false);
    const [dadosPessoasAutorizadas, setDadosPessoasAutorizadas] = useState(editandoPessoa ? (manutencao?.dados?.pessoaautorizada ?? []) : []);
    const [editando, setEditando] = useState(false);

    const editaPessoaAutorizada = (record) => {
        setEditando(true);
        form.setFieldsValue(record);
        setOpenModalPessoaAutorizada(true);
    };

    const alterarPropriedadePessoaAutorizada = (key, coluna, checked, idx) => {
        const newData = [...dadosPessoasAutorizadas];
        newData[idx][coluna] = checked;
        setDadosPessoasAutorizadas(newData);
    };

    const removePessoaAutorizada = (record, indice) => {
        const newData = [...dadosPessoasAutorizadas];
        record.indice = indice;
        const index = newData.findIndex((item) => indice === item.indice);
        newData.splice(index, 1)
        setDadosPessoasAutorizadas(newData);
    };

    const modalConfirmaExclusao = (record, indice) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja excluir a pessoa autorizada ${record.pes_nome} - ${record.grp_descricao} ?`,
            okText: 'Excluir',
            cancelText: 'Cancelar',
            zIndex: 2010,
            centered: true,
            onOk() {
                removePessoaAutorizada(record, indice);
            }
        });
    };

    useEffect(() => {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ pessoaautorizada: dadosPessoasAutorizadas } } } });
    }, [dadosPessoasAutorizadas]);

    return (

        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-pessoas-autorizadas.png")} alt="Pessoas Autorizadas" /> Pessoas Autorizadas - (OPCIONAL)
                    </Typography.Title>
                    <Divider orientation="left" plain>
                        Adicione a(s) pessoa(s) autorizada(s) aqui.
                    </Divider>
                </Col>
            </Row>
            <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => {
                            form.resetFields();
                            form.setFieldsValue({ pea_ativo: true });
                            setOpenModalPessoaAutorizada(true);
                        }} />
                    </div>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Table columns={
                    [
                        {
                            title: 'Nome',
                            render: ({ pea_nome }) => (
                                <div>
                                    <b>{pea_nome}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'CPF',
                            render: ({ pea_cpf }) => (
                                <div>
                                    <b>{MaskFormat(!!pea_cpf ? pea_cpf.replaceAll('.', '').replaceAll('-', '') : '', 'Não Informado', true)}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Grau de Parentesco',
                            render: ({ grauParentesco }) => (
                                <div>
                                    <b>{!!grauParentesco ? grauParentesco.grp_descricao : null}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Autorizado(a)',
                            Key: 'pea_ativo',
                            render: (record, indice, idx) => (
                                <Tag.CheckableTag
                                    key="pea_ativo"
                                    checked={record.pea_ativo}
                                    onChange={(checked) => alterarPropriedadePessoaAutorizada(indice, "pea_ativo", checked, idx)}
                                >
                                    Autorizado(a)?
                                </Tag.CheckableTag>
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            align: 'center',
                            key: 'x',
                            width: 65,
                            fixed: 'right',
                            render: (record, obj, indice) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button onClick={() => editaPessoaAutorizada(record, indice)} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Button onClick={() => modalConfirmaExclusao(record, indice)} icon={<DeleteOutlined />} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]} locale={{
                        emptyText: (
                            <Row>
                                <Col span={24}>
                                    <Divider orientation="center">
                                        <ExclamationCircleOutlined /> Ainda não possui Pessoa Autorizada Cadastrada
                                    </Divider>
                                </Col>
                            </Row>
                        )
                    }} scroll={{ x: 850 }} dataSource={dadosPessoasAutorizadas}
                />
            </div>
            <ModalPessoaAutorizada form={form} listaPessoasAutorizadas={{ dadosPessoasAutorizadas, setDadosPessoasAutorizadas }} exibirModalPessoaAutorizada={openModalPessoaAutorizada} fecharModalPessoaAutorizada={() => setOpenModalPessoaAutorizada(false)} editando={editando} setEditando={setEditando} />
        </div>

    );

}