import React, { useState, useEffect } from "react";
import { Row, Col, Form, Radio, Select, Button, Collapse, Tag, Tooltip, Modal, notification, Input, Dropdown, Menu } from "antd";
import { ArrowDownOutlined, ArrowUpOutlined, SearchOutlined, EyeOutlined, PrinterOutlined, CloseOutlined, ToTopOutlined, CheckOutlined, MoreOutlined, CloudUploadOutlined, UndoOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { carregarDadosPdv, novaAbaNavegador, formatarCpfCnpj } from "../../services/funcoes";
import { FormatNumber, FormatDate } from "../../ValueObjects";
import { dadosPdvActions, listagemActions, manutencaoActions } from "../../actions";
import { ComboData, Data, SelectProduto, TabelaDados, BreadcrumbPage } from "../../components";
import { ModalDetalhesNotaFiscal, ModalCancelarVenda, ModalEstornoNfe, ModalReenviarSefaz } from "../../components/modals/";
import ModalAjudaRejeicaoNfe from "../../components/modals/modalAjudaRejeicaoNfe";
import { useNavigate } from "react-router-dom";

export default function VendaRealizada() {

    const [form] = Form.useForm();
    const [formParam] = Form.useForm();
    const [{ manutencao, dadosPdv }, dispatch] = useStateValue();
    const [openModalDetalhesNotaFiscal, setOpenModalDetalhesNotaFiscal] = useState(false);
    const [openModalCancelarVenda, setOpenModalCancelarVenda] = useState(false);
    const [open, setOpen] = useState(false);
    const [dadosNota, setDadosNota] = useState({});
    const [finalidade, setFinalidade] = useState([]);
    const [listaCondicoes, setListaCondicoes] = useState([]);
    const [detalhesNF, setDetalhesNF] = useState({});
    const [listaStatusNota, setListaStatusNota] = useState([]);
    const [openModalEstorno, setOpenModalEstorno] = useState(false);
    const [listaPgto, setListaPgto] = useState([]);
    const [atualizaLista, setAtualizaLista] = useState(false);
    const [openModalReenviarSefaz, setModalReenviarSefaz] = useState(false);
    const [openModalAjudaRejeicaoNfe, setOpenModalAjudaRejeicaoNfe] = useState(false);
    const navigation = useNavigate();
    const [tipoMovimento] = useState([
        { key: 0, value: 'Devolução' },
        { key: 1, value: 'Saída' },
        { key: 2, value: 'Entrada' },
        { key: 3, value: 'Transferencia' }
    ]);
    const togglePesquisa = () => {
        setOpen(!open);
    };

    useEffect(() => {
        if (!!!dadosPdv.pdv) {
            verificaPdv();
        } else {
            carregarCaixa(dadosPdv.pdv?.pdv_id);
        }
        carregarListas();
        filtrosAvancados();
    }, [])

    useEffect(() => {
        if (atualizaLista) {
            filtrosAvancados();
        }
    }, [atualizaLista])

    async function verificaPdv() {
        let res = await carregarDadosPdv();
        dispatch({ type: dadosPdvActions.CHANGE, data: { pdv: res } });
        carregarCaixa(res.pdv_id);
    }

    function carregarCaixa(pdv_id) {
        api.get(`ControleCaixa/ListaControleCaixaAberto/${pdv_id}`).then(
            res => {
                if (!res.data) {
                    window.location = "/Home#"
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }


    function carregarListas() {
        api.get(`Enum/Listar?nome=StatusNFe`).then(res => { setListaStatusNota(res.data) }).catch(error => { console.log(error) });
        api.get(`Enum/Listar?nome=FinalidadeNfe`).then(res => { setFinalidade(res.data) }).catch(error => { console.log(error) });
        api.get(`FormaPagamento/ListarAtivos?Ativo=true&PageSize=500`).then(res => { setListaPgto(res.data.items) }).catch(error => { console.log(error) });
    }

    function carregarCondicao(value) {
        api.get(`CondicaoPagamento/Listar?CodigoFormaPagamento=${value}`).then(res => { setListaCondicoes(res.data.items) }).catch(error => { console.log(error) });
    }

    function filtrosAvancados(value) {
        let dataInicial = !!form.getFieldValue().dataInicial ? moment(form.getFieldValue().dataInicial).format('YYYY-MM-DD') : FormatDate(new Date().toLocaleDateString());
        let dataFinal = !!form.getFieldValue().dataFinal ? moment(form.getFieldValue().dataFinal).format('YYYY-MM-DD') : moment(new Date()).format('YYYY-MM-DD');
        let produtos = !!form.getFieldValue().pro_id ? `&Produtos=${form.getFieldValue().pro_id}` : '';
        let sql = "";
        sql += !!form.getFieldValue().finalidade ? (form.getFieldValue().finalidade.length > 0 ? `&Finalidade=${form.getFieldValue().finalidade}` : '') : '';
        sql += !!formParam.getFieldValue().status ? `&StatusNFe=${formParam.getFieldValue().status}` : '';
        sql += `&ModeloNota=00,65`;
        sql += `&DataInicial=${dataInicial}&DataFinal=${dataFinal}`
        sql += produtos
        sql += !!form.getFieldValue().condicaoPagamento ? `&CondicoesPagamento=${form.getFieldValue().condicaoPagamento}` : "";
        sql += !!form.getFieldValue().formaPagamento ? `&FormasPagamento=${form.getFieldValue().formaPagamento}` : "";
        dispatch({ type: listagemActions.CHANGE, data: { filtro: `${!!value ? value : !!formParam.getFieldValue().filtroPor ? formParam.getFieldValue().filtroPor : ''}${sql}`, ordem: '-ntf_numero' } });
    }

    async function exibirDetalhesNF(record) {
        let dadosTemp = (await api.get(`NotaFiscal/ListarNotaFiscal?IdNotaFiscal=${record.ntf_id}`)).data;
        dadosTemp.tipooperacao = tipoMovimento.filter((item) => (item.key === record.ntf_tipooperacao))[0].value;
        dadosTemp.finalidade = finalidade.filter((item) => (item.key === record.ntf_finalidade))[0].value;
        dadosTemp.ambiente = record.ntf_ambiente === 1 ? 'PRODUÇÃO' : 'HOMOLOGAÇÃO';
        setDetalhesNF(dadosTemp);
        setOpenModalDetalhesNotaFiscal(true);
    }

    function cancelarVenda(record) {
        api.get(`NotaFiscal/VerificarTempoEmissao?IdNotaFiscal=${record.ntf_id}`).then(
            res => {
                if (record.ntf_docfiscal) {
                    if (!!res.data.terminouPrazoCancelamento) {
                        Modal.warning({
                            title: 'Não é possivel realizar o cancelamento!',
                            content: 'Prazo de cancelamento de cupom Fiscal é de 30 minutos!',
                            okText: 'Ok',
                            okType: 'danger',
                            centered: true,
                        });
                    } else {
                        setDetalhesNF(record);
                        setOpenModalCancelarVenda(true)
                    }
                } else {
                    setDetalhesNF(record);
                    setOpenModalCancelarVenda(true);
                }
            }
        ).catch(error => {
            console.log(error);
        })
    }

    function recarregarLista() {
        filtrosAvancados();
    }

    function retornaDescricaoStatus(ntf_status) {
        if (!!listaStatusNota) {
            let status = listaStatusNota.filter((statusNota) => (statusNota.key === ntf_status))[0];
            return !!status ? status.value : "";
        }
    };

    function enviarNotaContingencia(record) {
        api.post(`NotaFiscal/EnviarNFCeContingencia?IdNotaFiscal=${record.ntf_id}`).then(
            res => {
                if (res.data.ntf_status == 1) {
                    console.log(res.data);
                    recarregarLista();
                    notification.success({ message: 'Sucesso', description: 'NFC-e autorizada com sucesso!' });
                    // if (res.data.ntf_docfiscal === true) {
                    //     novaAbaNavegador(`Danfe/GerarDanfe?chaveNFe=${res.data.ntf_chavenfe}`);
                    // }
                } else {
                    notification.warning({ message: 'Atenção', description: "Não foi possível aprovação da NFC-e" });
                }
            }
        ).catch(error => {
            console.log(error);
            notification.warning({ message: 'Erro', description: error.response.data });
        })
    }

    function reimprimirCupom(record) {
        if (record.ntf_docfiscal) {
            novaAbaNavegador(`Danfe/GerarDanfe?chaveNfe=${record.ntf_chavenfe}`);
        } else {
            novaAbaNavegador(`NotaFiscal/Imprimir?id=${record.ntf_id}`);
        }
        let formaPagItegrado = record.formasPgto.filter((item) => (item.fpg_tipointegracao > 0))
        if (formaPagItegrado.length > 0) {
            novaAbaNavegador(`Danfe/GerarComprovantePagamentoViaLoja?IdNotaFiscal=${record.ntf_id}`);
        }
        let formaPag = record.formasPgto.filter((item) => (item.fpg_imprimecarne === true))
        if (formaPag.length > 0) {
            novaAbaNavegador(`ContasPagarReceber/ImprimirCarnePagamento?IdNotaFiscal=${record.ntf_id}`);
            novaAbaNavegador(`ContasPagarReceber/Imprimir?IdNotaFiscal=${record.ntf_id}`);
        }
    }

    function verificaDevolucao(record) {
        api.get(`NotaFiscalDevolucao/VerificaDevolucaoTotal?idNotaFiscal=${record.ntf_id}`).then(
            res => {
                if (!res.data) {
                    navigation("../devolucaoVenda");
                    //window.location = '/Home#/devolucaoVenda'
                    dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ dadosNfceDevolucao: record } } } })
                } else {
                    Modal.warning({
                        title: 'Aviso',
                        content: 'Esta Nota Fiscal já foi totalmente devolvida'
                    });
                }
            }
        ).catch(
            error => {
                console.log(error);
                notification.warning({ message: 'Erro', description: error.response.message });
            }
        )
    }
    function autorizarNota(record) {
        setDadosNota(record);
        setModalReenviarSefaz(true);
    };
    function menuTable(record) {
        return (
            <Menu className="text-right">
                {(record.ntf_status === 4 || record.ntf_status === 0) && record.ntf_docfiscal === true &&
                    <Menu.Item key="1"><Button type="text" icon={<CloudUploadOutlined />} onClick={() => { autorizarNota(record) }} >Autorizar Sefaz</Button>
                    </Menu.Item>}
                {record.ntf_status === 1 && record.ntf_finalidade === 1 &&
                    <Menu.Item key="2"><Button type="text" icon={<UndoOutlined />} onClick={() => { verificaDevolucao(record) }} >Devolver o Cupom Fiscal</Button>
                    </Menu.Item>}
            </Menu >
        )
    };

    return (
        <>
            <Form layout="vertical" form={formParam}>
                <BreadcrumbPage pagina="Venda" pagina_seleciona="Vendas Realizadas" />
                <Row align="middle" gutter={[8, 0]}>
                    <Col xs={24} sm={16} md={16} lg={14} xl={14} xxl={16}>
                        <Form.Item label="Pesquisar por" name="filtroPor">
                            <Input.Search placeholder={"Código, descrição"} onSearch={valor => filtrosAvancados(valor)} allowClear />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8} md={8} lg={4} xl={4} xxl={4}>
                        <ComboData form={form} eventoAoAlterar={() => {
                            form.setFieldsValue({ dataFinal: moment(new Date()) });
                            form.setFieldsValue({ dataInicial: moment(form.getFieldValue().dataPesquisa) });
                            filtrosAvancados();
                        }} />
                    </Col>
                    <Col span={24}>
                        <Form.Item name="status" initialValue={0}>
                            <Radio.Group onChange={(record) => filtrosAvancados()}>
                                <Radio value={0}>Todos</Radio>
                                <Radio value={1}>Apenas Cupons Aprovados</Radio>
                                <Radio value={2}>Apenas Cupons Cancelados</Radio>
                                <Radio value={5}>Contingência</Radio>
                            </Radio.Group>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div className="page">
                <Row align="middle" gutter={[0, 8]}>
                    <Col span={24}>
                        <Collapse className="p-relative topDif" accordion ghost destroyInactivePanel={true}>
                            <div className="colDet"></div>
                            <Collapse.Panel key="1" showArrow={false} extra={
                                <Button type="primary" onClick={togglePesquisa}>
                                    {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Realizar pesquisa avançada
                                </Button>
                            }>
                                <Form layout="vertical" name="filtrosVenda" form={form}>
                                    <Row gutter={[8, 0]}>
                                        <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={3}>
                                            <Data label="Emissão de" name="dataInicial" />
                                        </Col>
                                        <Col xs={24} sm={12} md={4} lg={4} xl={4} xxl={3}>
                                            <Data label="Até" name="dataFinal" />
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={4}>
                                            <Form.Item label="Formas de Pagamento" name="formaPagamento">
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    placeholder="Selecione a Forma de Pagamento"
                                                    optionFilterProp="children"
                                                    onChange={(value) => { form.setFieldsValue({ condicaoPagamento: null }); carregarCondicao(value); filtrosAvancados() }}>
                                                    {listaPgto.map((item) => (
                                                        <Select.Option label={item.fpg_descricao} key={item.fpg_id} value={item.fpg_id}> {item.fpg_descricao} </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={12} md={8} lg={8} xl={8} xxl={4}>
                                            <Form.Item label="Condições de Pagamento" name="condicaoPagamento">
                                                <Select
                                                    showSearch
                                                    allowClear
                                                    optionFilterProp="children"
                                                    placeholder="Selecione a Condição de Pagamento"
                                                    onChange={() => filtrosAvancados()}
                                                >
                                                    {listaCondicoes.map((item) => (
                                                        <Select.Option label={item.cpg_descricao} key={item.cpg_id} value={item.cpg_id}> {item.cpg_descricao}</Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={14} xxl={10}>
                                            <Form.Item label="Finalidade" name="finalidade" initialValue={[1, 2]}>
                                                <Select mode="multiple" allowClear
                                                    placeholder="Filtrar pela Finalidade da Nota/Cupom">
                                                    {finalidade.map((item) => (
                                                        <Select.Option label={item.value} key={item.key} value={item.key}> {item.value} </Select.Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={10} xxl={6}>
                                            <Form.Item label="Produtos" name="pro_id">
                                                <SelectProduto
                                                    detalhes={true}
                                                    placeholder="Selecione o Produto"
                                                    name="pro_id"
                                                    allowClear
                                                    form={form}
                                                    onChange={() => { filtrosAvancados() }}
                                                    ordem={"pro_id"} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={5} className="t-mob-1600">
                                            <Row justify="end" gutter={[8, 8]}>
                                                <Col xs={24} sm={12} md={4} lg={4} xl={3} xxl={12}>
                                                    <Button icon={<CloseOutlined />} onClick={() => { form.resetFields(); filtrosAvancados(); }} block>
                                                        Remover Filtros
                                                    </Button>
                                                </Col>
                                                <Col xs={24} sm={12} md={4} lg={4} xl={3} xxl={12}>
                                                    <Button onClick={() => filtrosAvancados()} type="primary" icon={<SearchOutlined />} block>
                                                        Aplicar Filtros
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                </Row>
            </div>
            <div class="tabela">
                <TabelaDados url="notaFiscal/Listar" headerTabela={false} colunas={[
                    {
                        title: '',
                        render: (record, { ntf_docfiscal, ntf_status, ntf_numero, ser_serie, ntf_dataemissao, tip_descricao, ntf_nomedest, ntf_cnpjcpfdest, ntf_chavenfe, vendedor, valor_total, ntf_finalidade, ntf_modelo }) => (
                            <div>
                                <Row align="middle">
                                    <Col span={14}>
                                        <Row align="middle" gutter={[8, 0]} >
                                            <Col>
                                                <Tag color={((ntf_status === 0) ? 'default' : (ntf_status === 1 ? 'green' : (ntf_status === 2 ? 'gold' : (ntf_status === 3 ? 'orange' : (ntf_status === 4 ? 'red' : (ntf_status === 5 ? 'lime' : ''))))))}>
                                                    <b>
                                                        {retornaDescricaoStatus(ntf_status)}
                                                    </b>
                                                </Tag>
                                            </Col>
                                            <Col>
                                                <b>{tip_descricao}</b>
                                            </Col>
                                            <Col>
                                                Nº: <b>{ntf_numero}</b>
                                            </Col>
                                            <Col>
                                                Série: <b>{ser_serie}</b> <b> {ntf_modelo === 55 ? '- NF-e' : ntf_modelo === 65 ? '- NFC-e' : ntf_modelo === '00' ? '- D.A.V' : ''}</b>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={10}>
                                        <Row align="middle" justify="end" gutter={[5, 5]}>
                                            <Col>
                                                <Tooltip title="Visualizar Detalhes" placement="top" onClick={() => (exibirDetalhesNF(record))}>
                                                    <Button icon={<EyeOutlined />} />
                                                </Tooltip>
                                            </Col>
                                            {ntf_status != 2 && (ntf_finalidade === 1 || ntf_finalidade === 4) &&
                                                <Col>
                                                    <Tooltip title="Reimprimir o Cupom Fiscal" placement="top">
                                                        <Button icon={<PrinterOutlined />} onClick={() => reimprimirCupom(record)} />
                                                    </Tooltip>
                                                </Col>
                                            }
                                            {ntf_status === 5 && ntf_docfiscal === true &&
                                                <Col>
                                                    <Tooltip title="Enviar o Cupom Fiscal" placement="top">
                                                        <Button icon={<CheckOutlined />} onClick={() => enviarNotaContingencia(record)} />
                                                    </Tooltip>
                                                </Col>
                                            }

                                            {(ntf_status === 1 || ntf_status === 0) && ntf_docfiscal === false &&
                                                <Col>
                                                    <Tooltip title="Cancelar a DAV" placement="top">
                                                        <Button icon={<CloseOutlined />} onClick={() => cancelarVenda(record)} />
                                                    </Tooltip>
                                                </Col>
                                            }
                                            {ntf_status === 1 && ntf_docfiscal === true &&
                                                <Col>
                                                    <Tooltip title="Cancelar Cupom Fiscal" placement="top">
                                                        <Button icon={<CloseOutlined />} onClick={() => cancelarVenda(record)} />
                                                    </Tooltip>
                                                </Col>
                                            }
                                            {(ntf_status === 0 || ntf_status === 4) && ntf_docfiscal === true &&
                                                <Col>
                                                    <Tooltip title="Cancelar e Inutilizar o Cupom Fiscal" placement="left">
                                                        <Button icon={<CloseOutlined />} onClick={() => cancelarVenda(record)} />
                                                    </Tooltip>
                                                </Col>
                                            }
                                            <Col>
                                                <Dropdown overlay={menuTable(record)} trigger={['click']}>
                                                    <Button onClick={e => e.preventDefault()} icon={<MoreOutlined />} />
                                                </Dropdown>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                            <Col span={6}>
                                                Data de Emissão: <b>{moment(ntf_dataemissao).format("DD/MM/YYYY HH:mm:ss")}</b>
                                            </Col>
                                            <Col span={11}>
                                                Cliente: <b>{ntf_nomedest || 'Não Informado'}</b>
                                            </Col>
                                            <Col span={7} align="right">
                                                CPF/CNPJ: <b>{formatarCpfCnpj(ntf_cnpjcpfdest) || 'Não Informado'}</b>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={24}>
                                        <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                            <Col span={6}>
                                                Vendedor: <b>{vendedor}</b>
                                            </Col>
                                            <Col span={6}>
                                                Chave NF-e: <b>{ntf_chavenfe}</b>
                                            </Col>
                                            <Col span={6} align="right">
                                                Valor da Venda (R$): <b>{FormatNumber(valor_total, true)}</b>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        )
                    }
                ]}
                />
            </div>
            <ModalDetalhesNotaFiscal detalhesNF={detalhesNF} setDetalhesNF={setDetalhesNF} exibirModalDetalhesNotaFiscal={openModalDetalhesNotaFiscal} fecharModalDetalhesNotaFiscal={() => setOpenModalDetalhesNotaFiscal(false)} />
            <ModalAjudaRejeicaoNfe exibirModalAjudaRejeicaoNfe={openModalAjudaRejeicaoNfe} fecharModalAjudaRejeicaoNfe={() => { setOpenModalAjudaRejeicaoNfe(false); recarregarLista(); }} />
            <ModalCancelarVenda detalhesNF={detalhesNF} exibirModalCancelarVenda={openModalCancelarVenda} fecharModalCancelarVenda={() => { setOpenModalCancelarVenda(false) }} atualizaLista={atualizaLista} setAtualizaLista={setAtualizaLista} />
            <ModalReenviarSefaz dadosNota={dadosNota} titleModal="Reenviar NF-e Sefaz" exibirModalReenviarSefaz={openModalReenviarSefaz} fecharModalReenviarSefaz={() => { setModalReenviarSefaz(false); recarregarLista() }} />
            <ModalEstornoNfe detalhesNF={detalhesNF} exibirModal={openModalEstorno} fecharModal={() => { setOpenModalEstorno(false); recarregarLista(); }} />
        </>
    );
}