import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "antd";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { dadosPdvActions, manutencaoActions } from "../../actions";
import { ItemSelecionar, TabelaItem } from "./pages";
import { carregarDadosPdv, isObjetoDiffVazio } from "../../services/funcoes";
import { getDadosNota, getLocal } from "../../services/auth";

export default function Venda() {

    const [{ manutencao, dadosPdv }, dispatch] = useStateValue();
    const [itensSelecionados, setItensSelecionados] = useState([]);
    const [dadosVendedor, setDadosVendedor] = useState({});
    const [dadosCliente, setDadosCliente] = useState({});
    const [serieFiscal, setSerieFiscal] = useState();
    const [atualizaProd, setAtualizaProd] = useState(false);

    useEffect(() => {
        if (isObjetoDiffVazio(dadosCliente)) {
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ clienteDados: dadosCliente } } } });
        }
    }, [dadosCliente])

    useEffect(() => {
        if (isObjetoDiffVazio(dadosVendedor)) {
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ vendedorDados: dadosVendedor } } } });
        }
    }, [dadosVendedor])

    useEffect(() => {
        if (itensSelecionados.length > 0) {
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ itens: itensSelecionados } } } });
        }
    }, [itensSelecionados])

    useEffect(() => {
        let consumidorFinal = {
            pes_nome: 'CONSUMIDOR FINAL',
            pes_cpf: ''
        }
        var dadosNotaF5 = getDadosNota();
        if (dadosNotaF5 != null && dadosNotaF5 != 'null') {
            consumidorFinal = dadosNotaF5.cliente;
            setItensSelecionados(dadosNotaF5.itens ?? (dadosNotaF5.notafiscalitens ?? []));
            dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados } } });
        }
        setDadosCliente(consumidorFinal);
    }, [])

    useEffect(() => {
        console.log("Vendas", manutencao.dados)
    }, [manutencao.dados])

    async function carregarCaixaAberto(pdv) {
        api.get(`ControleCaixa/ListaControleCaixaAberto/${pdv.pdv_id}`).then(
            res => {
                if (!res.data) {
                    window.location = "/Home#"
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }
    async function carregarSerieFiscal() {
        if (!serieFiscal) {
            if (!!dadosPdv?.pdv) {
                api.get(`SerieFiscal/Buscar/${dadosPdv.pdv?.ser_id}`).then(
                    res => {
                        setSerieFiscal(res.data);
                    }
                ).catch(
                    error => {
                        console.log(error);
                    }
                )
            }
        }
    }

    useEffect(() => {
        if (!!dadosPdv?.pdv) {
            carregarCaixaAberto(dadosPdv?.pdv);
        } else {
            verificaPdv();
        }
        carregarSerieFiscal();
    }, [])

    async function verificaPdv() {
        let res = await carregarDadosPdv();
        dispatch({ type: dadosPdvActions.CHANGE, data: { pdv: res } });
    }

    useEffect(() => {
        if (manutencao.voltaVenda) {
            if (!!manutencao.dados) {
                if (!!manutencao.dados.vendedor) {
                    setDadosVendedor(manutencao.dados.vendedor);
                }
                setDadosCliente(!!manutencao.dados.cliente ? manutencao.dados.cliente : !!manutencao.dados.clienteDados ? manutencao.dados.clienteDados : {});
                setItensSelecionados(!!manutencao.dados.itens && manutencao.dados.itens.length > 0 ? manutencao.dados.itens : []);
                setAtualizaProd(true);
            }
        }
    }, [manutencao.voltaVenda])

    return (
        <div className="div-col-vendas">
            <Form layout="vertical" className="form-venda">
                <Row className="hv-100 b-1">
                    <Col span={12} className="hv-100 p-b-30 b-r-1">
                        <ItemSelecionar itensSelecionados={itensSelecionados} setItensSelecionados={setItensSelecionados} dadosVendedor={dadosVendedor} setDadosVendedor={setDadosVendedor} dadosCliente={dadosCliente} setDadosCliente={setDadosCliente} setAtualizarListaProd={setAtualizaProd} atualizarListaProd={atualizaProd} />
                    </Col>
                    <Col span={12} className="hv-100 p-b-30">
                        <TabelaItem itensSelecionados={itensSelecionados} setItensSelecionados={setItensSelecionados} dadosVendedor={dadosVendedor} setDadosVendedor={setDadosVendedor} dadosCliente={dadosCliente} setDadosCliente={setDadosCliente} serieFiscal={serieFiscal} />
                    </Col>
                </Row>
            </Form>
        </div>
    );
}