import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Table, Tooltip, Divider } from "antd";
import { QuestionCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import moment from "moment";

import api from "../../services/api";
import { FormatNumber } from "../../ValueObjects";

export default function VendasPorVendedor({ valoresTotais, datas }) {

    const [listaVendedor, setListaVendedor] = useState([]);

    function carregarDados() {
        api.get(`Vendedor/ListarVendasPorVendedor?DataFinal=${!!datas.dataFinal ? datas.dataFinal : moment(new Date()).format('YYYY-MM-DD')}&DataInicial=${!!datas.dataInicial ? datas.dataInicial : moment(new Date()).format('YYYY-MM-DD')}`).then(
            res => {
                let dados = res.data.items;
                let valores = {
                    qtde: 0,
                    valorVendaLiquida: 0
                };
                dados.forEach(item => {
                    valores.qtde += item.quantidadevendas;
                    valores.valorVendaLiquida += item.valorvendaliquida;
                })
                valoresTotais.setValoresTotais({ ...valoresTotais, ...valores });
                setListaVendedor(dados);
            }
        ).catch(
            error => {
                console.log(error);
            }
        );
    }

    useEffect(() => {
        carregarDados();
    }, [datas])

    return (
        <Row align="middle" gutter={[8, 8]} className="m-t-16">
            <Col span={24}>
                <div>
                    <Typography.Title level={5}>
                        <Row gutter={[5, 0]}>
                            <Col>
                                VENDAS POR VENDEDOR
                            </Col>
                            <Col>
                                <Tooltip title="Os valores abaixo representam as vendas com status Autorizada e em Contingência!">
                                    <QuestionCircleOutlined />
                                </Tooltip>
                            </Col>
                        </Row>
                    </Typography.Title>
                </div>
                <div className="tabela mt-zero">
                    <Table
                        dataSource={listaVendedor}
                        scroll={{ y: 163, x: 900 }}
                        pagination={false}
                        columns={[
                            {
                                title: 'Vendedor',
                                width: 240,
                                render: ({ pes_nome, quantidadevendas, ticketmedio }) => (
                                    <div>
                                        <Row gutter={[8, 0]}>
                                            <Col span={24}>
                                                <b>{pes_nome}</b>
                                            </Col>
                                            <Col>
                                                Nº Vendas:
                                            </Col>
                                            <Col>
                                                <b>{quantidadevendas}</b>
                                            </Col>
                                            <Col>
                                                Média:
                                            </Col>
                                            <Col>
                                                <b>{FormatNumber(ticketmedio, true)}</b>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                            {
                                title: 'A vista (R$)',
                                align: 'right',
                                render: ({ totalavista }) => (
                                    <div>
                                        <b>{FormatNumber(totalavista, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'A Prazo (R$)',
                                align: 'right',
                                render: ({ totalaprazo }) => (
                                    <div>
                                        <b>{FormatNumber(totalaprazo, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Total (R$)',
                                align: 'right',
                                render: ({ valorvendaliquida }) => (
                                    <div>
                                        <b>{FormatNumber(valorvendaliquida, true)}</b>
                                    </div>
                                ),
                            },
                            {
                                title: 'Média (R$)',
                                align: 'right',
                                render: ({ ticketmedio }) => (
                                    <div>
                                        <b>{FormatNumber(ticketmedio, true)}</b>
                                    </div>
                                )
                            }
                        ]} locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined/> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>
                            )
                        }}
                    />
                </div>
            </Col>
        </Row >
    );
}