import React, { useState, useEffect } from "react";
import { Row, Col, Button, Table, Divider, Modal } from "antd";
import { ExclamationCircleOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { MaskFormat } from "../../../ValueObjects";
import { isObjetoDiffVazio } from "../../../services/funcoes";

export default function ModalBuscaPessoaAutorizada({ exibirModalBuscaPessoaAutorizada, fecharModalBuscaPessoaAutorizada, dadosCliente }) {

    const [listaPessoasAutorizadas, setListaPessoasAutorizadas] = useState([]);
    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalBuscaPessoaAutorizada();
            }
        });
    };

    useEffect(() => {
        if (isObjetoDiffVazio(dadosCliente)) {
            carregarDados();
        }
    }, [dadosCliente])

    function carregarDados() {
        api.get(`PessoaAutorizada/Listar?cli_id=${dadosCliente.cli_id}`).then(
            res => {
                setListaPessoasAutorizadas(res.data);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }

    return (
        <Modal centered
            title="Pessoas Autorizadas"
            open={exibirModalBuscaPessoaAutorizada}
            getContainer={false}
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            width={720}
            onCancel={onCloseModal}
            footer={false}
        >
            <Row align="middle">
                <Col span={24}>
                    <Table
                        columns={[
                            {
                                render: ({ pes_nome, pef_cpf, grp_descricao }) => (
                                    <div>
                                        <Row gutter={[8, 0]}>
                                            <Col span={24}>
                                                <b>{pes_nome}</b>
                                            </Col>
                                            <Col>
                                                CPF/CNPJ: <b>{MaskFormat(pef_cpf, 'Não Informado', true)}</b>
                                            </Col>
                                            <Col>
                                                Tipo Pessoa: <b>{grp_descricao}</b>
                                            </Col>
                                        </Row>
                                    </div>
                                ),
                            },
                            {
                                dataIndex: '',
                                align: 'right',
                                key: 'x',
                                width: 110,
                                render: () => (
                                    <div>
                                        <Button block type="primary">
                                            Selecionar
                                        </Button>
                                    </div>
                                ),
                            },
                        ]} locale={{
                            emptyText: (
                                <Row>
                                    <Col span={24}>
                                        <Divider orientation="center">
                                            <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                        </Divider>
                                    </Col>
                                </Row>)
                        }} dataSource={listaPessoasAutorizadas} scroll={{ y: 160 }} pagination={false} showHeader={false}
                    />
                </Col>
            </Row>
        </Modal>

    );
}