import React from "react";
import { Row, Col, Form, Modal, Input, Result, notification } from "antd";
import { ExclamationCircleOutlined, LockOutlined, EyeTwoTone, EyeInvisibleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import api from "../../../services/api";

export default function ModalSenhaGerencial({ exibirModalSenhaGerencial, fecharModalSenhaGerencial }) {

    const [form] = Form.useForm();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalSenhaGerencial(false);
            }
        });
    };

    const salvarDados = values => {
        api.get(`Local/ValidarSenhaGerencial?senhaGerencial=${values.senhaGerencial}`).then(
            res => {
                if (res.data) {
                    fecharModalSenhaGerencial(true);
                } else {
                    notification.warning({ message: 'Senha incorreta!', description: 'Favor informar uma senha válida!' });
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        ).finally(
            () => {
                form.setFieldsValue({ senha: null });
            }
        )
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault(); // Evitar o comportamento padrão de submit
            form.submit();
        }
    };


    return (
        <Modal centered
            title="Senha Gerencial"
            open={exibirModalSenhaGerencial}
            onCancel={onCloseModal}
            onOk={() => form.submit()}
            okText={
                <>
                    <CheckOutlined /> Liberar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            getContainer={false}
        >
            <Form layout="vertical" name="manutencaoSenhaGerencial" form={form} onFinish={salvarDados}
                onKeyDown={handleKeyDown}>
                <Result
                    icon={<LockOutlined />}
                    title="Informe a Senha Gerencial"
                    extra={
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Senha" name="senhaGerencial">
                                    <Input.Password
                                        placeholder="Informe a Senha Gerencial"
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                />
            </Form>
        </Modal>
    );
}