import React, { useEffect, useState } from "react";
import { Row, Col, Form, Tabs, Select, Checkbox, Tooltip, Input, Upload, message, InputNumber, Button, Modal } from "antd";
import { QuestionCircleOutlined, CloseOutlined, SaveOutlined, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";

import TabPdv from './tabPdv';
import api from "../../services/api";
import { useStateValue } from "../../state";
import { InputPreco, BreadcrumbPage } from "../../components";
import { parametrosEmpresaActions } from "../../actions";
import { getEmpresa, linkApi } from "../../services/auth";

export default function ParametrosEmpresa() {

    const [{ manutencao, parametrosEmpresa }, dispatch] = useStateValue();
    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [carregando, setCarregando] = useState(false);
    const [loading, setLoading] = useState(false);
    const [listaImagens, setListaImagens] = useState([]);
    const [imageUrl, setImageUrl] = useState(null);
    const [formulario] = Form.useForm();
    const [operacaoFiscal, setOperacaoFiscal] = useState([]);
    const [listaContaGerencial, setListaContaGerencial] = useState([]);
    const [listaContaGerencialAgrupador, setListaContaGerencialAgrupador] = useState([]);
    const [custo, setCusto] = useState([]);
    const [impressaoComprovantes, setImpressaoComprovantes] = useState([]);
    const [regimeTributarioFiscal, setRegimeTributarioFiscal] = useState([]);
    const [enquadramentoFiscal, setEnquadramentoFiscal] = useState([]);

    const onChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
        }
        if (info.file.status !== 'removed') {
            getBase64(info.file.originFileObj, (imageUrl) => {
                setLoading(false);
                setImageUrl(imageUrl);
            });
        }
        if (info.file.status === 'removed') {
            setImageUrl(null);
        }

        setListaImagens(info.fileList);
    };

    function getBase64(img, callback) {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    }

    function beforeUpload(file) {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('Você só pode fazer upload de arquivos JPG/PNG!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('A imagem deve ter menos de 2 MB!');
        }
        return isJpgOrPng && isLt2M;
    }

    const uploadButton = (
        <div>
            {loading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">  {loading ? 'Carregando...' : 'Selecionar Imagem'}</div>
        </div>
    );

    useEffect(() => {
        api.get(`Empresa/Buscar/${getEmpresa()}`).then(retornoEmpresa => {
            formulario.setFieldsValue({
                emp_nomefantasia: retornoEmpresa.data.emp_nomefantasia,
                emp_razaosocial: retornoEmpresa.data.emp_razaosocial,
                emp_regimetributario: retornoEmpresa.data.emp_regimetributario,
                emp_enquadramentofiscal: retornoEmpresa.data.emp_enquadramentofiscal
            });
            let url = `${linkApi}Empresa/RetornaLogo?id=${getEmpresa()}`;
            setImageUrl(retornoEmpresa.data.emp_logo);
            setListaImagens([{ url: url, uid: 1 }]);
        }).catch((erro) => console.error(erro));

        api.get('OperacaoFiscal/Listar').then(res => {
            if (res.status === 200) {
                setOperacaoFiscal(res.data);
            }
        }).catch((erro) => console.error(erro));

        api.get('ContaGerencial/ListarAtivosEstruturadoPelaCategoria?filtro=&nroRegistros=1000&pagina=1').then(res => {
            setListaContaGerencialAgrupador(res.data.dados.filter((item) => { return item.podeSelecionar === false }));
            setListaContaGerencial(res.data.dados.filter((item) => { return item.podeSelecionar === true }));
        }).catch((erro) => console.error(erro));

        api.get('Enum/Listar?nome=TipoPrecoUtilizar').then(res => {
            if (res.status === 200) {
                setCusto(res.data.filter((item) => { return item.key > 0; }));
            }
        }).catch((erro) => console.error(erro));

        api.get('Enum/Listar?nome=ImpressaoComprovantes').then(res => {
            if (res.status === 200) {
                setImpressaoComprovantes(res.data);
            }
        }).catch((erro) => console.error(erro));

        api.get('ParametroEmpresa/BuscarParametros').then(res => {
            if (res.status === 200) {
                dispatch({ type: parametrosEmpresaActions.CHANGE, data: res.data });
                formulario.setFieldsValue(res.data);
            }
        }).catch((erro) => console.error(erro));

        api.get(`Enum/Listar?nome=RegimeTributarioFiscal`).then(res => {
            if (res.status === 200) {
                setRegimeTributarioFiscal(res.data);
            }
        }).catch((erro) => console.error(erro));

        api.get(`Enum/Listar?nome=EnquadramentoFiscal`).then(res => {
            if (res.status === 200) {
                setEnquadramentoFiscal(res.data);
            }
        }).catch((erro) => console.error(erro));
    }, []);

    function salvarParametros() {
        let dadosParametrosEmpresa = formulario.getFieldValue();

        dadosParametrosEmpresa.par_contagerencialsangriapdv = !!dadosParametrosEmpresa.par_contagerencialsangriapdv ? dadosParametrosEmpresa.par_contagerencialsangriapdv : null;
        dadosParametrosEmpresa.par_contagerencialsuprimentopdv = !!dadosParametrosEmpresa.par_contagerencialsuprimentopdv ? dadosParametrosEmpresa.par_contagerencialsuprimentopdv : null;
        dadosParametrosEmpresa.par_contagerencialvalepresente = !!dadosParametrosEmpresa.par_contagerencialvalepresente ? dadosParametrosEmpresa.par_contagerencialvalepresente : null;
        dadosParametrosEmpresa.par_contagervalepresenteprazo = !!dadosParametrosEmpresa.par_contagervalepresenteprazo ? dadosParametrosEmpresa.par_contagervalepresenteprazo : null;
        dadosParametrosEmpresa.par_ctg_recebimentoparcela = !!dadosParametrosEmpresa.par_ctg_recebimentoparcela ? dadosParametrosEmpresa.par_ctg_recebimentoparcela : null;
        dadosParametrosEmpresa.par_viasimpressaonfce = !!dadosParametrosEmpresa.par_viasimpressaonfce ? dadosParametrosEmpresa.par_viasimpressaonfce : 1;
        dadosParametrosEmpresa.par_impressaodavls = !!dadosParametrosEmpresa.par_impressaodavls ? dadosParametrosEmpresa.par_impressaodavls : null;
        dadosParametrosEmpresa.par_impressaonfce = !!dadosParametrosEmpresa.par_impressaonfce ? dadosParametrosEmpresa.par_impressaonfce : null;
        dadosParametrosEmpresa.par_impressaocondicional = !!dadosParametrosEmpresa.par_impressaocondicional ? dadosParametrosEmpresa.par_impressaocondicional : null;
        dadosParametrosEmpresa.par_impressaorecbtoparcela = !!dadosParametrosEmpresa.par_impressaorecbtoparcela ? dadosParametrosEmpresa.par_impressaorecbtoparcela : null;
        dadosParametrosEmpresa.par_custopadraonftransferencia = !!dadosParametrosEmpresa.par_custopadraonftransferencia ? dadosParametrosEmpresa.par_custopadraonftransferencia : null;
        dadosParametrosEmpresa.ope_id = !!dadosParametrosEmpresa.ope_id ? dadosParametrosEmpresa.ope_id : null;
        dadosParametrosEmpresa.emp_id = getEmpresa();
        dadosParametrosEmpresa.emp_logo = imageUrl;

        setCarregando(true);
        api.post("ParametrosEmpresa/ManutencaoParametrosEmpresa", dadosParametrosEmpresa).then(res => {
            if (res.status === 200) {
                Modal.success({
                    title: 'Parametros da empresa atualizados',
                    content: 'As definições dos parâmetros do Gestor ERP para sua empresa foram atualizados com sucesso!',
                    onOk() {
                        onOk();
                    }
                });
            } else {
                Modal.error({
                    title: 'Não foi possivel salvar os parâmetros.',
                    onOk() {
                        onOk();
                    }
                });
            }
        }, erro => {
            Modal.error({
                title: 'Não foi possivel salvar os parâmetros.',
                content: erro.message,
                onOk() {
                    onOk();
                }
            });
        }).finally(() => {
            atualizarParametrosEmpresa();
            setCarregando(false);
        });
    };

    function onOk() {
        //window.history.back();
    };

    function atualizarParametrosEmpresa() {
        api.get('ParametroEmpresa/BuscarParametros').then(res => {
            if (res.status === 200) {
                dispatch({ type: parametrosEmpresaActions.CHANGE, data: res.data });
            }
        }).catch((erro) => console.error(erro));
    };

    return (
        <div>
            <div className="pages-col">
                <BreadcrumbPage pagina_seleciona="Configuração Empresa" />
                <Form className="perfil" layout="vertical" form={formulario} name="parametrosEmpresa" onFinish={salvarParametros}>
                    <Row align="middle" justify="end" gutter={[0, 24]}>
                        <Col span={24}>
                            <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                                <Tabs.TabPane tab="Configurações Gerais" key="1">
                                    <Form.Item name="emp_logo" hidden><Input /></Form.Item>
                                    <Row align="middle" justify="center" gutter={[16, 16]}>
                                        <Col xxl={3} xl={4} lg={4} md={5} sm={5} xs={8} align="center">
                                            <ImgCrop
                                                rotate
                                                modalTitle="Ajuste sua imagem"
                                                modalOk="Salvar">
                                                <Upload
                                                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                                                    listType="picture-card"
                                                    onChange={onChange}
                                                    className="imgEmpresa"
                                                    showUploadList={true}
                                                    fileList={listaImagens}
                                                    beforeUpload={beforeUpload}
                                                    maxCount={1}
                                                >
                                                    {listaImagens.length === 0 && uploadButton}
                                                </Upload>
                                            </ImgCrop>
                                        </Col>
                                        <Col xxl={21} xl={20} lg={24} md={24} sm={24} xs={24}>
                                            <Row gutter={[8, 0]}>
                                                <Col xl={14} lg={14} md={14} sm={24} xs={24}>
                                                    <Form.Item name="emp_razaosocial" label="Razão Social">
                                                        <Input placeholder="Informe a Razão Social" />
                                                    </Form.Item>
                                                </Col>
                                                <Col xl={10} lg={10} md={10} sm={24} xs={24}>
                                                    <Form.Item name="emp_nomefantasia" label="Nome Fantasia">
                                                        <Input placeholder="Informe o Nome Fantasia" />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[8, 0]}>
                                                <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                                                    <Form.Item label="Regime Tributário Fiscal" name="emp_regimetributario">
                                                        <Select allowClear placeholder="Selecione o Regime Tributário Fiscal" showSearch optionFilterProp="children">
                                                            {regimeTributarioFiscal.map((item) => (
                                                                <Select.Option value={item.key} key={item.key} label={item.value}>{item.value}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                                                    <Form.Item label="Enquadramento Fiscal" name="emp_enquadramentofiscal">
                                                        <Select placeholder="Selecione o Enquadramento Fiscal" showSearch optionFilterProp="children" allowClear>
                                                            {enquadramentoFiscal.map((item) => (
                                                                <Select.Option value={item.key} key={item.key} label={item.value}>{item.value}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                                                    <Form.Item name="ope_id" label={
                                                        <div>
                                                            Operação Fiscal&nbsp;
                                                            <Tooltip title="Padrão para Documentos Fiscais">
                                                                <QuestionCircleOutlined />
                                                            </Tooltip>
                                                        </div>
                                                    }>
                                                        <Select placeholder="Selecione a Operação Fiscal" showSearch optionFilterProp="children" allowClear>
                                                            {operacaoFiscal.map((item) => (
                                                                <Select.Option value={item.ope_id} key={item.ope_id} label={item.ope_descricao}>{item.ope_descricao}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                                                    <Form.Item name="par_ctgrecebimentoparcela" label={
                                                        <div>
                                                            Conta Gerencial Recebimento&nbsp;
                                                            <Tooltip title="Para Recebimento de Parcela">
                                                                <QuestionCircleOutlined />
                                                            </Tooltip>
                                                        </div>
                                                    }>
                                                        <Select allowClear showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial">
                                                            {listaContaGerencialAgrupador.map(item => (
                                                                <Select.OptGroup label={item.ctg_descricao}>
                                                                    {listaContaGerencial.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                                                        <Select.Option key={conta.ctg_id} value={conta.ctg_id} label={conta.ctg_descricao}>{conta.ctg_descricao}</Select.Option>
                                                                    ))}
                                                                </Select.OptGroup>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                                                    <Form.Item label="Conta Gerencial Vale Presente (à vista)" name="par_contagerencialvalepresente">
                                                        <Select allowClear showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial">
                                                            {listaContaGerencialAgrupador.map(item => (
                                                                <Select.OptGroup label={item.ctg_descricao}>
                                                                    {listaContaGerencial.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                                                        <Select.Option key={conta.ctg_id} value={conta.ctg_id} label={conta.ctg_descricao}>{conta.ctg_descricao}</Select.Option>
                                                                    ))}
                                                                </Select.OptGroup>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xl={8} lg={8} md={8} sm={12} xs={24}>
                                                    <Form.Item label="Conta Gerencial Vale Presente (à prazo)" name="par_contagervalepresenteprazo">
                                                        <Select allowClear showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial">
                                                            {listaContaGerencialAgrupador.map(item => (
                                                                <Select.OptGroup label={item.ctg_descricao}>
                                                                    {listaContaGerencial.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                                                        <Select.Option key={conta.ctg_id} value={conta.ctg_id} label={conta.ctg_descricao}>{conta.ctg_descricao}</Select.Option>
                                                                    ))}
                                                                </Select.OptGroup>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={4} xl={5} lg={8} md={8} sm={12} xs={24} className="col-check-top">
                                                    <Form.Item name="par_usalimitecredito" valuePropName="checked">
                                                        <Checkbox> Usar Limite de Crédito? </Checkbox>
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={5} xl={7} lg={8} md={8} sm={12} xs={24}>
                                                    <Form.Item label="% da Renda Para o Limite de Crédito" name="par_percentuallimitecredito">
                                                        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.par_usalimitecredito !== currentValues.par_usalimitecredito}>
                                                            {({ getFieldValue }) => (
                                                                <InputNumber disabled={!getFieldValue().par_usalimitecredito}
                                                                    min={0}
                                                                    max={100}
                                                                    placeholder="Informe o Percentual do Limite de Crédito"
                                                                />
                                                            )}
                                                        </Form.Item>
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={4} xl={5} lg={8} md={8} sm={12} xs={24}>
                                                    <InputPreco label={
                                                        <div>
                                                            Markup Padrão &nbsp;
                                                            <Tooltip title="Para Composição de Preço de Produtos">
                                                                <QuestionCircleOutlined />
                                                            </Tooltip>
                                                        </div>
                                                    } placeholder="Informe o Markup Padrão" name="par_markupproduto" cifrao={false}></InputPreco>
                                                </Col>
                                                <Col xxl={5} xl={7} lg={7} md={7} sm={12} xs={24}>
                                                    <Form.Item label="Nº de Dias para Retorno de Condicional" name="par_diasretornocondicional">
                                                        <InputNumber min={0} max={100} placeholder="Nº de dias para Retorno do Condicional" />
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={3} xl={5} lg={6} md={6} sm={12} xs={24}>
                                                    <Form.Item name="par_diastoleranciaparcela" label={
                                                        <div>
                                                            Nº Dias Tolerância &nbsp;
                                                            <Tooltip title="Para Alterar o Vencimento da Parcela">
                                                                <QuestionCircleOutlined />
                                                            </Tooltip>
                                                        </div>
                                                    }>
                                                        <InputNumber min={0} max={100} placeholder="Informe os dias de Tolerância" />
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={3} xl={5} lg={6} md={6} sm={12} xs={24}>
                                                    <Form.Item name="par_diastoleranciacobrancajuro" label={
                                                        <div>
                                                            Nº Dias Tolerância &nbsp;
                                                            <Tooltip title="Para Cobrança de Juros">
                                                                <QuestionCircleOutlined />
                                                            </Tooltip>
                                                        </div>
                                                    }>
                                                        <InputNumber min={0} max={100} placeholder="Informe os dias de Tolerância" />
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={3} xl={4} lg={5} md={5} sm={12} xs={24}>
                                                    <Form.Item name="par_percjuromesparcelavencida" label={
                                                        <div>
                                                            % Juros&nbsp;
                                                            <Tooltip title="Para NF de Transferência. Informe o preço a ser utilizado na emissão desta nota.">
                                                                <QuestionCircleOutlined />
                                                            </Tooltip>
                                                        </div>
                                                    }>
                                                        <InputNumber
                                                            min={0}
                                                            max={100}
                                                            placeholder="Informe o Percentual de Juros"
                                                            formatter={value => `${value} %`}
                                                            decimalSeparator={','}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col xxl={4} xl={5} lg={6} md={6} sm={12} xs={24}>
                                                    <Form.Item name="par_custopadraonftransferencia" label={
                                                        <div>
                                                            Custo Padrão&nbsp;
                                                            <Tooltip title="Para NF de Transferência. Informe o preço a ser utilizado na emissão desta nota.">
                                                                <QuestionCircleOutlined />
                                                            </Tooltip>
                                                        </div>
                                                    }>
                                                        <Select placeholder="Selecione um Custo Padrão" showSearch optionFilterProp="children" allowClear>
                                                            {custo.map((item) => (
                                                                <Select.Option value={item.key} key={item.key} label={item.value}>{item.value}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                                <Col className="col-check-top">
                                                    <Form.Item name="par_imprimirdescontocadaitem" valuePropName="checked">
                                                        <Checkbox> Imprimir Desconto em cada Item do Cupom Fiscal? </Checkbox>
                                                    </Form.Item>
                                                </Col>
                                                <Col className="col-check-top">
                                                    <Form.Item name="par_usanotapromissoria" valuePropName="checked">
                                                        <Checkbox> Usa Promissória? </Checkbox>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row gutter={[8, 0]}>
                                        <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.par_usanotapromissoria !== currentValues.par_usanotapromissoria}>
                                            {({ getFieldValue }) => {
                                                if (getFieldValue().par_usanotapromissoria) return (
                                                    <Col span={24}>
                                                        <Form.Item label="Descritivo Promissórias" name="par_descritivopromissoria">
                                                            <Input.TextArea placeholder="Informe o Descritivo Promissórias" />
                                                        </Form.Item>
                                                    </Col>
                                                );
                                                else return null;
                                            }}
                                        </Form.Item>
                                    </Row>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Pontos de Venda (PDV)" key="2">
                                    <TabPdv impressaoComprovantes={impressaoComprovantes} listaContaGerencial={listaContaGerencial} listaContaGerencialAgrupador={listaContaGerencialAgrupador} />
                                </Tabs.TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                    <div class="ant-drawer-footer footer-recebimento">
                        <Row align="middle" justify="end" gutter={[8, 0]}>
                            <Col>
                                <Button icon={<CloseOutlined />} size="large" htmlType="button" onClick={() => {
                                    formulario.resetFields();
                                    onOk();
                                }}>
                                    Cancelar
                                </Button>
                            </Col>
                            <Col>
                                <Button loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                    Salvar
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
        </div>
    );

}