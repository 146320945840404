import React from "react";
import { Row, Col, Table, Divider } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';

export default function Email({ dadosEmail }) {

    return (
        <Row className="tabela">
            <Col span={24}>
                <Table columns={[
                    {
                        title: 'E-mail(s)',
                        render: ({ pem_email }) => (
                            <div>
                                <Row align="middle" gutter={[5, 0]}>
                                    <Col>
                                        <b>{pem_email}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} locale={{
                    emptyText: (
                        <Row>
                            <Col span={24}>
                                <Divider orientation="center">
                                    <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                </Divider>
                            </Col>
                        </Row>)
                }} dataSource={dadosEmail} scroll={{ y: 420 }} pagination={false}/>
            </Col>
        </Row>
    );
}