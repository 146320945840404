import React, { useEffect } from 'react';
import { Select, Form } from "antd";

export default function ComboData({ form, eventoAoAlterar }) {
    const opcoes = [
        { label: 'Dia Atual', value: 0 },
        { label: 'Mês Atual', value: 1 },
        { label: 'Últimos 3 dias', value: 2 },
        { label: 'Últimos 7 dias', value: 3 },
        { label: 'Últimos 15 dias', value: 4 },
        { label: 'Últimos 30 dias', value: 5 },
        { label: 'Últimos 60 dias', value: 6 }
    ];

    class Data extends React.Component {
        onChange = e => {
            const value = e;
            let data = this.value;
            switch (value) {
                case 0:
                    data = new Date();
                    break;
                case 1:
                    data = new Date(new Date().setDate(1));
                    break;
                case 2:
                    data = new Date(new Date().setDate((new Date().getDate() - 3)));
                    break;
                case 3:
                    data = new Date(new Date().setDate((new Date().getDate() - 7)));
                    break;
                case 4:
                    data = new Date(new Date().setDate((new Date().getDate() - 15)));
                    break;
                case 5:
                    data = new Date(new Date().setDate((new Date().getDate() - 30)));
                    break;
                case 6:
                    data = new Date(new Date().setDate((new Date().getDate() - 60)));
                    break;
            }

            if (!!form) {
                form.setFieldsValue({ 'dataPesquisa': data });
            }
            if (!!eventoAoAlterar) {
                eventoAoAlterar();
            }
            this.props.onChange(value);
        }

        render() {
            const { value } = this.props;

            return (
                <Select {...this.props} value={value} onChange={this.onChange}>
                    {opcoes.map((data) => (
                        <Select.Option value={data.value} label={data.label}>{data.label}</Select.Option>
                    ))}
                </Select>
            );
        }
    }

    class InputDemoDate extends React.Component {
        constructor(props) {
            super(props);
            this.state = { value: 0 };
        }
        onChange = value => {
            this.setState({ value });
        }

        render() {
            return (
                <Form.Item name="dtPesq" label="Período" initialValue={0}>
                    <Data value={this.state.value} onChange={this.onChange} />
                </Form.Item>
            )
        }

    }
    /* 
        useEffect(() => {
            form.setFieldsValue({ 'dataPesquisa': new Date(new Date().setDate(1)) });
        }, []); */

    return (
        <InputDemoDate />

    )
};