const drawerActions = {
    CHANGE: 'changeDrawer'
}

const manutencaoActions = {
    CHANGE: 'changeManutencao'
}

const dadosPdvActions = {
    CHANGE: 'changeDadosPdv'
}

const listagemActions = {
    CHANGE: 'changeListagem'
}

const listaSelectPaginadoActions = {
    CHANGE: 'changeListaSelectPaginado'
}

const listaTabelaDadosActions = {
    CHANGE: 'changeListaTabelaDados'
}

const parametrosEmpresaActions = {
    CHANGE: 'changeParametrosEmpresa'
}

const tributacaoActions = {
    CHANGE: 'changeTributacao'
}

export {
    drawerActions, manutencaoActions, dadosPdvActions, listagemActions, listaSelectPaginadoActions, listaTabelaDadosActions, parametrosEmpresaActions, tributacaoActions
}