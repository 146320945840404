import React, { useState, useEffect } from "react";
import moment from 'moment';
import { Row, Col, Table, Tag } from "antd";

import { FormatNumber } from '../../../ValueObjects';

export default function MovimentacaoCartoes({ listaMovimentos }) {

    return (
        <div className="tabela mt-zero">
            <Table
                columns={[
                    {
                        title: 'Movimento por Cartão',
                        render: ({ bnd_nome, recebimentos }) => (
                            <div>
                                <Row align="middle" gutter={[8, 5]}>
                                    <Col>
                                        <b>{bnd_nome}</b>
                                    </Col>
                                </Row>
                                {recebimentos.map((item) => (
                                    <Row align="middle" gutter={[8, 5]}>
                                        <Col span={20}>
                                            {item.fpg_descricao}
                                        </Col>
                                        <Col span={4} align="right">
                                            <b>{FormatNumber(item.rcc_valor, true)}</b>
                                        </Col>
                                    </Row>
                                ))
                                }
                            </div>
                        ),
                    },
                ]} dataSource={listaMovimentos} scroll={{ y: 267 }} pagination={false}
            />
        </div>
    );
}