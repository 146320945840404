import React, { useState } from "react";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import { TabelaDados } from "../../components";
import LocalizarParcela from "./localizarParcela";
import { MaskFormat, FormatFone } from "../../ValueObjects";

export default function LocalizarCliente({ selectedRowKeys, setSelectedRowKeys, receberSelecionados, listaParcelasReceber, showSearchBar, setShowSearchBar }) {

    const [totalSelecionados, setTotalSelecionados] = useState([]);
    const [dadosCliente, setDadosCliente] = useState({});
    const [listaParcelasCliente, setListaParcelasCliente] = useState(false);

    return (
        <div className="tabela m-t-8">
            {!listaParcelasCliente &&
                <TabelaDados url="cliente/listar" paginacao={true} colunas={
                    [{
                        title: 'Cliente',
                        render: ({ pes_nome }) => (
                            <div>
                                <b>{pes_nome} </b>
                            </div>
                        )
                    }, {
                        title: 'CPF/CNPJ',
                        width: 140,
                        render: ({ pef_cpf, pej_cnpj }) => (
                            <div>
                                <b>{!!pef_cpf ? MaskFormat(pef_cpf, '', true) : MaskFormat(pej_cnpj, '', true)}</b>
                            </div>
                        )
                    }, {
                        title: 'Telefone',
                        width: 125,
                        render: ({ psc_numero }) => (
                            <div>
                                <b>{FormatFone(psc_numero) || 'Não Informado'}</b>
                            </div>
                        )
                    }, {
                        dataIndex: '',
                        key: 'x',
                        align: 'center',
                        width: 65,
                        fixed: 'right',
                        render: (record) => (
                            <div>
                                <Button type="primary" onClick={() => {
                                    setDadosCliente(record);
                                    setListaParcelasCliente(true);
                                    setShowSearchBar(true)
                                }} icon={<ArrowRightOutlined />} />
                            </div>
                        )
                    }]}
                />
            }
            {listaParcelasCliente &&
                <LocalizarParcela selectedRowKeys={selectedRowKeys} setSelectedRowKeys={setSelectedRowKeys} selecionados={{ totalSelecionados, setTotalSelecionados }} setListaParcelasCliente={setListaParcelasCliente} receberSelecionados={receberSelecionados} listaParcelasReceber={listaParcelasReceber} dadosCliente={dadosCliente} setDadosCliente={setDadosCliente} showSearchBar={showSearchBar} setShowSearchBar={setShowSearchBar} />
            }
        </div>
    );
}