import React from "react";
import { Form, Input } from "antd";

import { useStateValue } from '../../state';
import { listagemActions } from '../../actions';

export default function FiltroSearch({ labelInput, placeholderInput, nameInput = "", form = null }) {

    const [{ manutencao }, dispatch] = useStateValue();

    return (
        <Form.Item label={labelInput || "Pesquisar por"} name={nameInput}>
            <Input.Search placeholder={placeholderInput || "Código, descrição"} onSearch={valor => {
                dispatch({ type: listagemActions.CHANGE, data: { filtro: valor, pagina: 1 } })
            }
            } onBlur={valor => {
                if (!!form && !!nameInput) {
                    form.setFieldsValue({ [nameInput]: valor.target.value });
                }
            }} allowClear />
        </Form.Item>
    );

}