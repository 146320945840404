import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Divider, Button, Tag, Table, Form, Modal } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from 'moment';

import { useStateValue } from "../../state";
import { manutencaoActions } from "../../actions";
import ModalNacionalidade from "../../components/modals/modalNacionalidade";

export default function Nacionalidade(editando) {

    const tagsData = ['Principal'];
    const [selectedTags, setSelectedTags] = useState(['Principal']);
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const [listaNacionalidade, setListaNacionalidade] = useState(editando ? (manutencao?.dados?.pessoanacionalidades ?? []) : []);
    const [openModalNacionalidade, setOpenModalNacionalidade] = useState(false);
    const [form] = Form.useForm();

    const selecionarOpcao = (tag, checked) => {
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        setSelectedTags(nextSelectedTags);
    }

    useEffect(() => {
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...{ pessoanacionalidades: listaNacionalidade } } } });
    }, [listaNacionalidade]);

    function editarNacionalidade(record, indice) {
        record.indice = indice;
        form.setFieldsValue(record);
        setOpenModalNacionalidade(true);
    };

    function excluirNacionalidade(id) {
        const lista = [...listaNacionalidade];
        const index = lista.findIndex((item) => id === item.pse_id);
        lista.splice(index, 1)
        setListaNacionalidade(lista);
    };

    const modalConfirmaExclusao = (record) => {
        Modal.confirm({
            title: 'Excluir?',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja excluir a nacionalidade ${record.pais.pse_nacionalidade} ?`,
            okText: 'Excluir',
            cancelText: 'Cancelar',
            zIndex: 2010,
            centered: true,
            onOk() {
                excluirNacionalidade(record.pais.pse_id);
            }
        });
    };

    return (
        <div>
            <Row align="middle">
                <Col span={24}>
                    <Typography.Title level={3}>
                        <img src={require("../../assets/i-nacionalidade.png")} alt="Nacionalidade" /> Nacionalidade - (OPCIONAL)
                    </Typography.Title>
                    <Divider orientation="left" plain> Adicione a(s) nacionalidade(s) do cliente aqui. </Divider>
                </Col>
            </Row>
            <Row align="middle" justify="end">
                <Col>
                    <div className="i-Position p-relative">
                        <Button type="primary" shape="circle" size="large" icon={<PlusOutlined />} onClick={() => setOpenModalNacionalidade(true)} />
                    </div>
                </Col>
            </Row>
            <div className="tabela mt-dif">
                <Table columns={
                    [
                        {
                            title: 'País',
                            width: 200,
                            render: ({ pais }) => (
                                <div>
                                    <b>{!!pais ? pais.pse_nome : undefined}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Data de Entrada no País',
                            render: ({ pen_dataentradapais }) => (
                                <div>
                                    <b>{moment(pen_dataentradapais).format("DD/MM/YYYY HH:mm:ss")}</b>
                                </div>
                            ),
                        },
                        {
                            title: 'Observação',
                            render: ({ pen_observacao }) => (
                                <div>
                                    <b>{pen_observacao}</b>
                                </div>
                            ),
                        },
                        {
                            title: '',
                            dataIndex: 'pen_naturalizadobrasil',
                            Key: 'pen_naturalizadobrasil',
                            render: (record) => (
                                tagsData.map(tag => (
                                    <Tag.CheckableTag
                                        key={tag}
                                        checked={selectedTags.indexOf(tag) > -1}
                                        onChange={checked => selecionarOpcao(tag, checked)}
                                    >
                                        {tag}
                                    </Tag.CheckableTag>
                                ))
                            ),
                        },
                        {
                            title: 'Ações',
                            dataIndex: '',
                            align: 'center',
                            key: 'x',
                            width: 65,
                            fixed: 'right',
                            render: (record, obj, indice) => (
                                <div>
                                    <Row align="middle" justify="center" gutter={[5, 0]}>
                                        <Col>
                                            <Button onClick={() => editarNacionalidade(record, indice)} icon={<EditOutlined />} />
                                        </Col>
                                        <Col>
                                            <Button onClick={() => modalConfirmaExclusao(record, indice)} icon={<DeleteOutlined />} />
                                        </Col>
                                    </Row>
                                </div>
                            ),
                        },
                    ]
                } locale={{
                    emptyText: (
                        <Row>
                            <Col span={24}>
                                <Divider orientation="center">
                                    <ExclamationCircleOutlined /> Ainda não possui Endereço Cadastrado
                                </Divider>
                            </Col>
                        </Row>
                    )
                }} dataSource={listaNacionalidade} scroll={{ x: 850 }} />
            </div>
            <ModalNacionalidade form={form} dadosNacionalidade={{ listaNacionalidade, setListaNacionalidade }} exibirModalNacionalidade={openModalNacionalidade} fecharModalNacionalidade={() => setOpenModalNacionalidade(false)} />
        </div>

    );

}