import React, { useEffect, useState } from "react";
import { Row, Col, Form, Checkbox, DatePicker, Input, Select } from "antd";
import InputNumber from "rc-input-number";

import { InputPreco } from "../../components";
import SelectPaginacao from "../selects/selectPaginacao";

export default function FormularioDinamico({ formulario, parametrosFiltros, retornarLinhas = true }) {
    const [elementosDinamicos, setElementosDinamicos] = useState([]);

    const retornaElementos = (elementos) => {
        var elementoConvert = JSON.stringify(elementos).replaceAll('cap_', 'rpj_');
        setElementosDinamicos(JSON.parse(elementoConvert));
        console.log({ elementoConvert });
    }

    useEffect(() => { if (!!parametrosFiltros) retornaElementos(parametrosFiltros); }, [parametrosFiltros]);
    useEffect(() => { if (!!elementosDinamicos && elementosDinamicos.length > 0) camposDinamicos(); }, [elementosDinamicos]);

    const camposDinamicos = () => {
        let linhas = []
        if (elementosDinamicos) {
            elementosDinamicos.forEach((reg, idx, array) => {
                if (linhas.filter((lin) => (lin.linha === reg.rpj_linha)).length > 0) {
                    let dados = linhas.find((lin) => (lin.linha === reg.rpj_linha));
                    dados.dados.push(reg);
                } else {
                    linhas.push({ linha: reg.rpj_linha, dados: [reg] });
                }
            });
        }
        return linhas.map((linhasFiltro) => {
            if (retornarLinhas === true) {
                return (
                    <Row gutter={[8, 8]}>
                        {componente(linhasFiltro.dados)}
                    </Row>
                );

            } else {
                return (
                    componente(linhasFiltro.dados)
                );
            }
        });
    }

    const componente = (linhasFiltro) => {
        return linhasFiltro.map((itemFiltro) => {

            switch (itemFiltro.rpj_tipoelemento) {
                case "input":
                    return input(itemFiltro);
                case "inputNumber":
                    return inputNumber(itemFiltro);
                case "inputPreco":
                    return inputPreco(itemFiltro);
                case "dataPicker":
                    return dataPicker(itemFiltro);
                case "select":
                    return select(itemFiltro);
                case "selectPaginacao":
                    return selectPaginacao(itemFiltro);
                case "checkbox":
                    return checkbox(itemFiltro);
                //formulario.setFieldsValue({[itemFiltro.rpj_nomeelemento]:JSON.parse(itemFiltro.rpj_options).defaultChecked})
                default: return ''
            }

        });
    }

    const input = (itemFiltro) => {
        return (
            <Col span={itemFiltro.rpj_coluna}>
                <Form.Item label={itemFiltro.rpj_label} name={itemFiltro.rpj_nomeelemento} rules={[{ required: itemFiltro.rpj_obrigatorio, message: itemFiltro.rpj_mensagemObrigatorio }]} >
                    <Input placeholder={itemFiltro.rpj_placeholder} />
                </Form.Item>
            </Col>
        )
    }

    const dataPicker = (itemFiltro) => {
        return (
            <Col span={itemFiltro.rpj_coluna}>
                <Form.Item label={itemFiltro.rpj_label} name={itemFiltro.rpj_nomeelemento} rules={[{ required: itemFiltro.rpj_obrigatorio, message: itemFiltro.rpj_mensagemObrigatorio }]}>
                    <DatePicker format={JSON.parse(itemFiltro.rpj_options).format} placeholder={itemFiltro.rpj_placeholder} />
                </Form.Item>
            </Col>
        )
    }

    const select = (itemFiltro) => {
        return (
            <Col span={itemFiltro.rpj_coluna}>
                <Form.Item label={itemFiltro.rpj_label} name={itemFiltro.rpj_nomeelemento} rules={[{ required: itemFiltro.rpj_obrigatorio, message: itemFiltro.rpj_mensagemObrigatorio }]}>
                    <Select
                        placeholder={itemFiltro.rpj_placeholder}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        //multiple={JSON.parse(itemFiltro.rpj_options).multiple}
                        mode={JSON.parse(itemFiltro.rpj_options).multiple ? "multiple" : ""}
                    >
                        {JSON.parse(itemFiltro.rpj_options).options.map((select, idx, array) => (
                            <Select.Option value={select.search("-") ? (select.split("-")[0]).replace(" ", "") : select} key={idx} label={select}>{select}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>
        )
    }

    const selectPaginacao = (itemFiltro) => {
        return (
            <Col span={itemFiltro.rpj_coluna}>
                <Form.Item label={itemFiltro.rpj_label} name={itemFiltro.rpj_nomeelemento} rules={[{ required: itemFiltro.rpj_obrigatorio, message: itemFiltro.rpj_mensagemObrigatorio }]}>
                    <SelectPaginacao
                        placeholder={itemFiltro.rpj_placeholder}
                        idValue={itemFiltro.rpj_nomeelemento}
                        allowClear={true}
                        //onChangeFunction={buscarSubGrupos}
                        url={JSON.parse(itemFiltro.rpj_options).url}//'Usuario/Listar'
                        nameLabel={JSON.parse(itemFiltro.rpj_options).nameLabel}//'usu_nome'
                        nameValue={JSON.parse(itemFiltro.rpj_options).nameValue}//'usu_id'
                        multiple={JSON.parse(itemFiltro.rpj_options).multiple}
                        //defaultValue={!!formulario.getFieldValue("gru_id") ? formulario.getFieldValue("gru_id") + " - " + formulario.getFieldValue("gru_nome") : ""}
                        mostrarCodigoLabel={JSON.parse(itemFiltro.rpj_options).mostrarCodigoLabel}
                        form={formulario}
                    //funcaoIncluir={adicionarSubgrupo}
                    />
                </Form.Item>
            </Col>
        )
    }

    const inputNumber = (itemFiltro) => {
        return (
            <Col span={itemFiltro.rpj_coluna}>
                <Form.Item label={itemFiltro.rpj_label} name={itemFiltro.rpj_nomeelemento} rules={[{ required: itemFiltro.rpj_obrigatorio, message: itemFiltro.rpj_mensagemObrigatorio }]}>
                    <InputNumber placeholder={itemFiltro.rpj_placeholder} step={1} min={0} />
                </Form.Item>
            </Col>
        )
    }

    const inputPreco = (itemFiltro) => {
        return (
            <Col span={itemFiltro.rpj_coluna}>
                <InputPreco label={itemFiltro.rpj_label} name={itemFiltro.rpj_nomeelemento} placeholder={itemFiltro.rpj_placeholder} rules={[{ required: itemFiltro.rpj_obrigatorio, message: itemFiltro.rpj_mensagemObrigatorio }]} />
            </Col>
        )
    }

    const checkbox = (itemFiltro) => {
        return (
            <Col span={itemFiltro.rpj_coluna} className="m-t-21">
                <Form.Item name={itemFiltro.rpj_nomeelemento} valuePropName="checked" initialValue={!!JSON.parse(itemFiltro.rpj_options).defaultChecked ? true : false}>
                    <Checkbox> {itemFiltro.rpj_label} </Checkbox>
                </Form.Item>
            </Col>
        )
    }

    return (
        camposDinamicos()
    );

}