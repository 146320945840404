import React, { useEffect, useState } from "react";
import { Button, Col, Form, Image, Input, Radio, Row, Typography, Modal } from "antd";
import { ArrowLeftOutlined, ExclamationCircleOutlined, CheckOutlined, CloseOutlined} from "@ant-design/icons";

import api from "../../../services/api";
import { useStateValue } from '../../../state';
import { info } from "../../../services/funcoes";

export default function ModalBandeiraCartao({ exibirModal, fecharModal, retornoModal, fecharModalOK = fecharModal }) {

    const [formDados] = Form.useForm();
    const [{ manutencao }, dispatch] = useStateValue();
    const [listaBandeiras, setListaBandeiras] = useState([]);
    const [bandeiraSelecionada, setBandeiraSelecionada] = useState([]);

    useEffect(() => {
        if (exibirModal) {
            api.get(`BandeiraCartaoEmpresa/ListarAtivos`).then(
                res => {
                    setListaBandeiras(res.data);
                }
            ).catch(error => {
                console.log(error);
            })
        }
    }, [exibirModal])

    const onCloseModal = () => {
        formDados.resetFields();
        setBandeiraSelecionada([]);
        fecharModal();
    };


    const modalCancelar = () => {
        Modal.confirm({
            title: 'Aviso',
            icon: <ExclamationCircleOutlined />,
            content: `Deseja realmente cancelar o lançamento de Cartão?`,
            okText: 'Sim',
            cancelText: 'Não',
            onOk() {
                onCloseModal()
            }
        });
    };

    const salvarDados = values => {
        if (bandeiraSelecionada.length > 0) {
            values.bandeira = bandeiraSelecionada[0];
            retornoModal(manutencao.dados, values);
            fecharModalOK();
            setBandeiraSelecionada([]);
        } else {
            info('Selecione uma bandeira!');
        }
    }

    function abrirTipoParcelamento(e) {
        if (!!e.target.value) {
            setBandeiraSelecionada(listaBandeiras.filter((bandeira) => { return bandeira.bnd_id === parseInt(e.target.value) }));
        }
    };

    function fecharTipoParcelamento() {
        setBandeiraSelecionada([]);
        formDados.resetFields();
    };

    return (
        <Modal centered
            width={700}
            title="Selecione a Bandeira do Cartão"
            open={exibirModal}
            getContainer={false}
            onCancel={modalCancelar}
            maskClosable={false}
            destroyOnClose
            footer={[
                <Button size="middle" icon={<CloseOutlined />} onClick={modalCancelar}>
                    Cancelar
                </Button>,
                <Button size="middle" type="primary" icon={<CheckOutlined />} onClick={() => { formDados.submit() }}>
                    OK
                </Button>
            ]}
        >
            <Form layout="vertical" name="formBandeira" form={formDados} onFinish={salvarDados}>
                {bandeiraSelecionada.length === 0 &&
                    <div>
                        <Row>
                            <Col span={24}>
                                <Form.Item name="bnd_id">
                                    <Radio.Group buttonStyle="solid" className="m-t-16">
                                        {listaBandeiras.map((item) => {
                                            return (
                                                <Radio.Button className="radioImage"
                                                    onClick={(e) => abrirTipoParcelamento(e)}
                                                    value={item.bnd_id}
                                                >
                                                    <div>
                                                        <Image
                                                            preview={false}
                                                            src={`data:image/png;base64,${item.bnd_logo}`}
                                                        />
                                                    </div>
                                                    <span>{item.bnd_nome.toUpperCase()}</span>
                                                </Radio.Button>
                                            )
                                        })}
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                }
                {bandeiraSelecionada.length > 0 &&
                    <div>
                        <Row justify="space-between">
                            <Col>
                                <Typography.Title level={5}>Bandeira Selecionada:</Typography.Title>
                            </Col>
                            <Col>
                                <Button onClick={() => fecharTipoParcelamento()} icon={<ArrowLeftOutlined />} size="middle" >
                                    Voltar
                                </Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <div className="bandeiras">
                                    <div>
                                        <Image
                                            preview={false}
                                            src={`data:image/png;base64,${bandeiraSelecionada[0].bnd_logo}`}
                                        />
                                    </div>
                                    <span>
                                        {bandeiraSelecionada[0].bnd_nome.toUpperCase()}
                                    </span>
                                </div>
                            </Col>
                        </Row>
                        <div>
                            {!!manutencao.dados && manutencao.dados.credito &&
                                <div>
                                    <Row justify="center">
                                        <Col>
                                            <Typography.Title level={5}>Tipo de Parcelamento:</Typography.Title>
                                        </Col>
                                    </Row>
                                    <Row justify="center">
                                        <Col>
                                            <Form.Item name="tipoParcelamento" initialValue={0}>
                                                <Radio.Group defaultValue={0} >
                                                    <Radio value={0}>Por Parte do Estabelecimento</Radio>
                                                    <Radio value={1}>Por Parte do Comprador</Radio>
                                                </Radio.Group>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </div>
                            }
                            <Row justify="center" className="m-t-8">
                                <Col>
                                    <Form.Item label="NSU: (Opcional)" name="nsu">
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                    </div>
                }
            </Form>
        </Modal>
    );
}