import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Input, Select, Collapse } from "antd";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

import api from "../../services/api";
import { InputCep, InputPhone, SelectPaginacao } from "../../components";

export default function ExibirMaisInformacoesCliente({ form, pesFisJur, clienteCpfCnpj, open, setOpen }) {

    const [cidade, setCidade] = useState({});
    const inputRef = React.createRef();

    const togglePesquisa = () => {
        setOpen(!open);
    };

    const verificaCep = (cep) => {
        if (!!cep) {
            api.get(`Cidade/Listar?Cep=${cep.match(/\d+/g).join('')}`).then(
                (res) => {
                    if (res.status === 200) {
                        form.setFieldsValue({ cid_descricao: res.data.items.length > 0 ? res.data.items[0].cid_descricao : '' })
                        setCidade(res.data.items[0]);
                        form.setFieldsValue({ est_sigla: res.data.items.length > 0 ? res.data.items[0].est_sigla : '' });
                    } else {
                        api.get(`Cidade/Listar?Cep=${cep.match(/\d+/g).join('')}`).then(
                            (res) => {
                                form.setFieldsValue({ cid_descricao: res.data.items.cidade })
                                setCidade(res.data[0]);
                                form.setFieldsValue({ est_sigla: res.data.items.estado });
                            })
                    }
                }
            );
        }
    }

    useEffect(() => {
        if (!!clienteCpfCnpj) {
            form.setFieldsValue({ cpfCnpj: clienteCpfCnpj });
            carregarDadosCliente();
        }
    }, [clienteCpfCnpj])

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                if (open && !!inputRef.current) {
                    inputRef.current.focus({ cursor: 'end', })
                };
            }, 500);
        }
    }, [open])


    function carregarDadosCliente() {
        api.get(`Cliente/BuscarClienteCpfCnpj/${clienteCpfCnpj.replaceAll('.', '').replaceAll('-', '').replaceAll('/', '')}`).then(
            res => {
                let telefones = res.data.telefones.filter((item) => (item.psc_principal === true))[0];
                let email = res.data.emails.filter((item) => (item.pem_emailprincipal === true))[0];
                let enderecos = res.data.enderecos.filter((item) => (item.pee_enderecoprincipal === true))[0];
                let dados = { ...telefones, ...email, ...enderecos, ...res.data };
                form.setFieldsValue(dados);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }

    return (
        <div className="page devolution">
            <Row gutter={[0, 8]}>
                <Col span={24}>
                    <Collapse className="p-relative" accordion ghost destroyInactivePanel={true}>
                        <div className="colDet"></div>
                        <Collapse.Panel key="1" showArrow={false} extra={
                            <Button type="primary" onClick={togglePesquisa}>
                                {open ? <ArrowUpOutlined /> : <ArrowDownOutlined />} Para exibir mais dados sobre o cliente, Clique Aqui.
                            </Button>
                        }>
                            <Row gutter={[8, 8]}>
                                <Col span={24}>
                                    <Row gutter={[8, 0]}>
                                        <Col xs={24} sm={13} md={10} lg={10} xl={8} xxl={8}>
                                            <Form.Item label="Cliente" name="pes_nome" rules={[{ required: true, message: 'Informe o Cliente' }]}>
                                                <Input placeholder="Informe o nome" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={6} md={4} lg={4} xl={3} xxl={3}>
                                            <InputCep label="CEP" name="cep_cep" rules={[{ required: true, message: 'Informe o CEP' }]} placeholder='Informe o CEP' onBlur={(cep) => verificaCep(cep.target.value)} onPressEnter={(cep) => verificaCep(cep.target.value)} />
                                        </Col>
                                        <Col xs={24} sm={7} md={6} lg={6} xl={3} xxl={3}>
                                            <Form.Item label="Cidade" name="cid_descricao" rules={[{ required: true }]}>
                                                <Input disabled={true} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={18} sm={12} md={10} lg={10} xl={6} xxl={6}>
                                            <Form.Item label="Endereço" name="log_logradouro" rules={[{ required: true, message: 'Insira o Endereço' }]}>
                                                <Input placeholder="Informe o Endereço" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={6} sm={5} md={4} lg={4} xl={2} xxl={2}>
                                            <Form.Item label="Número" name="pee_numero">
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} sm={8} md={5} lg={5} xl={3} xxl={3}>
                                            <Form.Item label="Bairro" name="bai_nome" rules={[{ required: true, message: 'Insira o Bairro' }]}>
                                                <Input placeholder="Informe o Bairro" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={8} lg={8} xl={6} xxl={6}>
                                            <Form.Item label="Complemento" name="pee_complemento">
                                                <Input placeholder="Informe o Complemento" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={6} md={4} lg={4} xl={3} xxl={3}>
                                            <Form.Item label="Tipo de Endereço" name="tpe_id" rules={[{ required: true, message: 'Tipo de Endereço' }]}>
                                                <SelectPaginacao url="TipoEndereco/Listar" placeholder="Informe o Tipo de Endereço" form={form} nameValue="tpe_id" nameLabel={"tpe_descricao"} conteudo={
                                                    te => (<Select.Option value={te.tpe_id} key={te.tpe_id} label={te.tpe_descricao}>{te.tpe_descricao}</Select.Option>)
                                                } />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={12} sm={8} md={5} lg={5} xl={3} xxl={3}>
                                            <InputPhone label="Telefone" name="psc_numero" rules={[{ required: true, message: 'Informar o Número do Telefone' }]} />
                                        </Col>
                                        <Col xs={24} sm={24} md={8} lg={8} xl={6} xxl={6}>
                                            <Form.Item label="E-mail" name="pem_email">
                                                <Input placeholder="Informe o e-mail" />
                                            </Form.Item>
                                        </Col>
                                        <Col>
                                            <Form.Item hidden={true} name="est_sigla" />
                                            <Form.Item hidden={true} name="cpfCnpj" />
                                            <Form.Item hidden={true} name="cli_id" />
                                            <Form.Item hidden={true} name="cli_ativo" />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Collapse.Panel>
                    </Collapse>
                </Col>
            </Row>
        </div>
    );
}