import React, { useEffect } from "react";
import { Row, Col, Form, Modal } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { InputPreco } from "../../../components";

export default function ModalEditarParcela({ exibirModal, fecharModal, dadosLinha, salvarDadosModal }) {

    const [formDados] = Form.useForm();

    useEffect(() => {
        if (!!dadosLinha) {
            formDados.setFieldsValue(dadosLinha);
        }
    }, [dadosLinha, []]);

    const onCloseModal = () => {
        formDados.resetFields();
        fecharModal();
    };

    const salvarDados = values => {
        salvarDadosModal(values);
        fecharModal();
    }

    function calcularValorReceber() {
        let valorTotal = parseFloat(!!dadosLinha.valorOriginal ? dadosLinha.valorOriginal : 0) - parseFloat(!!dadosLinha.valorQuitado ? dadosLinha.valorQuitado : 0);
        if (parseFloat(formDados.getFieldValue("valorDesconto")) > (valorTotal + parseFloat(formDados.getFieldValue("valorJuros")))) {
            formDados.setFieldsValue({ valorDesconto: valorTotal + parseFloat(formDados.getFieldValue("valorJuros")) });
        }
        let valorReceber = (valorTotal - parseFloat(formDados.getFieldValue("valorDesconto")) + parseFloat(formDados.getFieldValue("valorJuros"))).toFixed(2);
        formDados.setFieldsValue({ valorPago: valorReceber });
    };

    function verificarValorReceber() {
        let valorTotal = parseFloat((!!dadosLinha.valorOriginal ? dadosLinha.valorOriginal : 0)) - parseFloat((!!dadosLinha.valorQuitado ? dadosLinha.valorQuitado : 0));
        let valorReceber = (valorTotal - parseFloat(formDados.getFieldValue("valorDesconto")) + parseFloat(formDados.getFieldValue("valorJuros"))).toFixed(2);

        if (parseFloat(formDados.getFieldValue("valorPago")) > valorReceber) {
            formDados.setFieldsValue({ valorPago: valorReceber });
        }
    };

    return (
        <Modal centered
            title="Editar Parcela"
            open={exibirModal}
            getContainer={false}
            onCancel={onCloseModal}
            onOk={() => { formDados.submit() }}
            okText={
                <>
                    <CheckOutlined /> Ok
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <Form layout="vertical" name="formParcela" form={formDados} onFinish={salvarDados}>
                <Row gutter={[8, 16]}>
                    <Col span={8}>
                        <InputPreco onBlur={() => calcularValorReceber()} name={'valorJuros'} label={'Juros'} />
                    </Col>
                    <Col span={8}>
                        <InputPreco onBlur={() => calcularValorReceber()} name={'valorDesconto'} label={'Descontos'} />
                    </Col>
                    <Col span={8}>
                        <InputPreco onBlur={(valor) => verificarValorReceber()} name={'valorPago'} label={'Valor à Receber'} />
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}