import React, { useEffect, useState } from "react";
import { Row, Col, Form, Modal, Typography, Descriptions, notification } from "antd";
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../../services/api";
import { useStateValue } from "../../../state";
import { dadosPdvActions, manutencaoActions } from "../../../actions";
import { Cedulas, InputPreco } from "../../../components";
import { getIdUsuario, getLocal, getNomeUsuario } from "../../../services/auth";
import { carregarDadosPdv } from "../../../services/funcoes";

export default function ModalAberturaCaixa({ setCaixaAberto, exibirModalAberturaCaixa, fecharModalAberturaCaixa }) {

    const [form] = Form.useForm();
    const [{ manutencao, dadosPdv }, dispatch] = useStateValue();
    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalAberturaCaixa();
            }
        });
    };

    useEffect(() => {
        carregarPdv();
    }, [])

    useEffect(() => {
        if (exibirModalAberturaCaixa && !!dadosPdv?.pdv?.pdv_id) {
            buscarSaldoAnterior(dadosPdv?.pdv);
        }
    }, [exibirModalAberturaCaixa])

    async function buscarSaldoAnterior(pdv) {
        api.get(`ContaCorrente/RetornaSaldoPdv?idContaCorrente=${pdv.ctc_id}`).then(
            res => {
                form.setFieldsValue({ ctx_valorabertura: !!res.data.saldo ? res.data.saldo : 0 });
            }
        ).catch(
            error => { console.log(error) }
        )
    }

    async function carregarPdv() {
        let res = await carregarDadosPdv();
        dispatch({ type: dadosPdvActions.CHANGE, data: { pdv: res } });
    }

    async function abrirCaixa() {
        let dados = {};
        let values = {};
        values.dadosPdv = dadosPdv.pdv;
        values.pdv_id = values.dadosPdv?.pdv_id;
        values.usu_id = getIdUsuario();
        values.ctx_datahoraabertura = new Date();
        values.ctx_valorabertura = form.getFieldValue().ctx_valorabertura;
        dados = { ...values.dadosPdv, ...values };
        if (parseFloat(values.ctx_valorabertura) === 0) {
            Modal.confirm({
                title: 'Aviso!',
                icon: <ExclamationCircleOutlined />,
                content: 'Abrir este caixa com o valor R$ 0,00?',
                okText: 'Sim, quero assim mesmo',
                cancelText: 'Não',
                onOk() {
                    api.post(`ControleCaixa/RealizarAbertura`, values).then(
                        res => {
                            notification.success({ message: 'Sucesso!', description: 'Caixa Aberto com Sucesso!' });
                        }
                    ).catch(
                        error => {
                            console.log(error);
                        }
                    ).finally(
                        () => {
                            window.caixaAberto = true;
                            setCaixaAberto(true);
                            fecharModalAberturaCaixa();
                        }
                    )
                },
                onCancel() {
                    window.caixaAberto = false;
                    return false;
                }
            })
        } else {
            api.post(`ControleCaixa/RealizarAbertura`, values).then(
                res => {
                    notification.success({ message: 'Sucesso!', description: 'Caixa Aberto com Sucesso!' });
                }
            ).catch(
                error => {
                    console.log(error);
                }
            ).finally(
                () => {
                    window.caixaAberto = true;
                    setCaixaAberto(true);
                    fecharModalAberturaCaixa();
                }
            )
        }

    }

    return (
        <Modal centered
            title="Abertura do Caixa"
            open={exibirModalAberturaCaixa}
            getContainer={false}
            onCancel={onCloseModal}
            onOk={() => abrirCaixa()}
            okText={
                <>
                    <CheckOutlined /> Ok
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            width={690}
        >
            <div className="col-modal">
                <Form layout="vertical" name="manutencaoAberturaCaixa" form={form}>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <Typography.Text>
                                Informe os dados para Abertura do Caixa.
                            </Typography.Text>
                        </Col>
                    </Row>
                    <Row gutter={[8, 0]} className="m-t-16">
                        <Col span={24}>
                            <Descriptions size="small">
                                <Descriptions.Item label="Operador">
                                    <b> {getNomeUsuario()} </b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={24}>
                            <Descriptions size="small" column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 1, xs: 1 }}>
                                <Descriptions.Item label="Data Abertura">
                                    <b> {moment(new Date()).format('DD/MM/YYYY')} </b>
                                </Descriptions.Item>
                                <Descriptions.Item label="Hora Abertura">
                                    <b> {moment(new Date()).format('HH:mm')} </b>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <Row gutter={[8, 0]} className="m-t-16">
                        <Col span={24}>
                            <InputPreco name="ctx_valorabertura" label="Valor para Abertura" />
                        </Col>
                    </Row>
                    <Cedulas />
                </Form>
            </div>
        </Modal>
    );
}