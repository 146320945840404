import React, { useState, useEffect } from "react";
import { Row, Col, Table, Tag } from "antd";
import moment from 'moment';

import api from "../../../services/api";
import { FormatNumber } from '../../../ValueObjects';
import { isObjetoDiffVazio } from "../../../services/funcoes";

export default function CupomCancelados({ listaCancelados }) {

    return (
        <div className="tabela">
            <Table
                columns={[
                    {
                        title: 'Cupons Cancelados',
                        render: ({ ntf_numero, formaPgto, ntf_dataemissao }) => (
                            <div>
                                <Row align="middle" gutter={[8, 5]}>
                                    <Col>
                                        <Tag color="error">
                                            <b>Cancelado</b>
                                        </Tag>
                                    </Col>
                                    <Col>
                                        <b>Venda</b>
                                    </Col>
                                    <Col>
                                        Nº<b>{ntf_numero}</b>
                                    </Col>
                                </Row>
                                <Row align="middle" gutter={[8, 5]}>
                                    <Col>
                                        Forma de Pagamento:
                                    </Col>
                                </Row>
                                {formaPgto.map((item) => (
                                    <Row align="middle" gutter={[8, 5]}>
                                        <Col>
                                            <b>{item.fpg_descricao} {item.cpg_descricao}</b>
                                        </Col>
                                        <Col>
                                            Valor: <b>{FormatNumber(item.nfp_valor, true)}</b>
                                        </Col>
                                    </Row>
                                ))}
                                <Row>
                                    <Col span={24}>
                                        <b>{moment(ntf_dataemissao).format("DD/MM/YYYY HH:mm:ss")}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        align: 'right',
                        width: 130,
                        render: ({ ntf_valortotal }) => (
                            <div>
                                <Row gutter={[8, 0]} justify="end">
                                    <Col>
                                        <b>{FormatNumber(ntf_valortotal, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} dataSource={listaCancelados} scroll={{ y: 267 }} pagination={false}
            />
        </div>
    );
}