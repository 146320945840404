import React, { useState, useEffect } from "react";
import { Row, Col, Form, Typography, Card, Button, InputNumber, notification } from "antd";
import { useStateValue } from "../../state";
import { FormGW, InputPreco } from "../../components";
import { isObjetoDiffVazio } from "../../services/funcoes";

export default function ResumoVenda({ totais, setTotais, formulario, permiteEditarDescontoAcrescimo = true, frete }) {

    const [{ manutencao }] = useStateValue();
    const [descontoItem, setDescontoItem] = useState(false);
    const [acrescimoItem, setAcrescimoItem] = useState(false);
    const [permiteEdicaoDescontoAcrescimo, setPermiteEdicaoDescontoAcrescimo] = useState(false);
    useEffect(() => {
        setPermiteEdicaoDescontoAcrescimo(permiteEditarDescontoAcrescimo);
    }, [permiteEditarDescontoAcrescimo]);

    useEffect(() => {
        if (isObjetoDiffVazio(totais)) {
            formulario.setFieldsValue({ valorTotalSub: parseFloat(totais.valorTotalSub).toFixed(2), valorTotal: parseFloat(totais.valorTotal).toFixed(2), totalDescItens: parseFloat(totais.totalDescItens) });
            if (!!totais.percentualAcrescimo) {
                setAcrescimoItem(true);
            }
        }
    }, [totais]);

    useEffect(() => {
        if (frete) {
            formulario.setFieldsValue({ valorFrete: parseFloat(frete) });
            calculaTotalItemDescAcr();
        }
    }, [frete])

    function alterarValorTotal() {
        calculaDesconto('ATT');
        calculaAcrescimo('ATT');
    }

    function calculaDesconto(campo) {
        let percentualVendedor = !!manutencao.dados && !!manutencao.dados.vendedor && !!manutencao.dados.vendedor.ven_maximodesconto ? manutencao.dados.vendedor.ven_maximodesconto : null;
        let totalDesconto = !!manutencao.dados && !!manutencao.dados.totais && !!manutencao.dados.totais.valorTotalDesconto ? parseFloat(manutencao.dados.totais.valorTotalDesconto) : 0;
        let valorTotal = !!manutencao.dados && !!manutencao.dados.totais && !!manutencao.dados.totais.valorTotalSub ? parseFloat(manutencao.dados.totais.valorTotalSub) : 0;
        let valor = !!formulario.getFieldValue().valorTotalDesconto ? parseFloat(formulario.getFieldValue().valorTotalDesconto) : 0;
        let perc = !!formulario.getFieldValue().percentualDesconto ? formulario.getFieldValue().percentualDesconto : 0;
        let tot = !!formulario.getFieldValue().valorTotalSub ? parseFloat(formulario.getFieldValue().valorTotalSub) - (!!formulario.getFieldValue().totalDescItens ? parseFloat(formulario.getFieldValue().totalDescItens) : 0) : 0;
        let result = 0;
        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                if (percentualVendedor != null) {
                    if (((result + totalDesconto) * 100) / valorTotal > percentualVendedor) {
                        notification.warning({ message: 'Aviso!', description: 'Desconto maior do que o permitido ao vendedor!' });
                        formulario.setFieldsValue({ percentualDesconto: 0 });
                        formulario.setFieldsValue({ valorTotalDesconto: 0 });
                        formulario.setFieldsValue({ valorTotal: valorTotal - totalDesconto });
                        return false;
                    }
                    formulario.setFieldsValue({ valorTotalDesconto: result.toFixed(2) });
                } else {
                    formulario.setFieldsValue({ valorTotalDesconto: result.toFixed(2) });
                }
            } else if (campo === 'ATT') {
                result = (tot * perc) / 100;
                formulario.setFieldsValue({ valorTotalDesconto: result.toFixed(2) });
                valor = (!!formulario.getFieldValue().valorTotalDesconto ? formulario.getFieldValue().valorTotalDesconto : 0)
                result = (!!tot > 0 ? (valor * 100) / tot : 0);
                formulario.setFieldsValue({ percentualDesconto: result.toFixed(2) });
            } else {
                if (valor > tot) {
                    notification.warning({ message: 'Aviso!', description: 'Valor de desconto maior do que valor dos itens!' });
                    formulario.setFieldsValue({ valorTotalDesconto: tot });
                }
                result = (valor * 100) / tot;
                if (percentualVendedor != null) {
                    if (((valor + totalDesconto) * 100) / valorTotal > percentualVendedor) {
                        notification.warning({ message: 'Aviso!', description: 'Desconto maior do que o permitido ao vendedor!' });
                        formulario.setFieldsValue({ percentualDesconto: 0 });
                        formulario.setFieldsValue({ valorTotalDesconto: 0 });
                        formulario.setFieldsValue({ valorTotal: valorTotal - totalDesconto });
                        return false;
                    }
                    formulario.setFieldsValue({ percentualDesconto: result.toFixed(2) });
                } else {
                    formulario.setFieldsValue({ percentualDesconto: result.toFixed(2) });
                }
            }
            calculaTotalItemDescAcr();
        } else {
            formulario.setFieldsValue({ valorTotalDesconto: 0 });
            formulario.setFieldsValue({ percentualDesconto: 0 });
            calculaTotalItemDescAcr();
        }
    }

    function calculaAcrescimo(campo) {
        let valor = (!!formulario.getFieldValue().valorTotalAcrescimo ? parseFloat(formulario.getFieldValue().valorTotalAcrescimo) : 0);
        let perc = (!!formulario.getFieldValue().percentualAcrescimo ? formulario.getFieldValue().percentualAcrescimo : 0);
        let tot = (!!formulario.getFieldValue().valorTotalSub ? parseFloat(formulario.getFieldValue().valorTotalSub) : 0);
        let result = 0;
        if (!!campo) {
            if (campo === 'PERC') {
                result = (tot * perc) / 100;
                formulario.setFieldsValue({ valorTotalAcrescimo: result.toFixed(2) });
            } else if (campo === 'ATT') {
                result = (tot * perc) / 100;
                formulario.setFieldsValue({ valorTotalAcrescimo: result.toFixed(2) });
                valor = !!formulario.getFieldValue().valorTotalAcrescimo ? formulario.getFieldValue().valorTotalAcrescimo : 0;
                result = (tot > 0 ? (valor * 100) / tot : 0);
                formulario.setFieldsValue({ percentualAcrescimo: result.toFixed(2) });
            } else {
                result = (valor * 100) / tot;
                formulario.setFieldsValue({ percentualAcrescimo: result.toFixed(2) });
            }
            calculaTotalItemDescAcr();
        }
    }

    function calculaTotalItemDescAcr() {
        let valoresTemp = { ...totais };
        valoresTemp.valorTotalSub = !!formulario.getFieldValue().valorTotalSub ? formulario.getFieldValue().valorTotalSub : 0;
        valoresTemp.valorTotalAcrescimo = !!formulario.getFieldValue().valorTotalAcrescimo ? parseFloat(formulario.getFieldValue().valorTotalAcrescimo) : 0;
        valoresTemp.valorTotalDesconto =  !!formulario.getFieldValue().valorTotalDesconto ? parseFloat(formulario.getFieldValue().valorTotalDesconto) : 0;
        valoresTemp.valorTotal = parseFloat(((parseFloat(valoresTemp.valorTotalSub) + parseFloat(valoresTemp.valorTotalAcrescimo) + parseFloat(valoresTemp.totalAcresItens) - parseFloat(valoresTemp.valorTotalDesconto) - parseFloat(valoresTemp.totalDescItens)) + parseFloat(formulario.getFieldValue().valorFrete)).toFixed(2));
        formulario.setFieldsValue({ valorTotal: valoresTemp.valorTotal.toFixed(2), pfp_valor: valoresTemp.valorTotal.toFixed(2) });
        setTotais(valoresTemp);
    }

    return (
        <Card style={{ width: '500px' }} title={
            <Typography.Title level={4} className="m-b-0">
                <img src={require("../../assets/i-bill.png")} alt="Resumo da Venda" /> RESUMO DA VENDA
            </Typography.Title>
        }>
            <FormGW layout="vertical" form={formulario} name="resumoVenda" >
                <Row align="middle" gutter={[8, 0]}>
                    <Col span={24} className="text-right m-t-10">
                        <Row align="middle" justify="end" gutter={[8, 0]}>
                            <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={14}>
                                Subtotal (R$):
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={10}>
                                <InputPreco name="valorTotalSub" disabled />
                            </Col>
                        </Row>
                        <Row align="middle" justify="end" gutter={[8, 0]}>
                            <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={14}>
                                Frete (R$):
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={10}>
                                <InputPreco name="valorFrete" onBlur={() => calculaTotalItemDescAcr()}/>
                            </Col>
                        </Row>
                        <Row align="middle" justify="end" gutter={[8, 0]}>
                            <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={10}>
                                <Form.Item>
                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                        <Col>
                                            Desconto em:
                                        </Col>
                                        <Col>
                                            <Button className={!descontoItem ? 'ant-btn-primary' : ''} onClick={() => setDescontoItem(false)}>
                                                R$
                                            </Button>
                                            <Button className={descontoItem ? 'ant-btn-primary' : ''} onClick={() => setDescontoItem(true)}>
                                                %
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            {!descontoItem &&
                                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={10}>
                                    <InputPreco name="valorTotalDesconto" disabled={!!!manutencao.dados || (!!manutencao.dados && !isObjetoDiffVazio(manutencao.dados.vendedor) || permiteEdicaoDescontoAcrescimo === false)} onBlur={() => calculaDesconto('VALOR')} />
                                </Col>
                            }
                            {descontoItem &&
                                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={10}>
                                    <Form.Item name="percentualDesconto">
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            controls={false}
                                            defaultValue={0}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            disabled={!isObjetoDiffVazio(manutencao.dados.vendedor) || permiteEdicaoDescontoAcrescimo === false}
                                            onBlur={() => calculaDesconto('PERC')} />
                                    </Form.Item>
                                </Col>
                            }
                        </Row>
                        <Row align="middle" justify="end" gutter={[8, 0]}>
                            <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={10}>
                                <Form.Item>
                                    <Row align="middle" justify="end" gutter={[8, 0]}>
                                        <Col>
                                            Acréscimo em:
                                        </Col>
                                        <Col>
                                            <Button className={!acrescimoItem ? 'ant-btn-primary' : ''} onClick={() => setAcrescimoItem(false)}>
                                                R$
                                            </Button>
                                            <Button className={acrescimoItem ? 'ant-btn-primary' : ''} onClick={() => setAcrescimoItem(true)}>
                                                %
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            </Col>
                            {!!acrescimoItem === false &&
                                <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={10}>
                                    <InputPreco name="valorTotalAcrescimo" disabled={permiteEdicaoDescontoAcrescimo === false} onBlur={() => calculaAcrescimo('VALOR')} />
                                </Col>
                            }
                            {!!acrescimoItem === true &&
                                <Col xs={24} sm={8} md={8} lg={8} xl={8} xxl={10}>
                                    <Form.Item name='percentualAcrescimo'>
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            controls={false}
                                            defaultValue={0}
                                            formatter={value => `${value}%`}
                                            parser={value => value.replace('%', '')}
                                            disabled={permiteEdicaoDescontoAcrescimo === false}
                                            onBlur={() => calculaAcrescimo('PERC')} />

                                    </Form.Item>
                                </Col>
                            }
                        </Row>
                        <Row align="middle" justify="end" gutter={[8, 8]}>
                            <Col xs={16} sm={16} md={16} lg={16} xl={16} xxl={10}>
                                Total (R$):
                            </Col>
                            <Col xs={8} sm={8} md={8} lg={8} xl={8} xxl={10}>
                                <InputPreco name="valorTotal" disabled={true} />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </FormGW>
        </Card>
    );
}