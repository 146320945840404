import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, Checkbox, notification, Modal } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { validarCpf } from "../../../services/funcoes";
import InputCpfCnpj from "../../inputCpfCnpj";

export default function ModalPessoaAutorizada({ form, listaPessoasAutorizadas, exibirModalPessoaAutorizada, fecharModalPessoaAutorizada, editando, setEditando }) {

    const [listasGrauParentesco, setListasGrauParentesco] = useState([]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            zIndex: 2010,
            centered: true,
            onOk() {
                setEditando(false);
                form.resetFields();
                fecharModalPessoaAutorizada();
            }
        });
    };

    const onFinish = values => {
        let listaTemp = [...listaPessoasAutorizadas.dadosPessoasAutorizadas];
        let verificaJaCadastrado = listaTemp.filter((pes) => (pes.pea_cpf === form.getFieldsValue().pea_cpf));
        if (verificaJaCadastrado.length > 0 && !editando) {
            notification.info({ message: 'Pessoa Autorizada já Cadastrada!' })
            form.resetFields();
            fecharModalPessoaAutorizada();
            return false;
        } else if (editando) {
            let pessoaEditando = listaTemp.find((pes) => (pes.pea_cpf === form.getFieldsValue().pea_cpf));
            pessoaEditando = form.getFieldsValue();
            pessoaEditando.grauParentesco = listasGrauParentesco.filter((item) => (item.grp_id === pessoaEditando.grp_id))[0];
            listaTemp = listaTemp.filter((item) => (item.pea_cpf !== pessoaEditando.pea_cpf));
            listaTemp.push(pessoaEditando);
        } else {
            values.grauParentesco = listasGrauParentesco.filter((item) => (item.grp_id === form.getFieldsValue().grp_id))[0];
            listaTemp.push(values);
        }
        listaPessoasAutorizadas.setDadosPessoasAutorizadas(listaTemp);
        fecharModalPessoaAutorizada();
        form.resetFields();
    };

    useEffect(() => carregarDados(), []);

    function verificarCpfValido(event) {
        let valorCampo = event.currentTarget.value.replace(/\D/g, '');
        if (!validarCpf(valorCampo)) {
            notification.warning({ message: 'Aviso', description: 'CPF informado é inválido' });
            form.resetFields();
            return false;
        }
    }

    function carregarDados() {
        api.get(`GrauParentesco/Listar`).then(
            res => {
                let key = 0;
                res.data.forEach((item) => {
                    item.key = key++;
                })
                setListasGrauParentesco(res.data);
            }
        ).catch((erro) => console.error(erro))
    };

    return (

        <Modal centered
            title="Cadastro de Pessoas Autorizadas"
            open={exibirModalPessoaAutorizada}
            getContainer={false}
            onCancel={() => {
                onCloseModal();
            }}
            onOk={() => form.submit()}
            okText={
                <>
                    <PlusOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="formPessoaAutorizada" form={form} onFinish={onFinish}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item hidden name="pea_id"></Form.Item>
                            <Form.Item hidden name="cli_id"></Form.Item>
                            <Form.Item hidden name="pef_id"></Form.Item>
                            <Form.Item hidden name="pea_codigodigital"></Form.Item>
                            <InputCpfCnpj cpf label="CPF" name="pea_cpf" rules={[{ required: true, message: 'Informe o CPF da Pessoa' }]} onBlur={(valor) => { verificarCpfValido(valor) }} />
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                            <Form.Item label="Nome" name="pea_nome" rules={[{ required: true, message: 'Informe o Nome da Pessoa' }]}>
                                <Input placeholder="Informe o Nome da Pessoa" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={16} lg={16} xl={16}>
                            <Form.Item label="Grau de Parentesco" name="grp_id" rules={[{ required: true, message: 'Selecione o Grau de Parentesco' }]}>
                                <Select showSearch allowClear optionFilterProp="children" placeholder="Selecione o Grau de Parentesco" >
                                    {listasGrauParentesco.map((grau) => (
                                        <Select.Option label={grau.grp_descricao} value={grau.grp_id} key={grau.key}>
                                            {grau.grp_descricao}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item name="pea_ativo" valuePropName="checked" className="m-t-21">
                                <Checkbox>Pessoa Autorizada?</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}