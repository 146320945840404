import React, { useState } from "react";
import { Row, Col, Card, Typography, Button, Form } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import moment from "moment";

import { Data } from "../../components";
import { VendasPorVendedor, OperacoesFiscais, MaisVendidos, MenosVendidos } from "./pages";

export default function Resumo() {

    const [form] = Form.useForm();
    const [datas, setDatas] = useState({});
    const [valoresTotais, setValoresTotais] = useState(0);
    const [mostraData, setMostraData] = useState(false);

    function carregarData(filtro) {
        let dataInicial = new Date();
        let dataFinal = new Date();
        if (filtro === 'HOJE') {
            dataInicial = moment(dataInicial).format('YYYY-MM-DD');
            dataFinal = moment(dataFinal).format('YYYY-MM-DD');
        } else if (filtro === 'SEMANA') {
            dataInicial = moment(new Date(dataInicial.setDate(dataInicial.getDate() - 7))).format('YYYY-MM-DD');
            dataFinal = moment(new Date()).format('YYYY-MM-DD');
        } else if (filtro === 'MES') {
            dataInicial = moment(new Date(dataInicial.setDate(1))).format('YYYY-MM-DD');
            dataFinal = moment(new Date()).format('YYYY-MM-DD');
        } else if (filtro === 'OUTROFILTRO') {
            dataInicial = moment(form.getFieldValue().dataInicial).format('YYYY-MM-DD');
            dataFinal = moment(form.getFieldValue().dataFinal).format('YYYY-MM-DD');
        }
        setDatas({ dataInicial, dataFinal });
    }

    return (
        <Form layout="vertical" name="resumo" form={form} >
            <div className="col-left-resumo">
                <Row justify="center" align="middle" gutter={[8, 16]}>
                    <Col span={24}>
                        <Row justify="center" gutter={[8, 8]}>
                            <Col>
                                <Button size="large" onClick={() => { setMostraData(false); carregarData('HOJE') }}> HOJE </Button>
                            </Col>
                            <Col>
                                <Button size="large" onClick={() => { setMostraData(false); carregarData('SEMANA') }}> SEMANA </Button>
                            </Col>
                            <Col>
                                <Button size="large" onClick={() => { setMostraData(false); carregarData('MES') }}> MÊS </Button>
                            </Col>
                            <Col>
                                <Button size="large" onClick={() => setMostraData(!mostraData)}> OUTRO PERÍODO </Button>
                            </Col>
                        </Row>
                    </Col>
                    {mostraData &&
                        <Col span={24}>
                            <Row justify="center" gutter={[8, 0]}>
                                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
                                    <Data label="Data Inicial" name="dataInicial" />
                                </Col>
                                <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
                                    <Data label="Até" name="dataFinal" />
                                </Col>
                                <Col xs={24} sm={24} md={6} lg={6} xl={4} xxl={3}>
                                    <Button icon={<CheckOutlined />} onClick={() => carregarData('OUTROFILTRO')} type="primary" className="t-mob" block> Aplicar Filtros </Button>
                                </Col>
                            </Row>
                        </Col>
                    }
                    <Col span={24}>
                        <Row align="middle" justify="center" gutter={[8, 8]}>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={5} align="center">
                                <Card title={
                                    <b>NÚMERO DE TRANSAÇÕES</b>
                                } className="tickts">
                                    <Row align="middle">
                                        <Col span={24}>
                                            <Typography.Title style={{ textAlign: 'center' }} level={2}>
                                                {valoresTotais.qtde}
                                            </Typography.Title>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={5} align="center">
                                <Card title={
                                    <b>TOTAL DE VENDAS</b>
                                } className="tickts">
                                    <Row align="middle">
                                        <Col span={24}>
                                            <Typography.Title style={{ textAlign: 'center' }} level={2}>
                                                R${parseFloat(valoresTotais.valorVendaLiquida).toFixed(2)}
                                            </Typography.Title>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={5} align="center">
                                <Card title={
                                    <b>TICKET MÉDIO</b>
                                } className="tickts">
                                    <Row align="middle">
                                        <Col span={24}>
                                            <Typography.Title style={{ textAlign: 'center' }} level={2} >
                                                R${parseFloat(valoresTotais.valorVendaLiquida !== 0 ? valoresTotais.valorVendaLiquida / valoresTotais.qtde : 0).toFixed(2)}
                                            </Typography.Title>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <VendasPorVendedor formulario={form} datas={datas} valoresTotais={{ valoresTotais, setValoresTotais }} />
                <div className="tabela">
                    <Row align="middle" gutter={[8, 8]} className="m-t-16">
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <MaisVendidos formulario={form} datas={datas} />
                        </Col>
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                            <MenosVendidos formulario={form} datas={datas} />
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="lateral-home">
                <OperacoesFiscais />
            </div>
        </Form>
    );
}