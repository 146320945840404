import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, notification, Modal } from "antd";
import { ExclamationCircleOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import InputPhone from "../../inputPhone";
import SelectPaginacao from "../../selects/selectPaginacao";
import { MaskedInput } from "antd-mask-input";

export default function ModalTelefone({ form, listaTelefones, exibirModalTelefone, fecharModalTelefone }) {

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            zIndex: 2010,
            onOk() {
                form.resetFields();
                fecharModalTelefone();
            }
        });
    };

    const onFinish = values => {
        let indice = form.getFieldValue().indice;
        //delete values.indice;
        let lista = [...listaTelefones.dadosTelefone];
        values.psc_ativo = true;
        values.psc_numero = values.psc_numero.replace(/\D/g, '');
        if (!!indice) {
            values.psc_principal = lista.filter((psc) => (psc.indice === indice))[0].psc_principal;
        }
        if (indice >= 0) {
            lista[indice] = values;
        } else {
            let verificaOcupacaoAdd = lista.filter(o => o.psc_numero === form.getFieldValue().psc_numero);
            if (verificaOcupacaoAdd.length > 0) {
                fecharModalTelefone();
                notification.info({ message: 'Telefone já adicionado!' });
            } else {
                if (lista.length > 0) {
                    values.psc_principal = false;
                } else {
                    values.psc_principal = true;
                }
                lista.push(values);
            }
        }
        listaTelefones.setDadosTelefone(lista);
        form.resetFields();
        fecharModalTelefone();
    };

    const inputRef = React.createRef();
    useEffect(() => {
        setTimeout(() => {
            if (exibirModalTelefone && !!inputRef.current) {
                inputRef.current.focus({ cursor: 'end', })
            };
        }, 500);
    }, [exibirModalTelefone]);

    const cellphoneMask = '(00) 0 0000-0000';
    const phoneMask = '(00) 0000-0000';

    const mask = React.useMemo(
        () => [
            {
                mask: cellphoneMask,
                lazy: false,
            },
            {
                mask: phoneMask,
                lazy: false,
            },
        ]
    );

    return (
        <Modal centered
            title="Cadastro de Telefones"
            open={exibirModalTelefone}
            getContainer={false}
            onCancel={() => {
                onCloseModal();
            }}
            onOk={() => form.submit()}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            destroyOnClose={true}
        >
            <div>
                <Form layout="vertical" name="formTelefone" form={form} onFinish={onFinish}>
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item label="Número" name="psc_numero" rules={[{ required: true, message: 'Informe o Número do Telefone' }]}>
                                <MaskedInput mask={mask}
                                    className="ant-input ant-input-sm"
                                    placeholder="Informe o CEP"
                                    id="psc_numero"
                                    maskOptions={{
                                        dispatch: function (appended, dynamicMasked) {
                                            const isCellPhone = dynamicMasked.unmaskedValue[2] === '9';
                                            return dynamicMasked.compiledMasks[isCellPhone ? 0 : 1];
                                        },
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item label="Tipo de Telefone" name="tpt_id" rules={[{ required: true, message: 'Selecione um Tipo' }]}>
                                <SelectPaginacao url="TipoTelefone/Listar" placeholder="Selecione um Tipo" form={form} selecionarRegUnico="tpt_id" nameValue="tpt_id" nameLabel="tpt_descricao" conteudo={
                                    tt => (<Select.Option value={tt.tpt_id} key={tt.key} label={tt.tpt_descricao}>{tt.tpt_descricao}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item label="Ramal" name="psc_ramal">
                                <Input placeholder="Informe o ramal" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[8, 8]}>
                        <Col span={24}>
                            <Form.Item label="Observação" name="psc_observacao">
                                <Input.TextArea placeholder="Informe uma observação" />
                            </Form.Item>

                        </Col>
                    </Row>
                    <Form.Item name="indice" hidden />
                </Form>
            </div>
        </Modal>
    );
}