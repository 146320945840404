import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Table, Select, Form, Tag, Divider } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import moment from "moment";

import api from "../../services/api";
import { FormatNumber } from "../../ValueObjects";

export default function MaisVendidos({ datas }) {

    const [form] = Form.useForm();
    const [data, setData] = useState(true);
    const [listaProdutosMaisVendidos, setListaProdutosMaisVendidos] = useState([]);
    const [ordem] = useState([
        { descricao: 'Por Valor', key: '1', value: '+valor' },
        { descricao: 'Por Quantidade', key: '2', value: '+quantidade' }
    ]);

    function produtoMaisVendido() {
        let dataInicial = !!datas.dataInicial ? datas.dataInicial : moment(new Date()).format('YYYY-MM-DD');
        let dataFinal = !!datas.dataFinal ? datas.dataFinal : moment(new Date()).format('YYYY-MM-DD');
        let ordem = !!form.getFieldsValue().ordem ? form.getFieldValue().ordem : '+valor';
        api.get(`Produto/RetornaProdutosMaisMenosVendidos?Order=${ordem}&dataInicial=${dataInicial}&dataFinal=${dataFinal}`).then(
            res => {
                setListaProdutosMaisVendidos(res.data.items);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }

    useEffect(() => {
        produtoMaisVendido();
        form.setFieldsValue({ ordem: '+valor' })
    }, [datas])

    return (
        <div className="tabela mt-zero">
            <Table title={() => {
                return (
                    <Typography.Title level={5} className="m-0">
                        <Form layout="vertical" name="maisVendidos" form={form}>
                            <Row align="middle" gutter={[5, 0]} justify="space-between">
                                <Col xs={24} sm={24} md={14} lg={16} xl={16} xxl={18}>
                                    MAIS VENDIDOS
                                </Col>
                                <Col xs={24} sm={24} md={10} lg={8} xl={8} xxl={6}>
                                    <Form.Item name="ordem" className="p-f-b">
                                        <Select allowClear placeholder="Ordene por" onChange={() => { produtoMaisVendido(); setData(!data) }}>
                                            {ordem.map((item) => (
                                                <Select.Option label={item.descricao} key={item.key} value={item.value}>{item.descricao} </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Typography.Title>
                )
            }} columns={
                [
                    {
                        title: 'Item',
                        render: ({ pro_id, pro_descricao }) => (
                            <div>
                                <Row align="middle" gutter={[5, 0]}>
                                    <Col>
                                        <Tag color="processing" className="w-75">
                                            <b>{pro_id}</b>
                                        </Tag>
                                    </Col>
                                    <Col>
                                        <b>{pro_descricao}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: (record) => (
                            <div>
                                {data &&
                                    <div>
                                        UN. (R$)
                                    </div>
                                }
                                {!data &&
                                    <div>
                                        UN.
                                    </div>
                                }
                            </div>
                        ),
                        width: 150,
                        align: 'right',
                        render: ({ valor, quantidade, ump_id }) => (
                            <div>
                                {data &&
                                    <b>{FormatNumber(valor, true)}</b>
                                }
                                {!data &&
                                    <b>{`${quantidade} ${ump_id}`}</b>
                                }
                            </div>
                        ),
                    },
                ]
            } dataSource={listaProdutosMaisVendidos} scroll={{ y: 155 }} pagination={false} locale={{
                emptyText: (
                    <Row>
                        <Col span={24}>
                            <Divider orientation="center">
                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                            </Divider>
                        </Col>
                    </Row>
                )
            }} />
        </div>
    )
}