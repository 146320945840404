import React from "react";
import { Row, Col, Modal } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

export default function ModalPlano({ exibirModal, fecharModal }) {

    return (

        <Modal centered
            title="COMPARATIVO ENTRE PLANOS"
            open={exibirModal}
            getContainer={false}
            onCancel={fecharModal}
            footer={null}
            maskClosable={false}
            className="modalPlanos"
        >
            <div className="planos">
                <Row align="middle">
                    <Col span={9} className="hg35">
                        &nbsp; &nbsp;
                    </Col>
                    <Col span={2} align="center">
                        <div className="titlePlano">
                            GRÁTIS
                        </div>
                    </Col>
                    <Col span={2} align="center">
                        <div className="titlePlano">
                            MEI
                        </div>
                    </Col>
                    <Col span={2} align="center">
                        <div className="titlePlano">
                            INICIAL
                        </div>
                    </Col>
                    <Col span={3} align="center">
                        <div className="titlePlano">
                            AVANÇADO
                        </div>
                    </Col>
                    <Col span={3} align="center">
                        <div className="titlePlano">
                            COMPLETO
                        </div>
                    </Col>
                    <Col span={3} align="center">
                        <div className="titlePlano">
                            PREMIUM
                        </div>
                    </Col>
                </Row>
                <div>
                    <Row align="middle">
                        <Col span={9} align="right">Implantação Gratuita</Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center">Sob Consulta</Col>
                        <Col span={3} align="center">Sob Consulta</Col>
                        <Col span={3} align="center">Sob Consulta</Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Usuários Ilimitados</Col>
                        <Col span={2} align="center">1</Col>
                        <Col span={2} align="center">1</Col>
                        <Col span={2} align="center">1</Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Emissão de Nota Fiscal Ilimitada</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Dashboards Gerenciais</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Monitoramento de NF-e</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Venda Online (PDV WEB)</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Ponto de Venda (PDV) Online/Offline</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center">1</Col>
                        <Col span={3} align="center">4</Col>
                        <Col span={3} align="center">6</Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Grade de Produtos Personalizável</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Adição de Campos Personalizáveis em Cadastros Básicos</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Pré-Venda Mobile</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Backup Diário Automático</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Segurança de Dados</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Materiais de Ajuda</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Guarda do XML por 5 anos seguindo todos os critérios legais</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Permite Cadastro de Filiais</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Acesso a API de Integração</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Chamados de Suporte (Abertura de Ticket/E-mail)</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={24} align="center">
                            <div className="titlePlano title">
                                FINANCEIRO
                            </div>
                        </Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Contas a Pagar</Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Contas a Receber</Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Emissão de Boletos</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Integração Bancária (Boletos)</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Fluxo de Caixa</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Lançamento de Caixa</Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Dashboard Financeiro</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Contas Gerenciais</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">DRE Gerencial (Padrão)</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={24} align="center">
                            <div className="titlePlano title">
                                VENDAS
                            </div>
                        </Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Orçamento</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Pedido</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Condicional (NEW/PDV/APP)</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Controle de Comissão</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Gestão de Metas de Vendas</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Promoções</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Devolução de Produtos</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Troca</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Aplicativo Pré-venda (iOS, Android)</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Análise de Crédito de Cliente</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Emissão de NF-e (Venda, Devolução, Débito de ICMS, Transferência¹)</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Emissão de NF-e (Entrega Futura, Remessas)</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={24} className="p-t-b-5">
                            OBS: ¹ Nota de Transferência disponível quando houver uma ou mais filiais
                        </Col>
                    </Row>
                    <Row align="middle">
                        <Col span={24} align="center">
                            <div className="titlePlano title">
                                PONTO DE VENDA – PDV (ONLINE/OFFLINE)
                            </div>
                        </Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Licenças</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center">1</Col>
                        <Col span={3} align="center">3</Col>
                        <Col span={3} align="center">8</Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Disponibilidade Offline</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">NFC-e</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Integração com Aplicativo de Pré Venda</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Recebimento de Parcelas</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Lançamento Condicional</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Sincronização de Dados</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Impressão de Carnê</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Emissão de Boletos no PDV</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Gerador de Etiquetas Personalizáveis</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Cadastro de Clientes</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Lista de Clientes com Total de Compras e Ticket Médio</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Cálculo do Limite de Crédito</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Dashboard de Vendas</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Lançamento de Despesas</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Transferências Automáticas no Fechamento de Caixa</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={24} align="center">
                            <div className="titlePlano title">
                                COMPRAS
                            </div>
                        </Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Ordem de Compra</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Emissão de NF-e (Entrada, Crédito de ICMS, Retorno de Mercadoria)</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Importação de XML</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Manifestação de Destinatário de NF-e</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={24} align="center">
                            <div className="titlePlano title">
                                ESTOQUE
                            </div>
                        </Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Controle de Estoque</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Dashboard de Estoque</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Registro de Inventário Automatizado</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={24} align="center">
                            <div className="titlePlano title">
                                RELATÓRIOS
                            </div>
                        </Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Emissão Relatórios</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Exportação de Relatórios (PDF e XLS)</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Envio de Relatórios por e-mail</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Personalização de Relatórios</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Personalização de Modelos de Etiquetas</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={24} align="center">
                            <div className="titlePlano title">
                                POLÍTICAS DE ACESSO
                            </div>
                        </Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Cadastro de Usuários</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Grupos de Usuários</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Gerenciamento de Acesso às Filiais</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Restrição de Acesso por Dia da Semana e Horário</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Controle Permissão de Acesso por Usuário e/ou Grupo de Usuário</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                    <Row align="middle">
                        <Col span={24} align="center">
                            <div className="titlePlano title">
                                PORTAL DO CONTADOR
                            </div>
                        </Col>
                    </Row>
                    <Row align="middle">
                        <Col span={9} align="right">Acesso ao Portal do Contador</Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CloseOutlined /></Col>
                        <Col span={2} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                        <Col span={3} align="center"><CheckOutlined /></Col>
                    </Row>
                </div>
            </div>
        </Modal>

    );
}