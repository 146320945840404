import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Button, Modal } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined, MinusOutlined } from "@ant-design/icons";

import { DrawerNav } from '../../components';
import { useStateValue } from '../../state';
import ManutencaoClientes from '../../components/drawerCliente/manutencao';
import { drawerActions, listagemActions, manutencaoActions } from '../../actions';

export default function DrawerDevolucaoCliente({ abrirDrawerDevCliente, fecharDrawerDevCliente, formCliente }) {

    const nameForm = "manutencaoVendedor"
    const [{ ui, manutencao }, dispatch] = useStateValue();
    const TIPO_PESSOA = { FISICA: 1, JURIDICA: 2, ESTRANGEIRO: 3 }
    const [editando, setEditando] = useState(false);
    const [carregando, setCarregando] = useState(false);

    useEffect(() => {
        if (!!manutencao.dados) {
            let cpfCnpj = !!formCliente.getFieldValue().cpfCnpj ? formCliente.getFieldValue().cpfCnpj.replaceAll('-', '').replaceAll('/', '').replaceAll('.', '') : '';
            if (manutencao.dados.editando) {
                setEditando(true);
            }
            if (manutencao.dados.pes_fisicajuridica === TIPO_PESSOA.FISICA) {
                cpfCnpj = manutencao.dados.pef_cpf;
            } else if (manutencao.dados.pes_fisicajuridica === TIPO_PESSOA.JURIDICA) {
                cpfCnpj = manutencao.dados.pej_cnpj;
            }
            manutencao.dados.cpfCnpj = cpfCnpj;
            formCliente.setFieldsValue(manutencao.dados);
        } else {
            formCliente.setFieldsValue({ cli_id: null, cli_ativo: true });
        }
    }, [manutencao.dados])

    function fecharDrawer() {
        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pes_nome' } });
        dispatch({ type: manutencaoActions.CHANGE, data: { dados: null } });
        fecharDrawerDevCliente();
        setEditando(false);
    }

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            okType: 'danger',
            centered: true,
            onOk() {
                fecharDrawer();
            }
        });
    };

    return (
        <DrawerNav title="Cadastro Cliente"
            width="65%"
            visible={abrirDrawerDevCliente}
            closeIcon={<MinusOutlined />}
            fecharDrawer={() => { fecharDrawer() }}
            footer={
                <div>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button onClick={() => formCliente.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ManutencaoClientes formulario={formCliente} nameForm={nameForm} carregando={setCarregando} aoSalvar={fecharDrawer} editando={editando} setEditando={setEditando} abrirDrawerDevCliente={abrirDrawerDevCliente} />
        </DrawerNav>

    )
}