import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Drawer, Modal, Form } from "antd";
import { CloseOutlined, SaveOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { FormatNumber } from '../../ValueObjects';
import ReparcelamentoParcelas from './reparcelamentoParcelas';
import { useStateValue } from '../../state';

export default function DrawerReparcelamento({ listaParcelasReparcelar, listaTotalizadoresReparcelar, dadosCliente, buscarParcelasPessoa, showDrawerReparcelamento, fecharDrawerReparcelamento }) {

    const [{ui}, dispatch] = useStateValue()
    const [formReparcelamento] = Form.useForm();
    const [parcelasGeradas, setParcelasGeradas] = useState([]);
    const [carregando, setCarregando] = useState(false);

    function fecharDrawer() {
        formReparcelamento.resetFields();
        setParcelasGeradas([]);
        fecharDrawerReparcelamento();
    }

    useEffect(() => {
        setParcelasGeradas([]);
        formReparcelamento.resetFields();
    }, [listaParcelasReparcelar]);

    const onClose = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação e ignorar o que foi realizado até o momento?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharDrawerReparcelamento();
            }
        });
    };

    return (
        <Drawer title="Reparcelamento de Parcelas"
            width="65%"
            open={ui.showDrawer}
            onClose={onClose}
            footer={
                <div>
                    <Row align="middle" justify="space-between" gutter={[8, 0]} className="text-right">
                        <Col>
                            {(!!listaTotalizadoresReparcelar.valorTotalJuros && listaTotalizadoresReparcelar.valorTotalJuros !== 0) &&
                                <div>
                                    Valor Juros (R$)
                                    <div>
                                        <b>{FormatNumber(listaTotalizadoresReparcelar.valorTotalJuros, true)}</b>
                                    </div>
                                </div>
                            }
                        </Col>
                        <Col>
                            {(!!listaTotalizadoresReparcelar.valorDesconto && listaTotalizadoresReparcelar.valorDesconto !== 0) &&
                                <div>
                                    Valor Descontos (R$)
                                    <div>
                                        <b>{FormatNumber(listaTotalizadoresReparcelar.valorDesconto, true)}</b>
                                    </div>
                                </div>
                            }
                        </Col>
                        <Col>
                            {(!!listaTotalizadoresReparcelar.valorAReceber && listaTotalizadoresReparcelar.valorAReceber !== 0) &&
                                <div className="m-b-5">
                                    Valor à Receber (R$)
                                    <div className="f-16 c-primary">
                                        <b>{FormatNumber(listaTotalizadoresReparcelar.valorAReceber, true)}</b>
                                    </div>
                                </div>
                            }
                        </Col>
                    </Row>
                    <Row align="middle" justify="end" gutter={[8, 0]}>
                        <Col>
                            <Button onClick={onClose} icon={<CloseOutlined />} size="large" htmlType="button">
                                Cancelar
                            </Button>
                        </Col>
                        <Col>
                            <Button disabled={parcelasGeradas.length === 0} onClick={() => formReparcelamento.submit()} loading={carregando} icon={<SaveOutlined />} size="large" type="primary" htmlType="submit">
                                Salvar
                            </Button>
                        </Col>
                    </Row>
                </div>
            }>
            <ReparcelamentoParcelas formulario={formReparcelamento} carregando={setCarregando} aoSalvar={() => { fecharDrawer(); buscarParcelasPessoa(); }} listaParcelasReparcelar={listaParcelasReparcelar} listaTotalizadoresReparcelar={listaTotalizadoresReparcelar} dadosCliente={dadosCliente} parcelasGeradas={{ parcelasGeradas, setParcelasGeradas }} />
        </Drawer>
    )
}