import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Table, Divider, Tag, Button, Typography } from "antd";
import { ExclamationCircleOutlined, ArrowRightOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { useStateValue } from "../../../state";
import TabelaDados from "../../tabelaDados";
import { listagemActions } from "../../../actions";

export default function ModalVendedor({ exibirModalVendedor, fecharModalVendedor, setDadosVendedor }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalVendedor();
            }
        });
    };

    function adicionarVendedor(dados) {
        setDadosVendedor(dados);
        fecharModalVendedor();
    }

    useEffect(() => {
        if (exibirModalVendedor)
            dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pes_nome', filtro: '' } });
    }, [exibirModalVendedor])

    return (
        <Modal centered
            width={720}
            title="Vendedor"
            open={exibirModalVendedor}
            getContainer={false}
            onCancel={() => {
                onCloseModal();
            }}
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            destroyOnClose={true}
        >
            <div>
                <div>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <Typography.Text>
                                Abaixo selecione um vendedor.
                            </Typography.Text>
                        </Col>
                    </Row>
                </div>
                <div>
                    <Row align="middle" className="tabela m-t-16">
                        <Col span={24}>
                            <div className="tabela">
                                <TabelaDados url="Vendedor/Listar"
                                    colunas={[
                                        {
                                            title: 'Vendedor',
                                            width: 300,
                                            render: ({ ven_id, pes_nome }) => (
                                                <div>
                                                    <Row align="middle" gutter={[8, 0]}>
                                                        <Col>
                                                            <Tag color="processing">
                                                                <b>#{ven_id}</b>
                                                            </Tag>
                                                        </Col>
                                                        <Col className="over-text">
                                                            <b>{pes_nome}</b>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            ),
                                        },
                                        {
                                            title: 'Ações',
                                            align: 'right',
                                            render: (record) => (
                                                <div>
                                                    <Button type="primary" onClick={() => adicionarVendedor(record)}>
                                                        Selecionar <ArrowRightOutlined />
                                                    </Button>
                                                </div>
                                            ),
                                        },
                                    ]} scroll={{ y: 160 }}
                                />
                            </div>
                        </Col>
                    </Row>

                </div>
            </div>
        </Modal>
    );
}