import React from "react";
import { Row, Col, Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";


export default function BreadcrumbPage({ pagina, pagina_seleciona }) {
    
    return (
    <>
        <Row align="middle" gutter={[8,0]}>
            <Col>
                <Breadcrumb className="m-b-3">
                    <Breadcrumb.Item key="home">
                        <Link to="/">
                            <HomeOutlined />
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item key="">
                        {pagina}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item key="">
                        <b className="f-18">{pagina_seleciona}</b>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Col>
        </Row>
    </>
  );
}