import React from "react";
import { Table, Form, Row, Col, Input } from "antd";
import { WarningOutlined, CheckOutlined } from "@ant-design/icons";

import { FormatNumber } from '../../../ValueObjects';

export default function Resumo({ dadosLista }) {


    return (
        <div>
            <div className="tabela mt-zero">
                <Table columns={[
                    {
                        title: '',
                        render: ({ fpg_descricao }) => (
                            <div>
                                <b>{fpg_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        width: 130,
                        align: 'right',
                        render: ({ valorInformado }) => (
                            <div>
                                Vlr Informado (R$):  <b>{FormatNumber(valorInformado, true)}</b>
                            </div>
                        ),
                    },
                    {
                        width: 130,
                        align: 'right',
                        render: ({ valorTotalCaixa, valorInformado }) => (
                            <div>
                                Vlr Quebra (R$): <b>{FormatNumber( valorInformado - valorTotalCaixa , true)}</b>
                            </div>
                        ),
                    },

                    {
                        width: 130,
                        align: 'right',
                        render: ({ valorTotalCaixa, valorInformado }) => (
                            <div>
                                <Row>
                                    <Col span={24}>
                                        Saldo Caixa (R$): {valorInformado < valorTotalCaixa  ? <WarningOutlined /> : <CheckOutlined /> }<b>{FormatNumber(valorTotalCaixa, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} dataSource={dadosLista} pagination={false} showHeader={false} />
            </div>
            <div>
                <Row gutter={[8, 8]} className="m-t-16">
                    <Col span={24}>
                        <Form.Item label="Observação" name="ctx_observacao">
                            <Input.TextArea placeholder="Informe uma observação caso necessário" />
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        </div>
    );
}