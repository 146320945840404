import React from "react";
import { Row, Col, Form, Select, Switch } from "antd";
import { InputPreco } from "../../../components";

export default function Dinheiro({ listaContaCorrente, dadosTransferencia, setDadosTransferencia, alteraValor }) {

    const switchTransferencia = () => {
        setDadosTransferencia(!dadosTransferencia)
    };

    return (
        <Row gutter={[8, 0]}>
            <Col span={8}>
                <InputPreco name="ctxValorFechamento" label="Valor Caixa (R$)" onBlur={() => alteraValor()} />
            </Col>
            <Col className="btn-fil">
                <Row gutter={[8, 0]}>
                    <Col>
                        <Form.Item initialValue={dadosTransferencia}>
                            <Switch onClick={switchTransferencia} />
                        </Form.Item>
                    </Col>
                    <Col>
                        Realizar transferência automática?
                    </Col>
                </Row>
            </Col>
            {dadosTransferencia &&
                <>
                    <Col span={16}>
                        <Form.Item label="Conta Corrente" name="ctc_id" rules={[{ required: dadosTransferencia }]}>
                            <Select
                                optionFilterProp="children"
                                showSearch
                                allowClear
                                placeholder="Selecione uma Conta Corrente"
                            >
                                {listaContaCorrente.map(conta => (
                                    <Select.Option key={conta.key} value={conta.ctc_id} label={conta.ctc_descricao}> {conta.ctc_descricao}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <InputPreco label="Valor (R$)" name="valorTransferencia" />
                    </Col>
                </>
            }
        </Row >
    );
}