import React, { useState, useEffect } from "react";
import moment from 'moment';
import { Row, Col, Table, Tag } from "antd";

import { FormatNumber } from '../../../ValueObjects';

export default function MovimentoCaixa({ listaMovimentos }) {

    return (
        <div className="tabela mt-zero">
            <Table
                columns={[
                    {
                        title: 'Movimento do Caixa',
                        render: ({ mcc_entradasaida, mcc_complemento, mcc_dataHora }) => (
                            <div>
                                <Row align="middle" gutter={[8, 0]}>
                                    <Col>
                                        <Tag color={mcc_entradasaida === 1 ? 'red' : 'processing'}>
                                            <b>{mcc_entradasaida === 1 ? 'Saída' : 'Entrada'}</b>
                                        </Tag>
                                    </Col>
                                    <Col className="w-space">
                                        <b>{mcc_complemento}</b>
                                    </Col>
                                    <Col span={24}>
                                        <b>{moment(mcc_dataHora).format("DD/MM/YYYY HH:mm:ss")}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Valor (R$)',
                        align: 'right',
                        width: 130,
                        render: ({ mcc_valor, mcc_valorjuros, mcc_valormulta, mcc_valordesconto }) => (
                            <div>
                                <Row gutter={[8, 0]} justify="end">
                                    <Col>
                                        <b>{FormatNumber((mcc_valor + mcc_valorjuros - mcc_valordesconto), true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} dataSource={listaMovimentos} scroll={{ y: 149 }} pagination={false}
            />
        </div>
    );
}