import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Layout, Avatar, Dropdown, Menu, Button } from "antd";
import { SettingFilled, LogoutOutlined, PrinterFilled } from '@ant-design/icons';

import "./index.less";
import api from "../../services/api";
import { useStateValue } from "../../state";
import { getEmailUsuario, logout } from '../../services/auth';
import { BreadcrumbPage, BotaoAbrirChamado } from "../../components";

export default function HeaderPage() {

    const [image, setImage] = useState(false);
    const [{ manutencao }, dispatch] = useStateValue();

    const menuUsuario = (

        <Menu className="col-avatar text-right">
            <Menu.Divider />
            <Menu.Item key="1">
                <Button type="text">
                    <Link to="/parametrosEmpresa">
                        <SettingFilled /> Configurações
                    </Link>
                </Button>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="2">
                <Button type="text">
                    <PrinterFilled /> Impressoras
                </Button>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="3">
                <Button type="text" block className="text-right" onClick={() => logout()}>
                    <LogoutOutlined /> Sair
                </Button>
            </Menu.Item>
        </Menu>

    );

    const avatar = useMemo(() => {
        return (<Avatar size="large" src={image} />);
    }, [image]);

    useEffect(() => {
        listarAvatar();
    }, []);

    function listarAvatar() {
        api.get(`Usuario/AvatarBase64/${getEmailUsuario()}`).then(
            res => {
                setImage(`data:image/png;base64,${res.data}`);
            }
        ).catch(
            erro => {
                console.log(erro)
            }
        )
    };


    return (

        <Layout.Header>
            <Row align="middle" justify="center" gutter={[8, 0]}>
                <Col span={12}>
                    <img width="125" height="35" src={require("../../assets/logoWhite.png")} alt="Gestor Web" className="logo" />
                </Col>
                <Col span={12}>
                    <Row justify="end">
                        <Col>
                            <BotaoAbrirChamado />
                        </Col>
                        <Col>
                            <Dropdown overlay={menuUsuario} trigger={['click']} placement="bottomRight" arrow>
                                <span className="p-l-5">
                                    {avatar}
                                </span>
                            </Dropdown>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Layout.Header>

    );

}