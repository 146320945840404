import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Select, Checkbox, Modal } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";

import Data from "../../data";
import api from "../../../services/api";

export default function ModalNacionalidade({ form, dadosNacionalidade, exibirModalNacionalidade, fecharModalNacionalidade }) {

    const [listaPais, setListaPais] = useState([]);
    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            zIndex: 2010,
            centered: true,
            onOk() {
                fecharModalNacionalidade();
            }
        });
    };

    useEffect(() => {
        carregarPais();
    }, []);

    function carregarPais() {
        api.get(`Pais/Listar`).then(
            res => {
                if (res.status === 200) {
                    setListaPais(res.data);
                }
            }
        ).catch(
            erro => {
                console.log(erro);
            }
        )
    };

    const salvar = record => {
        let nacionalidade = [...dadosNacionalidade.listaNacionalidade];
        let indice = form.getFieldValue().indice;
        delete record.indice;
        record.pais = listaPais.filter((pais) => (pais.pse_id === record.pse_id))[0];
        if (indice >= 0) {
            nacionalidade[indice] = record;
        } else {
            nacionalidade.push(record);
        }
        dadosNacionalidade.setListaNacionalidade(nacionalidade);
        fecharModalNacionalidade();
    };

    return (
        <Modal centered
            title="Cadastro de Nacionalidade"
            open={exibirModalNacionalidade}
            getContainer={false}
            onCancel={onCloseModal}
            onOk={() => form.submit()}
            okText={
                <>
                    <PlusOutlined /> Adicionar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <Form layout="vertical" name="formNacionalidade" form={form} onFinish={salvar}>
                <Row gutter={[8, 0]}>
                    <Col span={12}>
                        <Form.Item label="País" name="pse_id" rules={[{ required: true, message: 'Selecione o País' }]}>
                            <Select allowClear placeholder="Selecione o País" showSearch optionFilterProp="children">
                                {listaPais.map((pais) => (
                                    <Select.Option label={pais.pse_nome} value={pais.pse_id} key={pais.pse_id}>{pais.pse_nome}</Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Data label="Data de Entrada no País" name="pen_dataentradapais" />
                    </Col>
                    <Col>
                        <Form.Item name="pen_naturalizadobrasil" valuePropName="checked" initialValue={false}>
                            <Checkbox>Naturalizado</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Observação" name="pen_observacao">
                            <Input.TextArea placeholder="Informe uma observação" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}