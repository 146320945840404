import React, { useState, useEffect } from 'react';
import { Row, Col, Descriptions, Tabs } from "antd";
import moment from 'moment';

import { FormatFone, MaskFormat } from '../../ValueObjects/';
import { TabDetalheItens, TabDetalheIFormaPagamento } from './tabPage';

export default function DetalheNotaFiscal({ dadosModal }) {

    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [detalheNotaFiscal, setDetalheNotaFiscal] = useState({});
    const [arrFinalidade] = useState(["NF-E NORMAL", "NF-E COMPLEMENTAR", "NF-E DE AJUSTE", "DEVOLUÇÃO"]);
    const [arrStatus] = useState(["EM DIGITAÇÃO", "AUTORIZADA", "CANCELADA", "DENEGADA", "REJEITADA", "CONTINGÊNCIA"]);
    const [arrAmbiente] = useState(["PRODUÇÃO", "HOMOLOGAÇÃO"]);

    useEffect(() => {
        if (!!dadosModal) {
            setDetalheNotaFiscal(dadosModal);
        }
    }, [dadosModal]);

    return (
        <div>
            <Row align="middle">
                <Col>
                    <Descriptions column={2} size="small">
                        <Descriptions.Item label="Chave">
                            <b>{(!!detalheNotaFiscal.ntf_chavenfe ? detalheNotaFiscal.ntf_chavenfe : 'NÃO DISPONÍVEL')}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Data de Emissão">
                            <b>{!!detalheNotaFiscal.ntf_dataemissao ? moment(detalheNotaFiscal.ntf_dataemissao).format('DD/MM/YYYY') : null}</b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions column={2} size="small">
                        <Descriptions.Item label="Nº">
                            <b>{detalheNotaFiscal.ntf_numero}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Operação">
                            <b>{detalheNotaFiscal.ope_descricao}</b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions column={3} size="small">
                        <Descriptions.Item label="Finalidade">
                            <b>{arrFinalidade[detalheNotaFiscal.ntf_finalidade - 1]}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Status">
                            <b>{arrStatus[detalheNotaFiscal.ntf_status]}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Ambiente">
                            <b>{arrAmbiente[detalheNotaFiscal.ntf_ambiente - 1]}</b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions column={3} size="small">
                        <Descriptions.Item label="Vendedor">
                            <b>{(!!detalheNotaFiscal.nomeVendedor ? detalheNotaFiscal.nomeVendedor : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Destinatário">
                            <b>{(!!detalheNotaFiscal.pes_nome ? detalheNotaFiscal.pes_nome : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Pessoa Autorizada">
                            <b>{(!!detalheNotaFiscal.nomepessoaautorizada ? detalheNotaFiscal.nomepessoaautorizada : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions column={2} size="small">
                        <Descriptions.Item label="CPF/CNPJ">
                            <b>{(!!detalheNotaFiscal.pej_cnpj ? MaskFormat(detalheNotaFiscal.pej_cnpj, '', true) : (!!detalheNotaFiscal.pef_cpf ? MaskFormat(detalheNotaFiscal.pef_cpf, '', true) : 'NÃO INFORMADO'))}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="IE">
                            <b>{(!!detalheNotaFiscal.ntf_iedest ? detalheNotaFiscal.ntf_iedest : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions column={2} size="small">
                        <Descriptions.Item label="Telefone">
                            <b>{!!detalheNotaFiscal.ntf_telefonedest ? FormatFone(detalheNotaFiscal.ntf_telefonedest) : 'NÃO INFORMADO'}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="E-mail">
                            <b>{(!!detalheNotaFiscal.ntf_emaildest ? detalheNotaFiscal.ntf_emaildest : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle">
                <Col>
                    <Descriptions size="small">
                        <Descriptions.Item label="Endereço">
                            <b>{(!!detalheNotaFiscal.ntf_logradourodest ? detalheNotaFiscal.ntf_logradourodest : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <Row align="middle" gutter={[0, 16]}>
                <Col>
                    <Descriptions column={3} size="small">
                        <Descriptions.Item label="Bairro">
                            <b>{(!!detalheNotaFiscal.ntf_bairrodest ? detalheNotaFiscal.ntf_bairrodest : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="Cidade">
                            <b>{(!!detalheNotaFiscal.ntf_municipiodest ? detalheNotaFiscal.ntf_municipiodest : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>
                        <Descriptions.Item label="CEP">
                            <b>{(!!detalheNotaFiscal.ntf_cepdest ? MaskFormat(detalheNotaFiscal.ntf_cepdest, '00000-000') : 'NÃO INFORMADO')}</b>
                        </Descriptions.Item>
                    </Descriptions>
                </Col>
            </Row>
            <div className="m-t-8">
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Itens" key="1">
                        <TabDetalheItens itensNF={detalheNotaFiscal.itensNf} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Formas de Pagamento" key="2">
                        <TabDetalheIFormaPagamento formaPagamento={detalheNotaFiscal.formaPgto} />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>
    );
}