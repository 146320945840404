import React, { useEffect, useState } from "react";
import { Row, Col, Form, Modal, Typography, Button, Steps, notification } from "antd";
import { ExclamationCircleOutlined, ArrowRightOutlined, LeftOutlined, CheckOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../../services/api";
import MovimentacaoCartoes from "./cartoes";
import { arredonda, novaAbaNavegador } from "../../../services/funcoes";
import { getLocal, getNomeUsuario } from "../../../services/auth";
import { CupomCancelados, MovimentoCaixa, Dinheiro, Resumo, Pix } from './pages';
import { useStateValue } from "../../../state";

export default function ModalFechamentoCaixa({ setCaixaAberto, exibirModalFechamentoCaixa, fecharModalFechamentoCaixa }) {

    const [{ dadosPdv }] = useStateValue();
    const [form] = Form.useForm();
    const [tagPages, setTagPages] = useState(0);
    const [totais, setTotais] = useState({});
    const [dadosPdvCaixa, setDadosPdvCaixa] = useState({});
    const [dadosTransferencia, setDadosTransferencia] = useState(false);

    //listas

    const [parametrosEmpresa, setParametrosEmpresa] = useState({});
    const [listaContaCorrente, setListaContaCorrente] = useState([]);
    const [listaMovimentos, setListaMovimentos] = useState([]);
    const [listaCancelados, setListaCancelados] = useState([]);
    const [listaMovimentosCartoes, setListaMovimentosCartoes] = useState([]);
    const [dadosLista, setDadosLista] = useState([]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            okType: 'danger',
            centered: true,
            onOk() {
                fecharModalFechamentoCaixa();
                setTagPages(0);
            }
        });
    };
    useEffect(() => {
        if (exibirModalFechamentoCaixa) {
            carregarDados();
        }
    }, [exibirModalFechamentoCaixa])

    function carregarDados() {
        api.get(`ControleCaixa/ListaControleCaixaAberto/${dadosPdv?.pdv?.pdv_id}`).then(
            res => {
                let dadosTemp = { ...dadosPdv?.pdv, ...res.data };
                setDadosPdvCaixa(dadosTemp);
                carregarListas(dadosTemp);
            }).catch(
                error => {
                    console.log(error);
                }
            );

        api.get(`ContaCorrente/ListarContasParaTransferencia?filtro=&codigoContaCorrente=&codigoLocal=${getLocal()}&ordem=%2Bctc_descricao`).then(
            res => {
                let key = 0;
                res.data.forEach((item) => {
                    item.key = key++
                })
                setListaContaCorrente(res.data);
            }).catch(
                error => {
                    console.log(error);
                }
            );
        api.get(`ParametroEmpresa/BuscarParametros`).then(
            res => {
                setParametrosEmpresa(res.data);
            }
        ).catch(
            error => {
                console.log(error);
            }
        );

    }

    function carregarListas(dados) {
        api.get(`MovimentoContaCorrente/ListaMovimentoEntradaSaidaContaCorrente?IdContaCorrente=${dados.ctc_id}&dataInicial=${dados.ctx_datahoraabertura}`).then(
            res => {
                let totais = {
                    saida: res.data.valorTotalSaidas,
                    entrada: res.data.valorTotalEntradas
                };
                let dadosTemp = {
                    fpg_descricao: 'DINHEIRO',
                    valorInformado: !!form.getFieldValue().ctxValorFechamento ? parseFloat(form.getFieldValue().ctxValorFechamento) : !!form.getFieldValue().valorDinheiro ? form.getFieldValue().valorDinheiro : 0,
                    valorMovimento: res.data.valorTotalEntradas - res.data.valorTotalSaidas,
                    valorTotalCaixa: (dados.ctx_valorabertura - res.data.valorTotalSaidas) + res.data.valorTotalEntradas
                }
                setDadosLista([dadosTemp]);
                setTotais(totais);
                setListaMovimentos(res.data.movimento);
            }
        ).catch(error => {
            console.log(error);
        });
        api.get(`NotaFiscal/RetornaNotasPorCaixa?controleCaixaId=${dados.ctx_id}&status=2`).then(
            res => {
                setListaCancelados(res.data);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
        api.get(`RecebimentoCartao/ListaResumoMovimentoCartao?controleCaixaId=${dados.ctx_id}`).then(
            res => {
                setListaMovimentosCartoes(res.data);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }

    function fecharCaixa() {
        let dadosTemp = { ...dadosPdvCaixa };
        dadosTemp.ctx_observacao = form.getFieldValue().ctx_observacao;
        let valorSaldoCaixa = parseFloat(parseFloat((dadosTemp.ctx_valorabertura + totais.entrada) - totais.saida).toFixed(2));
        dadosTemp.ctx_quebracaixa = !!dadosTemp.ctx_quebracaixa ? dadosTemp.ctx_quebracaixa : parseFloat((valorSaldoCaixa - form.getFieldValue().ctxValorFechamento).toFixed(2));
        dadosTemp.ctx_valorfechamento = arredonda(form.getFieldValue().ctxValorFechamento);

        if (!!form.getFieldValue().ctc_id) {
            dadosTemp.dadosTransferencia = {
                ctcorigem: { ctc_id: dadosTemp.ctc_id },
                ctcdestino: { ctc_id: form.getFieldValue().ctc_id },
                ctgorigem: { ctg_id: parametrosEmpresa.par_contagerencialsangriapdv },
                ctgdestino: { ctg_id: parametrosEmpresa.par_contagerencialsuprimentopdv },
                mcc_valor: !!form.getFieldValue().valorTransferencia ? parseFloat(form.getFieldValue().valorTransferencia) : 0,
                mcc_entradasaida: 3,
                mcc_numerodocumento: '',
                mcc_complemento: 'Transferência',
                localdestino: { loc_id: getLocal() }
            };
            api.post(`MovimentoContaCorrente/IncluirLancamentoCaixa`, dadosTemp.dadosTransferencia).then(
                res => {
                    notification.success({ message: 'Sucesso', description: 'Transferencia realizada com sucesso!' });
                    dadosTemp.ctx_valorfechamento = arredonda(dadosTemp.ctx_valorfechamento - res.data.mcc_valor)
                    api.post(`ControleCaixa/RealizarFechamento`, dadosTemp).then(
                        res => {
                            novaAbaNavegador(`ControleCaixa/Imprimir?CaixaId=${res.data.ctx_id}`);
                            notification.success({ message: 'Sucesso!', description: 'Caixa fechado com sucesso!' });
                            setCaixaAberto(false);
                        }
                    ).catch(
                        error => {
                            console.log(error);
                        }
                    ).finally(() => {
                        setDadosTransferencia(false);
                        form.resetFields();
                    })
                }
            ).catch(
                error => {
                    console.log(error);
                }
            ).finally(() => {
                setDadosTransferencia(false);
                window.caixaAberto = false;
                setTagPages(0);
                limparListas();
                form.resetFields();
                fecharModalFechamentoCaixa();
            })
        } else {
            api.post(`ControleCaixa/RealizarFechamento`, dadosTemp).then(
                res => {
                    novaAbaNavegador(`ControleCaixa/Imprimir?CaixaId=${res.data.ctx_id}`);
                    notification.success({ message: 'Sucesso!', description: 'Caixa fechado com sucesso!' });
                    setCaixaAberto(false);
                    fecharModalFechamentoCaixa();
                }
            ).catch(
                error => {
                    console.log(error);
                }
            ).finally(() => {
                setDadosTransferencia(false);
                form.resetFields();
                window.caixaAberto = false;
                limparListas();
                setTagPages(0);
                fecharModalFechamentoCaixa();
            })

        }
    }

    function modalfecharCaixa() {
        Modal.confirm({
            title: 'Fechar este caixa agora?',
            icon: <ExclamationCircleOutlined />,
            content: 'Fechar este caixa agora?',
            okText: 'Sim, quero fechar o caixa',
            cancelText: 'Não',
            okType: 'danger',
            centered: true,
            onOk() {
                fecharCaixa();
            }
        })
    }

    function alteraValorCaixa() {
        let listaTemp = [...dadosLista];
        let dadosTemp = listaTemp.filter((item) => (item.fpg_descricao === 'DINHEIRO'))[0];
        dadosTemp.valorInformado = parseFloat(form.getFieldValue().ctxValorFechamento);
        setDadosLista(listaTemp);
    }

    function limparListas() {
        setListaContaCorrente([]);
        setListaMovimentos([]);
        setListaCancelados([]);
        setListaMovimentosCartoes([]);
        setDadosLista([]);
    }

    return (
        <Modal centered
            title="Fechamento do Caixa"
            open={exibirModalFechamentoCaixa}
            getContainer={false}
            onCancel={onCloseModal}
            onOk={() => modalfecharCaixa()}
            okText="Ok"
            cancelText="Cancelar"
            maskClosable={false}
            width={760}
            footer={false}
            className="modalSemPad"
        >
            <div className="col-modal">
                <Form layout="vertical" name="manutencaoFechamentoCaixa" className="p-t-r-l-16" form={form} onFinish={modalfecharCaixa}>
                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <Typography.Text>
                                O Fechamento de caixa é feito ao final do expediente com o total das movimentações realizadas durante o dia.
                            </Typography.Text>
                        </Col>
                    </Row>
                    <Row gutter={[8, 0]} justify="space-between" className="m-t-8">
                        <Col>
                            Operador: <b> {getNomeUsuario()} </b>
                        </Col>
                        <Col>
                            Data Abertura: <b> {moment(dadosPdvCaixa.ctx_datahoraabertura).format('DD/MM/YYYY')}</b>
                        </Col>
                        <Col>
                            Hora Abertura: <b>{moment(dadosPdvCaixa.ctx_datahoraabertura).format('HH:mm:ss')}</b>
                        </Col>
                    </Row>
                    <Row gutter={[0, 16]} className="m-t-16">
                        <Col span={24}>
                            <Steps size="small" progressDot current={tagPages}>
                                <Steps.Step title="Movimentação" />
                                <Steps.Step title="Dinheiro" />
                                {/* <Steps.Step title="Pix" /> */}
                                <Steps.Step title="Cartão" />
                                <Steps.Step title="Resumo" />
                            </Steps>
                        </Col>
                        {tagPages === 0 &&
                            <Col span={24}>
                                <MovimentoCaixa listaMovimentos={listaMovimentos} />
                                <CupomCancelados listaCancelados={listaCancelados} />
                            </Col>
                        }
                        {tagPages === 1 &&
                            <Col span={24}>
                                <Dinheiro listaContaCorrente={listaContaCorrente} dadosTransferencia={dadosTransferencia} setDadosTransferencia={setDadosTransferencia} alteraValor={() => alteraValorCaixa()} />
                            </Col>
                        }
                        {/* {tagPages === 2 &&
                            <Col span={24}>
                                <Pix />
                            </Col>
                        } */}
                        {tagPages === 2 &&
                            <Col span={24}>
                                <MovimentacaoCartoes listaMovimentos={listaMovimentosCartoes} dadosPdvCaixa={dadosPdvCaixa} dadosTransferencia={dadosTransferencia} />
                            </Col>
                        }
                        {tagPages === 3 &&
                            <Col span={24}>
                                <Resumo dadosLista={dadosLista} />
                            </Col>
                        }
                    </Row>
                    <Row className="m-t-16">
                        <Col span={12}>
                            <Button icon={<LeftOutlined />} disabled={tagPages === 0} onClick={() => setTagPages(tagPages - 1)}>
                                Voltar
                            </Button>
                        </Col>
                        <Col span={12}>
                            <Row justify="end" gutter={[8, 0]}>
                                {tagPages === 3 && <Col>
                                    <Button type="primary" onClick={() => form.submit()}>
                                        <CheckOutlined /> Concluir
                                    </Button>
                                </Col>}
                                {tagPages < 3 && <Col>
                                    <Button type="primary" disabled={tagPages === 3} onClick={() => setTagPages(tagPages + 1)}>
                                        Avançar <ArrowRightOutlined />
                                    </Button>
                                </Col>}
                            </Row>
                        </Col>
                    </Row>
                </Form>
                <Row className="footer-value">
                    <Col span={6}>
                        <Row className="p-10 b-r-1">
                            <Col span={24}>
                                Valor Abertura
                            </Col>
                            <Col span={24} className="text-right over-text">
                                <b className="f-16">R$ {parseFloat(dadosPdvCaixa.ctx_valorabertura).toFixed(2)}</b>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Row className="p-10 b-r-1">
                            <Col span={24}>
                                Entradas
                            </Col>
                            <Col span={24} className="text-right over-text">
                                <b className="f-16">R$ {parseFloat(totais.entrada).toFixed(2)}</b>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Row className="p-10 b-r-1">
                            <Col span={24}>
                                Saídas
                            </Col>
                            <Col span={24} className="text-right over-text">
                                <b className="f-16">R$ {parseFloat(totais.saida).toFixed(2)}</b>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={6}>
                        <Row className="p-10">
                            <Col span={24}>
                                Saldo de Caixa
                            </Col>
                            <Col span={24} className="text-right over-text">
                                <b className="f-16">R$ {parseFloat((dadosPdvCaixa.ctx_valorabertura + totais.entrada) - totais.saida).toFixed(2)}</b>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}