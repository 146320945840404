import React, { useEffect, useState } from "react";
import { Row, Col, Modal, Table, Form, notification, Input } from "antd";
import { ExclamationCircleOutlined, SaveOutlined, CloseOutlined, CheckOutlined } from "@ant-design/icons";

import api from "../../../services/api";

export default function ModalContaCorrente({ exibirModalContaCorrente, fecharModalContaCorrenteOk, fecharModalContaCorrente, contaCorrente, listaContaCorrente, tipoConta }) {

    const [linhaPintada, setLinhaPintada] = useState(0);
    const [searchTerm, setSearchTerm] = useState("");
    const [searchResults, setSearchResults] = useState([]);
    const handleChange = event => {
        setSearchTerm(event.target.value);
    };

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalContaCorrente();
            }
        });
    };

    const salvarModalContaCorrente = () => {
        if (!!contaCorrente.contaCorrente && JSON.stringify(contaCorrente.contaCorrente) !== '{}') {
            fecharModalContaCorrenteOk();
        } else {
            notification.warning({ message: 'Aviso', description: 'Favor selecionar uma conta corrente' })
        }
    };

    useEffect(() => {
        if (exibirModalContaCorrente) {
            listarContaCorrente();
        }
    }, [exibirModalContaCorrente]);

    function listarContaCorrente() {
        api.get(`ContaCorrente/AtivosTipoConta?Ativo=true&tipoConta=${tipoConta}&PageSize=500&filtro=`).then(
            res => {
                if (res.status === 200) {
                    res.data.items.sort(function (x, y) {
                        let a = x.ctc_descricao.toUpperCase(),
                            b = y.ctc_descricao.toUpperCase();
                        return a === b ? 0 : a > b ? 1 : -1;
                    }
                    )
                    listaContaCorrente.setListaContaCorrente(res.data.items);
                    setSearchResults(res.data.items.sort());
                }
            }
        ).then(
            erro => {
                console.log(erro);
            }
        );
    };

    useEffect(() => {
        if (!!listaContaCorrente.listaContaCorrente) {
            if (listaContaCorrente.listaContaCorrente.length === 1) {
                contaCorrente.setContaCorrente(listaContaCorrente.listaContaCorrente[0]);
            }
        }
    }, [listaContaCorrente.listaContaCorrente]);

    function selecionarContaCorrente(record, rowIndex) {
        contaCorrente.setContaCorrente(record);
        setLinhaPintada(record.ctc_id);
    };

    useEffect(() => {
        if (listaContaCorrente.listaContaCorrente.length > 0) {
            const results = listaContaCorrente.listaContaCorrente.filter(cc => cc.ctc_descricao.toLowerCase().includes(searchTerm));
            setSearchResults(results);
        }
    }, [searchTerm])

    return (
        <Modal centered
            title="Conta Corrente"
            open={exibirModalContaCorrente}
            getContainer={false}
            onCancel={onCloseModal}
            onOk={salvarModalContaCorrente}
            okText={
                <>
                    <SaveOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
        >
            <Form layout="vertical">
                <Row>
                    <Col span={24}>
                        <Form.Item label="Pesquisar por">
                            <Input placeholder="Código, Descrição" name="searchTerm" onChange={handleChange} />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <div className="tabela check-list">
                <Table
                    columns={
                        [
                            {
                                title: 'Informações das Contas Corrente',
                                render: ({ ctc_descricao, ctc_id }) => (
                                    <Row align="middle" gutter={[5, 0]} className="p-5-8 cursor-pointer" style={{ backgroundColor: (ctc_id === linhaPintada ? '#c5d1dd' : 'transparent') }}>
                                        {(ctc_id === linhaPintada) &&
                                            <Col>
                                                <CheckOutlined className="f-16" />
                                            </Col>
                                        }
                                        <Col>
                                            <b>{ctc_descricao}</b>
                                        </Col>
                                    </Row>
                                ),
                            }
                        ]
                    }
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: () => {
                                selecionarContaCorrente(record, rowIndex);
                            }
                        }
                    }}
                    dataSource={searchResults}
                />
            </div>
        </Modal>
    );
}