import React from "react";
import { Row, Col, Descriptions, Form, Input, Modal, notification } from "antd";
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import DetalheNotaFiscal from "../../detalheNotaFiscal";

export default function ModalEstornoNfe({ detalhesNf, exibirModal, fecharModal }) {
    const [formulario] = Form.useForm();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                formulario.resetFields();
                fecharModal();
            }
        });
    };

    function salvar(valores) {
        let dados = detalhesNf;
        dados.justificativa = formulario.getFieldValue().justificativa;
        api.post(`NotaFiscal/Estorno`, dados).then(
            res => {
                if (res.data.protNFe[0].infProt.cStat === 100) {
                    notification.success({ description: `NF-e Nº: ${detalhesNf.ntf_numero} estorna com sucesso!`, message: 'Aviso' });
                } else if (res.data.protNFe[0].infProt.cStat != 100) {
                    notification.success({ description: res.data.protNFe[0].infProt.xMotivo, message: 'Aviso' });
                }
            }
        ).catch(
            erro => {
                notification.warning({ description: 'Ocorreu um erro ao tentar estornar a nota fiscal.', message: 'Aviso' });
            }
        ).finally(
            () => {
                fecharModal();
            }
        )
    };

    return (
        <Modal centered
            title="Estorno de NF-e"
            open={exibirModal}
            getContainer={false}
            onCancel={onCloseModal}
            okText={
                <>
                    <CheckOutlined /> Realizar o Estorno
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            } 
            maskClosable={false}
            onOk={salvar}
            destroyOnClose
        >
            <div>
                <Form layout="vertical" form={formulario} name="formEstornoNfe">
                    <Row justify="center">
                        <Col>
                            <Descriptions size="small">
                                <Descriptions.Item>
                                    ATENÇÃO: <br />
                                    O prazo legal para o Cancelamento de um Nota Fiscal Eletrônica é de 168 horas. <br />
                                    A Nota Fiscal selecionada ultrapassou este prazo, de modo que, apenas é permitido seu Estorno.
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <DetalheNotaFiscal detalhesNf={detalhesNf} />
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Motivo do Estorno" name="justificativa">
                                <Input.TextArea placeholder="Informe aqui o motivo do Estorno da Nf-e" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}