import React from "react";
import { Row, Col, Form, Input, Checkbox, Modal } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";
import InputPhone from "../../inputPhone";

export default function ModalOrgaoSubjacente({ exibirModalOrgaoSubjacente, fecharModalOrgaoSubjacente }) {

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            zIndex: 2010,
            centered: true,
            onOk() {
                fecharModalOrgaoSubjacente();
            }
        });
    };

    const onFinish = values => {
        console.log('Success:', values);
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Modal centered
            title="Cadastro de Órgãos Subjacentes"
            open={exibirModalOrgaoSubjacente}
            getContainer={false}
            onCancel={onCloseModal}
            okText={
                <>
                    <PlusOutlined /> Adicionar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            onOk={onCloseModal}
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="manutencaoOrgaoSubjacente" initialValues={{ remember: true }} onFinish={onFinish} onFinishFailed={onFinishFailed}>
                    <Row gutter={[8, 8]}>
                        <Col span={12}>
                            <Form.Item label="Nome" name="pop_nome" rules={[{ required: true, message: 'Informe o nome do Órgão Público.' }]}>
                                <Input placeholder="Informe o nome do Órgão Público" />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="E-mail" name="pop_email">
                                <Input placeholder="Informe o E-mail" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                        <Col span={8}>
                            <InputPhone label="Telefone" name="pop_telefone" placeholder="Informe o Número do telefone" />
                        </Col>
                        <Col span={8}>
                            <Form.Item label="CEP" name="pop_cep">
                                <Input placeholder="Informe o CEP" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Cidade" name="pop_cidade">
                                <Input placeholder="Informe a Cidade" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <Form.Item label="Endereço" name="pop_endereco">
                                <Input placeholder="Informe o Endereço" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                        <Col span={8}>
                            <Form.Item label="Bairro" name="pop_bairro">
                                <Input placeholder="Informe o Bairro" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Número" name="pop_numero">
                                <Input placeholder="Informe o Número" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Complemento" name="pop_complemento">
                                <Input placeholder="Informe o Complemento" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[8, 8]}>
                        <Col span={24}>
                            <Form.Item label="Local de Referência" name="pop_localreferencia">
                                <Input.TextArea placeholder="Informe o Local de Referência" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[8, 8]}>
                        <Col span={24}>
                            <Form.Item label="Observações" name="pop_observacao">
                                <Input.TextArea placeholder="Observações" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row align="middle" gutter={[8, 8]}>
                        <Col span={24}>
                            <Form.Item name="pem_ativo">
                                <Checkbox>Ativo?</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}