import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, Modal, notification } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { FormGW, SelectPaginacao } from "../../../components";
import { listaSelectPaginadoActions } from "../../../actions";
import { useStateValue } from "../../../state";
import { MaskedInput } from "antd-mask-input";

export default function ModalEndereco({ form, listaEndereco, exibirModalEndereco, fecharModalEndereco, exibirPais = true }) {

    const [{ manutencao }, dispatch] = useStateValue();
    const [cidade, setCidade] = useState({});

    async function onFinish(values) {

        if (values.log_logradouro.length <= 3) {
            notification.warning({ message: 'Aviso!', description: 'Logradouro deve conter mais de 3 caracteres!' });
            return false;
        }
        let lista = [...listaEndereco.dadosEndereco];
        let indice = values.indice;
        delete values.indice;
        values.cep_cep = values.cep_cep.replace(/\D/g, '');
        values.cep_id = cidade.cep_id;
        values.cid_descricao = !!values.cid_nome ? values.cid_nome : form.getFieldValue().cid_descricao;
        if (listaEndereco.dadosEndereco.length === 0) {
            values.pee_enderecoprincipal = true;
        }
        values.pee_ativo = true;
        values.uf = values.est_sigla;
        values.logradouro = values.log_logradouro;
        values.enderecoCompleto = values.log_logradouro + ',' + values.pee_numero + '-' + values.bai_nome + '-' + values.pee_complemento + '-' + values.cep_cep + '-' + values.cid_descricao + '-' + values.est_sigla;
        if (indice >= 0) {
            lista[indice] = values;
        } else {
            lista.push(values);
        }
        values.pais = await listarPais(values.pse_id);
        listaEndereco.setDadosEndereco(lista);
        form.resetFields();
        fecharModalEndereco();
    };

    async function listarPais(pseId) {
        let req = await api.get(`Pais/BuscarPorId/${pseId}`);
        if (req.status === 200 && !!req.data) {
            return req.data;
        }
        return {};
    }

    const verificaCep = (cep) => {
        cep = cep?.replaceAll(/\D/g, '');
        if (!!cep) {
            api.get(`Cidade/BuscaCidadeCep?cep=${cep}`).then(
                (res) => {
                    if (res.status === 200) {
                        form.setFieldsValue({ cid_descricao: res.data.length > 0 ? res.data[0].cid_descricao : '' })
                        setCidade(res.data[0]);
                        form.setFieldsValue({ est_sigla: res.data.length > 0 ? res.data[0].est_sigla : '' });
                        form.setFieldsValue({ bai_nome: res.data.length > 0 ? res.data[0].bai_nome : '' });
                        form.setFieldsValue({ log_logradouro: res.data.length > 0 ? res.data[0].log_logradouro : '' });
                    } else {
                        api.get(`Cidade/ListarCidadeCep?cep=${cep.match(/\d+/g).join('')}`).then(
                            (res) => {
                                if (res.status === 200) {
                                    form.setFieldsValue({ cid_descricao: res.data.cidade })
                                    setCidade(res.data[0]);
                                    form.setFieldsValue({ est_sigla: res.data.estado });
                                }
                            })
                    }
                }
            );
        }
    };

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            zIndex: 2010,
            onOk() {
                fecharModalEndereco();
            }
        });
    };

    useEffect(() => {
        let lista = [];
        lista.push({ name: "pse_id", campo: "pse_id", value: '33' });
        dispatch({ type: listaSelectPaginadoActions.CHANGE, data: { itens: lista } });
        form.setFieldsValue({ pse_id: '33' });
    }, [])

    const inputRef = React.createRef();

    useEffect(() => {
        setTimeout(() => {
            if (!!inputRef.current) {
                inputRef.current.focus({ cursor: 'end', })
            };
        }, 500);
    }, [exibirModalEndereco, inputRef.current])

    return (
        <Modal centered
            width={700}
            title="Cadastro de Endereços"
            open={exibirModalEndereco}
            getContainer={false}
            onCancel={() => {
                form.resetFields();
                onCloseModal();
            }}
            onOk={() => form.submit()}
            okText={
                <>
                    <PlusOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            destroyOnClose
        >
            <div>
                <FormGW layout="vertical" name="manutencaoEndereco" form={form} onFinish={onFinish} >
                    <Form.Item name="indice" hidden />
                    <Form.Item name="pee_enderecoprincipal" hidden />
                    <Form.Item name="pee_ativo" hidden />
                    <Row gutter={[8, 0]}>
                        {exibirPais && <Col xs={24} sm={5} md={4} lg={4} xl={4}>
                            <Form.Item label="País" name="pse_id">
                                <SelectPaginacao url="Pais/Listar" placeholder="Informe o País" form={form} selecionarRegUnico="pse_id" nameValue="pse_id" nameLabel="pse_nome" conteudo={
                                    pse => (<Select.Option value={pse.pse_id} key={pse.key} label={pse.pse_nome}>{pse.pse_nome}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>}
                        <Col xs={24} sm={6} md={4} lg={4} xl={4}>
                            <Form.Item label="CEP" name="cep_cep" rules={[{ required: true, message: 'Informe o CEP' }]}>
                                <MaskedInput
                                    mask={'00000-000'}
                                    ref={inputRef}
                                    className="ant-input ant-input-sm"
                                    placeholder="Informe o CEP"
                                    id="cep_cep"
                                    onBlur={(cep) => verificaCep(cep.target.value)}
                                    onPressEnter={(cep) => verificaCep(cep.target.value)}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={exibirPais ? 18 : 10} sm={exibirPais ? 9 : 10} md={exibirPais ? 5 : 17} lg={exibirPais ? 5 : 17} xl={exibirPais ? 5 : 17}>
                            <Form.Item label="Cidade" name="cid_descricao">
                                <Input disabled placeholder="Informe a Cidade" />
                            </Form.Item>
                        </Col>
                        <Col xs={6} sm={4} md={2} lg={2} xl={2}>
                            <Form.Item label="Estado" name="est_sigla">
                                <Input disabled placeholder="UF" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={9} lg={9} xl={9}>
                            <Form.Item label="Endereço" name="log_logradouro" rules={[{ required: true, message: 'Informe o Endereço' }]}>
                                <Input min={3} placeholder="Informe o Endereço" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={6} lg={6} xl={6}>
                            <Form.Item label="Bairro" name="bai_nome" rules={[{ required: true, message: 'Informe o Bairro.' }]}>
                                <Input placeholder="Informe o Bairro" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={5} lg={5} xl={5}>
                            <Form.Item label="Número" name="pee_numero">
                                <Input placeholder="Informe o Número" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={5} lg={5} xl={5}>
                            <Form.Item label="Complemento" name="pee_complemento">
                                <Input placeholder="Informe o Complemento" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item label="Tipo de Endereço" name="tpe_id" rules={[{ required: true, message: 'Tipo de Endereço' }]}>
                                <SelectPaginacao url="TipoEndereco/Listar" placeholder="Informe o Tipo de Endereço" form={form} nameValue="tpe_id" nameLabel={"tpe_descricao"} conteudo={
                                    te => (<Select.Option value={te.tpe_id} key={te.tpe_id} label={te.tpe_descricao}>{te.tpe_descricao}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Local de Referência" name="pee_localreferencia">
                                <Input.TextArea placeholder="Informe o Local de Referência" />
                            </Form.Item>
                        </Col>
                    </Row>
                </FormGW>
            </div>
        </Modal>
    );
}