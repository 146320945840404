import React, { useEffect } from 'react';
import { Form } from "antd";

import { useStateValue } from '../../state';
import { setTela } from "../../services/auth";

function FormGW({ initialValues, layout, form, name, onFinish, campoId, children }) {

    const [{ ui }] = useStateValue();
    const gravaLocalStorage = () => {
        //localStorage.setItem(name, JSON.stringify(allValues));
        let dados = {};
        let url = window.document.location.href;
        dados = form.getFieldValue();
        dados.formulario = url.substring(url.lastIndexOf('/'), url.length);
        dados.editar = false;
        setTela(JSON.stringify(dados));
    }
    const listaInputHidden = [];

    function autoTab(event) {
        if ((event.keyCode === 13 || event.keyCode === 9) || (!!event.which && (event.which === 13 || event.which === 9))) {
            if (!!event.target.form) {
                for (let key in event.target.form.elements) {
                    if (!!event.target.form.elements[key].setAttribute && parseInt(key) >= 0) {
                        event.target.form.elements[key].setAttribute('index', key);
                    }
                }
                let index = parseInt(event.target.getAttribute('index'));
                if (!!event.target.form.elements[index + 1]) {
                    if (event.target.form.elements[index + 1].disabled || event.target.form.elements[index + 1].hidden) {
                        let posicao = validarCampoValido(event, index, 2);
                        event.target.form.elements[index + posicao].focus();
                    } else {
                        event.target.form.elements[index + 1].focus();
                    }
                }
            }
        }
    };

    function validarCampoValido(event, index, aux) {
        if (!!event.target.form.elements[index + aux]) {
            if (event.target.form.elements[index + aux].disabled) {
                aux += 1;
                validarCampoValido(event, index, aux);
            } else {
                return aux;
            }
        } else {
            return aux - 1;
        }
    };

    useEffect(() => {
        if (!!form.getFieldsValue() && ui.showDrawer) {
            for (let campo in form.getFieldsValue()) {
                listaInputHidden.push({ campo: campo, hidden: false });
            }
            popularListaInputHidden();
            if (!verificarJaPossuiFocus()) {
                listaInputHidden.forEach((inputHidden, index, array) => {
                    if (!inputHidden.hidden && !!inputHidden.campo) {
                        let input = form.getFieldInstance(inputHidden.campo);
                        if (!!input && !!input.props && !input.props.disabled) {
                            setTimeout(() => {
                                try {
                                    input.focus();
                                } catch {
                                    return;
                                }
                            }, 500);
                            array.length = index + 1;//break the loop
                        }
                    }
                });
            }
        }
    }, [ui.showDrawer]);

    function popularListaInputHidden() {
        let formItemHidden = !!document.querySelector('.ant-drawer') ? document.querySelector('.ant-drawer').querySelectorAll('.ant-form-item-hidden') : null;
        if (!!formItemHidden) {
            listaInputHidden.forEach((inputHidden, index, array) => {
                formItemHidden.forEach(itemHidden => {
                    if (!!itemHidden.querySelector('input') && itemHidden.querySelector('input').id.indexOf(inputHidden.campo) > -1) {
                        listaInputHidden[index].hidden = true;
                    }
                });
            });
        }
    };

    function verificarJaPossuiFocus() {
        let retorno = false;
        listaInputHidden.forEach((inputHidden, index, array) => {
            let elementos = document.querySelector('.ant-drawer').querySelectorAll('input');
            let input = Array.prototype.filter.call(elementos, (test) => { return test.id.indexOf(inputHidden.campo) > -1 })[0]
            if (input === document.activeElement) {
                retorno = true;
            }
        });
        return retorno;
    };

    /*let dadosForm = localStorage.getItem(name);
    if (dadosForm) {
        if (dadosForm.includes(campoId)) {
            dadosForm = JSON.parse(dadosForm)
            if (dadosForm[campoId] === form.getFieldValue(campoId) ) form.setFieldsValue(dadosForm);
        } else {
            form.setFieldsValue(JSON.parse(dadosForm));
        }
    }*/
    return (
        <Form layout={layout} initialValues={initialValues} form={form} name={name} onFinish={onFinish} onKeyPress={autoTab} onValuesChange={(valor, allValues) => gravaLocalStorage(allValues)}>
            {/*children?children.props.children.map(child => child):''*/}
            {children}
        </Form>
    )

}
export default FormGW;