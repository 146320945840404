import React from 'react';
import { Row, Col, Table } from "antd";
import moment from 'moment';

import { FormatNumber } from '../../ValueObjects';

export default function TabDetalheIFormaPagamento({ formaPagamento }) {

    const expandedRowRender = record => {
        return <Table dataSource={record.parcelas} columns={
            [
                {
                    title: '',
                    render: ({ prc_numeroparcela, prc_datavencimento, prc_valor }) => (
                        <div>
                            <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                <Col>
                                    Nº: <b>{prc_numeroparcela}</b>
                                </Col>
                                <Col>
                                    Vencimento: <b>{!!prc_datavencimento ? moment(prc_datavencimento).format('DD/MM/YYYY') : null}</b>
                                </Col>
                                <Col>
                                    Valor (R$): <b>{FormatNumber(!!prc_valor ? prc_valor : 0, true)}</b>
                                </Col>
                            </Row>
                        </div>
                    ),
                },
            ]
        } pagination={false} />
    };

    const rowExpandable = record => { return record.fpg_tipopagamento === 5 };

    return (
        <div className="tabela modalTable">
            <Table dataSource={formaPagamento} showHeader={true} columns={
                [
                    {
                        title: 'Forma/Condição Pagto',
                        render: ({ fpg_descricao, cpg_descricao }) => (
                            <div>
                                <Row align="middle" justify="space-between" gutter={[8, 0]}>
                                    <Col span={16}>
                                        <b>{fpg_descricao + (!!cpg_descricao ? '/ ' + cpg_descricao : '')}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Valor Troco',
                        align: 'right',
                        render: ({ nfp_valortroco }) => (
                            <div>
                                <Row align="right" justify='end' gutter={[8, 0]}>
                                    <Col span={8} align="right">
                                        <b>R$ {FormatNumber(!!nfp_valortroco ? nfp_valortroco : 0, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                    {
                        title: 'Valor Líquido',
                        align: 'right',
                        render: ({ nfp_valor, nfp_valortroco }) => (
                            <div>
                                <Row align="right" justify='end' gutter={[8, 0]}>
                                    <Col span={8} align="right">
                                        <b>R$ {FormatNumber(!!nfp_valor ? (nfp_valor - nfp_valortroco) : 0, true)}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]
            } pagination={false} expandable={{ expandedRowRender, rowExpandable }} />

        </div >
    );
}