import React, { useEffect } from "react";
import { Layout, Modal } from "antd";
import { Route, HashRouter, Routes, BrowserRouter, Outlet, useNavigate } from "react-router-dom";

import api from "../../services/api";
import SemAcesso from "../semAcesso";
import { useStateValue } from "../../state";
import { HeaderPage, MenuPage } from "../../components";
import { parametrosEmpresaActions, tributacaoActions } from "../../actions";
import { setPermissoes, setStatusPlano, setNavegacao, getEmpresa, setParametrosEmpresa } from "../../services/auth";
import { Login, Resumo, Venda, Clientes, FinalizarVenda, VendaRealizada, DevolucaoVenda, Parcelas, ParametrosEmpresa } from "../";
import { Content } from "antd/es/layout/layout";

export default function Home() {

    const [{ }, dispatch] = useStateValue();
    const navigation = useNavigate();

    function buscarParametros() {
        api.get('ParametroEmpresa/BuscarParametros').then(
            res => {
                if (res.status === 200) {
                    dispatch({ type: parametrosEmpresaActions.CHANGE, data: { markupProduto: res.data.par_markupproduto, par_diastoleranciacobrancajuro: res.data.par_diastoleranciacobrancajuro, par_percjuromesparcelavencida: res.data.par_percjuromesparcelavencida } });
                    setParametrosEmpresa(res.data);
                }
            }
        ).catch(
            (erro) => {
                console.error(erro);
            }
        );
    }

    async function empresaUsuario() {
        let dadosEmpresa = (await api.get(`Empresa/Buscar/${getEmpresa()}`)).data
        dispatch({ type: tributacaoActions.CHANGE, data: { empRegimeTributario: dadosEmpresa.emp_regimetributario, empEnquadramentoFiscal: dadosEmpresa.emp_enquadramentofiscal } });
    }

    useEffect(() => {
        empresaUsuario();
        resetarNav();
        buscarParametros();
        buscarPermissoesUsuario();
        buscarStatusPlano();
    }, []);

    function resetarNav() {
        if (!!!localStorage.getItem('@GestorWeb-Navegacao')) {
            setNavegacao({ geral: {} });
        }
    }

    function buscarPermissoesUsuario() {
        api.get(`PermissaoUsuario/Listar`).then(
            (res) => {
                setPermissoes(res.data);
            }
        ).catch(
            error => {
                if (error.response.status === 426) {
                    Modal.warn({
                        title: error.response.data
                    });
                    navigation("semAcesso");
                    //window.location = 'Home#/semAcesso'
                } else {
                    console.log(error);
                }
            }
        )
    }

    function buscarStatusPlano() {
        api.get(`LocalPlano/VerificarStatusPlano`).then(
            res => {

            }
        ).catch(
            error => {
                if (error.response.status === 402) {
                    setStatusPlano('VENCIDO');
                    navigation("renovarPlano");
                    //window.location = '/renovarPlano#'
                }
            }
        )
    }

    return (
        <div className="App">
            <Layout className="vh100">
                <HeaderPage />
                <Layout className="vh100 m-t-55">
                    <MenuPage />
                    <Layout className="site-layout">
                        <Layout.Content>
                            <Routes>
                                <Route path="/login" element={<Login />} />
                                <Route path="/" element={<Resumo />} />
                                <Route path="venda" element={<Venda />} />
                                <Route path="/clientes" element={<Clientes />} />
                                <Route path="finalizarVenda" element={<FinalizarVenda />} />
                                <Route path="/vendaRealizada" element={<VendaRealizada />} />
                                <Route path="/devolucaoVenda" element={<DevolucaoVenda />} />
                                <Route path="/parcelas" element={<Parcelas />} />
                                <Route path="/parametrosEmpresa" element={<ParametrosEmpresa />} />
                                <Route path="/semAcesso" element={<SemAcesso />} />
                            </Routes>
                        </Layout.Content>
                    </Layout>
                </Layout>
            </Layout>
        </div>
    );
}