import React from "react";
import { Row, Col, Table, Tag, Divider } from "antd";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useEffect } from "react";
import { useState } from "react";

export default function Endereco({ dadosEndereco }) {

    const [enderecos, setEnderecos] = useState([]);

    useEffect(() => {
        if (dadosEndereco?.length > 0) {
            carregarEndereco(dadosEndereco);
        }
    }, [dadosEndereco])

    function carregarEndereco(dados) {
        let listaTemp = [];
        let listaAux = [...dados];

        listaAux.forEach((end) => {
            let dadosEndereco = {
                tpe_descricao: end.tpe_descricao,
                endereco: ''
            };
            dadosEndereco.endereco += !!end.log_logradouro ? end.log_logradouro + ', ' : '';
            dadosEndereco.endereco += !!end.bai_nome ? end.bai_nome + ', ' : '';
            dadosEndereco.endereco += !!end.cid_nome ? end.cid_nome + ', ' : '';
            dadosEndereco.endereco += !!end.est_sigla ? end.est_sigla + ', ' : '';
            dadosEndereco.endereco += !!end.cep_cep ? end.cep_cep : '';
            listaTemp.push(dadosEndereco);
        })
        setEnderecos(listaTemp);
    }

    return (
        <Row className="tabela">
            <Col span={24}>
                <Table columns={[
                    {
                        title: 'Endereço(s)',
                        render: ({ tpe_descricao, endereco }) => (
                            <div>
                                <Row align="middle" gutter={[5, 0]}>
                                    <Col>
                                        <Tag color="processing">
                                            <b>{tpe_descricao}</b>
                                        </Tag>
                                    </Col>
                                    <Col>
                                        <b>{endereco}</b>
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} locale={{
                    emptyText: (
                        <Row>
                            <Col span={24}>
                                <Divider orientation="center">
                                    <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                </Divider>
                            </Col>
                        </Row>)
                }} dataSource={enderecos} scroll={{ y: 420 }} pagination={false} />
            </Col>
        </Row>
    );
}