import React, { useEffect, useState } from "react";
import { Row, Col, Form, Input, Select, Modal, notification } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { FormGW, SelectPaginacao } from "../../../components";
import { MaskedInput } from "antd-mask-input";
import { InputPreco } from "../../../../../GestorERP.PDV.Web/src/components";

export default function ModalTransportadora({ dadosCliente, exibirModalTransportadora, fecharModalTransportadora, setFrete, setSwitchState, setEnderecoDestino }) {

    const [cidade, setCidade] = useState({});
    const [form] = Form.useForm();
    const inputRef = React.createRef();

    useEffect(() => {
        if (dadosCliente && Object.keys(dadosCliente).length > 0) {
            let dadosEndereco = dadosCliente?.enderecos ? dadosCliente.enderecos.filter(x => x.pee_enderecoprincipal === true && x.pee_ativo === true)[0] : undefined;
            if (dadosEndereco !== undefined) {
                form.setFieldsValue({
                    cep_cep: dadosEndereco.cep_cep,
                    cid_descricao: dadosEndereco.cid_descricao,
                    est_sigla: dadosEndereco.est_sigla,
                    log_logradouro: dadosEndereco.log_logradouro,
                    bai_nome: dadosEndereco.bai_nome,
                    pee_numero: dadosEndereco.pee_numero,
                    pee_complemento: dadosEndereco.pee_complemento,
                    tpe_id: dadosEndereco.tpe_id,
                    pee_localreferencia: dadosEndereco.pee_localreferencia
                });
            }
        }
    }, [exibirModalTransportadora])

    const salvarDadosFrete = () => {
        let values = form.getFieldValue();
        if (values.log_logradouro !== undefined && values.bai_nome !== undefined && values.cep_cep !== undefined && values.tpe_id !== undefined ) {
            if (values.log_logradouro.length <= 3) {
                notification.warning({ message: 'Aviso!', description: 'Logradouro deve conter mais de 3 caracteres!' });
                return false;
            } else {
                setFrete(values.valorFrete);
                setEnderecoDestino(values);
            }
        } else {
            notification.warning({ message: 'Aviso!', description: 'Informe os campos obrigatórios' });
            return false;
        }
        form.resetFields();
        fecharModalTransportadora();
    };

    // async function buscarDadosTransportador(cpfCnpj) {
    //     try {
    //         const response = await api.get(`Transportador/BuscarTransportardorCpfCnpj/${cpfCnpj}`)
    //         const dadosTransportador = response.data;
    //         form.setFieldsValue({
    //             nomeTransportador: dadosTransportador.pes_nome,
    //         })
    //     } catch (error) {
    //         console.error("Erro:", error);
    //     }
    // }

    // const handleCpfCnpjChange = (event) => {
    //     const cpfCnpj = event.target.value;
    //     if (cpfCnpj.length === 11 || cpfCnpj.length === 14) {
    //         buscarDadosTransportador(cpfCnpj);
    //     }
    // }

    function buscarCep(cep) {
        fetch(`https://viacep.com.br/ws/${cep.replace(`-`, ``)}/json/`)
            .then(
                res => res.json()
            ).then(
                result => {
                    form.setFieldsValue({ cidade: `${result.localidade} / ${result.uf}` });
                },
                erro => {
                    notification.warning({ message: 'Aviso', description: 'Cep não encontrado!' })
                }
            );
    };

    const handleEnderecoChange = (value) => {
        const enderecoSelecionado = dadosCliente.enderecos.find(endereco => endereco.pee_id === value);
        if (enderecoSelecionado) {
            form.setFieldsValue({
                cep_cep: enderecoSelecionado.cep_cep,
                cid_descricao: enderecoSelecionado.cid_descricao,
                est_sigla: enderecoSelecionado.est_sigla,
                log_logradouro: enderecoSelecionado.log_logradouro,
                bai_nome: enderecoSelecionado.bai_nome,
                pee_numero: enderecoSelecionado.pee_numero,
                pee_complemento: enderecoSelecionado.pee_complemento,
                tpe_id: enderecoSelecionado.tpe_id
            });
        }
    };

    const verificaCep = (cep) => {
        cep = cep?.replaceAll(/\D/g, '');
        if (!!cep) {
            api.get(`Cidade/BuscaCidadeCep?cep=${cep}`).then(
                (res) => {
                    if (res.status === 200) {
                        form.setFieldsValue({ cid_descricao: res.data.length > 0 ? res.data[0].cid_descricao : '' })
                        setCidade(res.data[0]);
                        form.setFieldsValue({ est_sigla: res.data.length > 0 ? res.data[0].est_sigla : '' });
                    } else {
                        api.get(`Cidade/ListarCidadeCep?cep=${cep.match(/\d+/g).join('')}`).then(
                            (res) => {
                                if (res.status === 200) {
                                    form.setFieldsValue({ cid_descricao: res.data.cidade })
                                    setCidade(res.data[0]);
                                    form.setFieldsValue({ est_sigla: res.data.estado });
                                }
                            }
                        )
                    }
                }
            );
        }
    };

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalTransportadora();
                form.resetFields();
                setSwitchState(false);
            }
        });
    };

    return (
        <Modal centered
            width={1110}
            title="Frete"
            open={exibirModalTransportadora}
            getContainer={false}
            onCancel={() => {
                onCloseModal();
            }}
            onOk={salvarDadosFrete}
            okText={
                <>
                    <PlusOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            destroyOnClose
        >
            <div>
                <FormGW layout="vertical" name="manutencaoEndereco" form={form} >
                    <Form.Item name="indice" hidden />
                    <Form.Item name="pee_enderecoprincipal" hidden />
                    <Form.Item name="pee_ativo" hidden />
                    <Row gutter={[8, 0]}>
                        <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            <InputPreco
                                label="Informe o valor (R$)"
                                name="valorFrete"
                                // rules={[{ required: true, message: 'Informe o valor (R$)' }]}
                            />
                        </Col>
                        {/* <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                            <Form.Item label="CPF/CNPJ Transportador" name="cpfCnpjTransportador" rules={[{ required: true, message: 'Informe o CPF/CNPJ do Transportador' }]}>
                                <Input
                                    placeholder="000.000.000-00 ou 00.000.000/000-00"
                                    inputMode="numeric"
                                    style={{ textAlign: 'left' }}
                                    onBlur={handleCpfCnpjChange}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <FormItem label="Nome Transportador" name="nomeTransportador" rules={[{ required: true, message: 'Informe o nome do Transportador' }]}>
                                <Input placeholder="Informe o nome do Transportador" />
                            </FormItem>
                        </Col> */}
                        <Col xs={24} sm={12} md={6} lg={6} xl={18}>
                            <Form.Item label="Informe o Transportador" name="trn_id">
                                <SelectPaginacao url="Transportador/ListarTodos" allowClear placeholder="Selecione o Transportador" nameLabel="pes_nome" form={form} nameValue="trn_id" conteudo={
                                    tp => (<Select.Option value={tp.trn_id} label={tp.pes_nome} key={tp.trn_id}>{tp.pes_nome}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={18} md={18} lg={18} xl={18}>
                            <Form.Item label="Endereço" name="ntf_endereco">
                                <Select placeholder="Informe o Endereço" allowClear onChange={(value) => { handleEnderecoChange(value); }} >
                                    {dadosCliente?.enderecos?.map(
                                        (enderecos) => (
                                            <Select.Option
                                                value={enderecos.pee_id} key={enderecos.pee_id}>{enderecos.log_logradouro}{', '}{enderecos.cid_descricao}{' - '}{enderecos.est_sigla}
                                            </Select.Option>
                                        )
                                    )}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={6} lg={6} xl={6}>
                            <Form.Item label="CEP" name="cep_cep" rules={[{ required: true, message: 'Informe o CEP' }]}>
                                <MaskedInput
                                    mask={'00000-000'}
                                    ref={inputRef}
                                    className="ant-input ant-input-sm"
                                    placeholder="Informe o CEP"
                                    id="cep_cep"
                                    onBlur={(cep) => verificaCep(cep.target.value)}
                                    onPressEnter={(cep) => verificaCep(cep.target.value)}
                                    style={{
                                        textAlign: 'left'
                                    }}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={10} sm={10} md={17} lg={17} xl={17}>
                            <Form.Item label="Cidade" name="cid_descricao">
                                <Input disabled placeholder="Informe a Cidade" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={10} md={7} lg={7} xl={7}>
                            <Form.Item label="Estado" name="est_sigla">
                                <Input disabled placeholder="UF" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16} md={12} lg={12} xl={12}>
                            <Form.Item label="Endereço" name="log_logradouro" rules={[{ required: true, message: 'Informe o Endereço' }]}>
                                <Input min={3} placeholder="Informe o Endereço" />

                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
                            <Form.Item label="Bairro" name="bai_nome" rules={[{ required: true, message: 'Informe o Bairro.' }]}>
                                <Input placeholder="Informe o Bairro" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={8} md={4} lg={4} xl={4}>
                            <Form.Item label="Número" name="pee_numero">
                                <Input placeholder="Informe o Número" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item label="Complemento" name="pee_complemento">
                                <Input placeholder="Informe o Complemento" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                            <Form.Item label="Tipo de Endereço" name="tpe_id" rules={[{ required: true, message: 'Tipo de Endereço' }]}>
                                <SelectPaginacao url="TipoEndereco/Listar" placeholder="Informe o Tipo de Endereço" form={form} nameValue="tpe_id" nameLabel={"tpe_descricao"} conteudo={
                                    te => (<Select.Option value={te.tpe_id} key={te.tpe_id} label={te.tpe_descricao}>{te.tpe_descricao}</Select.Option>)
                                } />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item label="Local de Referência" name="pee_localreferencia">
                                <Input.TextArea placeholder="Informe o Local de Referência" />
                            </Form.Item>
                        </Col>
                    </Row>
                </FormGW>
            </div>
        </Modal>
    );
}