import { retornaSoLetras, retornaSoNumeros } from "./funcoes";

export const validarIE = (numeroInscricaoEstadual, ufEstado) => {
    let retornaIEValido = false;
    if (!!numeroInscricaoEstadual && !!ufEstado) {
        switch (ufEstado) {
            case "RS":
                retornaIEValido = (ValidarInscricaoEstadualRS(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "SC":
                retornaIEValido = (ValidarInscricaoEstadualSC(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "PR":
                retornaIEValido = (ValidarInscricaoEstadualPR(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "SP":
                retornaIEValido = (ValidarInscricaoEstadualSP(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "MG":
                retornaIEValido = (ValidarInscricaoEstadualMG(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "RJ":
                retornaIEValido = (ValidarInscricaoEstadualRJ(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "ES":
                retornaIEValido = (ValidarInscricaoEstadualPR(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "BA":
                retornaIEValido = (ValidarInscricaoEstadualBA(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "SE":
                retornaIEValido = (ValidarInscricaoEstadualSE(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "AL":
                retornaIEValido = (ValidarInscricaoEstadualAL(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "PE":
                retornaIEValido = (ValidarInscricaoEstadualPE(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "PB":
                retornaIEValido = (ValidarInscricaoEstadualPB(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "RN":
                retornaIEValido = (ValidarInscricaoEstadualRN(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "PI":
                retornaIEValido = (ValidarInscricaoEstadualPI(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "MA":
                retornaIEValido = (ValidarInscricaoEstadualMA(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "CE":
                retornaIEValido = (ValidarInscricaoEstadualCE(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "GO":
                retornaIEValido = (ValidarInscricaoEstadualGO(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "TO":
                retornaIEValido = (ValidarInscricaoEstadualTO(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "MT":
                retornaIEValido = (ValidarInscricaoEstadualMT(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "MS":
                retornaIEValido = (ValidarInscricaoEstadualMS(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "DF":
                retornaIEValido = (ValidarInscricaoEstadualDF(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "AM":
                retornaIEValido = (ValidarInscricaoEstadualAM(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "AC":
                retornaIEValido = (ValidarInscricaoEstadualAC(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "PA":
                retornaIEValido = (ValidarInscricaoEstadualPA(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "RO":
                retornaIEValido = (ValidarInscricaoEstadualRO(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "RR":
                retornaIEValido = (ValidarInscricaoEstadualRR(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            case "AP":
                retornaIEValido = (ValidarInscricaoEstadualAP(numeroInscricaoEstadual)) ? true : false;
                return retornaIEValido;
            default:
        }
    } else {
        return true;
    }
}


export const ValidarInscricaoEstadualRS = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }

    let pesosMultiplicador = [2, 9, 8, 7, 6, 5, 4, 3, 2];

    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 10) {
        return false;
    }

    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 9);
    let somaIE = 0;
    for (let i = 0; i < 9; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let resto = somaIE % 11;
    resto = (11 - resto);
    let digitoVerificador = (resto === 10 || resto === 11) ? 0 : resto;

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualSC = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }

    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 9) {
        return false;
    }
    let pesosMultiplicador = [9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 8);
    let somaIE = 0;
    for (let i = 0; i < 8; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }

    let resto = somaIE % 11;
    if (resto > 1) {
        resto = (11 - resto);
    }
    let digitoVerificador = resto;

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualPR = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 10) {
        return false;
    }
    let pesosMultiplicadorPrimeiro = [3, 2, 7, 6, 5, 4, 3, 2];
    let pesosMultiplicadorSegundo = [4, 3, 2, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 8);
    let somaIE = 0;
    for (let i = 0; i < 8; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicadorPrimeiro[i];
    }
    let resto = somaIE % 11;
    let primeiroDigitoVerificador = (resto === 0 || resto === 1) ? 0 : (11 - resto);

    somaIE = 0;
    resto = 0;
    let inscricaoEstadualComPrimeiroDigitoVerificador = inscricaoEstadualSemDigitoVerificador + "" + primeiroDigitoVerificador;
    for (let i = 0; i < 9; i++) {
        somaIE += inscricaoEstadualComPrimeiroDigitoVerificador[i] * pesosMultiplicadorSegundo[i];
    }
    resto = somaIE % 11;
    let segundoDigitoVerificador = (resto === 0 || resto === 1) ? 0 : (11 - resto);

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + primeiroDigitoVerificador + "" + segundoDigitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualSP = (numeroInscricao) => {
    let NumeroInscricao = numeroInscricao;
    let nInscricao = NumeroInscricao.substr(0, 1);
    if (nInscricao === "P") {
        NumeroInscricao = retornaSoNumeros(NumeroInscricao);
        if (NumeroInscricao.length !== 12) {
            return false;
        }
        let pesosMultiplicador = [1, 3, 4, 5, 6, 7, 8, 10];
        let inscricaoEstadualSemDigitoVerificador = NumeroInscricao.substr(0, 8);
        let somaIE = 0;
        for (let i = 0; i < 8; i++) {
            somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
        }
        let resto = somaIE % 11;
        let digitoVerificador = (resto > 9) ? 0 : resto;

        return NumeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador + "" + NumeroInscricao.substr(9, 3)) ? true : false;
    } else {
        let apenasNumeros = (retornaSoLetras(!!NumeroInscricao ? NumeroInscricao : numeroInscricao) == "") ? true : false;
        if (!apenasNumeros) {
            return false;
        }
        let NumeroInscricao = retornaSoNumeros(!!NumeroInscricao ? NumeroInscricao : numeroInscricao);
        if (NumeroInscricao.length !== 12) {
            return false;
        }

        let pesosMultiplicadorPrimeiro = [1, 3, 4, 5, 6, 7, 8, 10];
        let pesosMultiplicadorSegundo = [3, 2, 10, 9, 8, 7, 6, 5, 4, 3, 2];
        let inscricaoEstadualSemDigitoVerificador = NumeroInscricao.substr(0, 8);
        let somaIE = 0;
        for (let i = 0; i < inscricaoEstadualSemDigitoVerificador.length; i++) {
            somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicadorPrimeiro[i];
        }

        let resto = somaIE % 11;
        let primeiroDigitoVerificador = (resto > 9) ? 0 : resto;
        let inscricaoEstadualComPrimeiroDigitoVerificador = inscricaoEstadualSemDigitoVerificador + "" + primeiroDigitoVerificador + "" + NumeroInscricao.substr(9, 2);
        somaIE = 0;
        resto = 0;
        for (let i = 0; i < 11; i++) {
            somaIE += inscricaoEstadualComPrimeiroDigitoVerificador[i] * pesosMultiplicadorSegundo[i];
        }

        resto = somaIE % 11;
        let segundoDigitoVerificador = (resto > 9) ? 0 : resto;

        return (NumeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + primeiroDigitoVerificador + "" + NumeroInscricao.substr(9, 2) + "" + segundoDigitoVerificador)) ? true : false;
    }
};

export const ValidarInscricaoEstadualMG = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 13) {
        return false;
    }
    let pesosMultiplicadorPrimeiro = [1, 2, 1, 2, 1, 2, 1, 2, 1, 2, 1, 2];
    let pesosMultiplicadorSegundo = [3, 2, 11, 10, 9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 3) + "0" + numeroInscricao.substr(3, 8);
    let somaIE = 0;
    let listaDigitosValorMultiplicacao = [];
    for (let i = 0; i < 12; i++) {
        let valorMultiplicacao = inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicadorPrimeiro[i];
        listaDigitosValorMultiplicacao.push(valorMultiplicacao);
    }
    for (let x = 0; x < listaDigitosValorMultiplicacao.length; x++) {
        let numero = listaDigitosValorMultiplicacao[x].toString();
        if (numero.length > 1) {
            let valor1 = numero.substr(0, 1);
            let valor2 = numero.substr(1, 1);
            numero = parseInt(valor1) + parseInt(valor2);
        }
        somaIE += parseInt(numero);
    }
    let primeiraDezenaExata = 0;
    let somaIEString = somaIE.toString();
    primeiraDezenaExata = somaIEString.substr(0, 1);
    primeiraDezenaExata = parseInt(primeiraDezenaExata) + 1;
    primeiraDezenaExata = primeiraDezenaExata.toString() + 0;
    primeiraDezenaExata = parseInt(primeiraDezenaExata);

    primeiraDezenaExata = (somaIE < 10) ? 10 : primeiraDezenaExata;

    let primeiroDigitoVerificador = primeiraDezenaExata - somaIE;

    somaIE = 0;
    let inscricaoEstadualComPrimeiroDigitoVerificador = numeroInscricao.substr(0, 11) + "" + primeiroDigitoVerificador;
    for (let i = 0; i < 12; i++) {
        somaIE += inscricaoEstadualComPrimeiroDigitoVerificador[i] * pesosMultiplicadorSegundo[i];
    }
    let resto = somaIE % 11;
    resto = (resto === 0 || resto === 1) ? 0 : (11 - resto);
    let segundoDigitoVerificador = resto;

    return (numeroInscricao === (numeroInscricao.substr(0, 11) + "" + primeiroDigitoVerificador + "" + segundoDigitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualRJ = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) == "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 8) {
        return false;
    }
    let pesosMultiplicador = [2, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 7);
    let somaIE = 0;
    for (let i = 0; i < 7; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let resto = somaIE % 11;
    resto = (resto <= 1) ? 0 : (11 - resto);
    let digitoVerificador = resto;

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualES = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 9) {
        return false;
    }
    let pesosMultiplicador = [9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 8);
    let somaIE = 0;
    for (let i = 0; i < 8; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let resto = somaIE % 11;
    resto = (resto <= 1) ? 0 : (11 - resto);
    let digitoVerificador = resto;

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualBA = (numeroInscricao) => {
    if (numeroInscricao.length === 8) {
        let apenasNumeros = (retornaSoLetras(numeroInscricao) == "") ? true : false;
        if (!apenasNumeros) {
            return false;
        }
        let numeroInscricao = retornaSoNumeros(numeroInscricao);
        if (numeroInscricao.length !== 8) {
            return false;
        }

        let pesosMultiplicadorPrimeiro = [7, 6, 5, 4, 3, 2];
        let pesosMultiplicadorSegundo = [8, 7, 6, 5, 4, 3, 2];
        let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 6);
        let somaIE = 0;
        for (let i = 0; i < 6; i++) {
            somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicadorPrimeiro[i];
        }
        let primeiroDigitoVerificador = 0;
        let resto = 0;
        if (numeroInscricao.substr(0, 1) === "6" || numeroInscricao.substr(0, 1) === "7" || numeroInscricao.substr(0, 1) === "9") {
            resto = somaIE % 11;
            resto = (11 - resto);
            primeiroDigitoVerificador = (resto === 0 || resto === 1) ? 0 : resto;
        } else {
            resto = somaIE % 10;
            resto = (10 - resto);
            primeiroDigitoVerificador = (resto === 0) ? 0 : resto;
        }

        let inscricaoEstadualComPrimeiroDigitoVerificador = inscricaoEstadualSemDigitoVerificador + "" + primeiroDigitoVerificador;
        somaIE = 0;
        resto = 0;
        for (let i = 0; i < 7; i++) {
            somaIE += inscricaoEstadualComPrimeiroDigitoVerificador[i] * pesosMultiplicadorSegundo[i];
        }
        let segundoDigitoVerificador = 0;
        if (numeroInscricao.substr(0, 1) === "6" || numeroInscricao.substr(0, 1) === "7" || numeroInscricao.substr(0, 1) === "9") {
            resto = somaIE % 11;
            segundoDigitoVerificador = (resto === 0 || resto === 1) ? 0 : (11 - resto);
        } else {
            resto = somaIE % 10;
            segundoDigitoVerificador = (resto === 0) ? 0 : (10 - resto);
        }

        return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + segundoDigitoVerificador + "" + primeiroDigitoVerificador)) ? true : false;
    } else {
        let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
        if (!apenasNumeros) {
            return false;
        }
        if (numeroInscricao.length !== 8) {
            let pesosMultiplicadorPrimeiro = [8, 7, 6, 5, 4, 3, 2];
            let pesosMultiplicadorSegundo = [9, 8, 7, 6, 5, 4, 3, 2];
            let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 7);
            let somaIE = 0;
            for (let i = 0; i < 7; i++) {
                somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicadorPrimeiro[i];
            }
            let primeiroDigitoVerificador = 0;
            let resto = 0;
            if (numeroInscricao.substr(1, 1) === "6" || numeroInscricao.substr(1, 1) === "7" || numeroInscricao.substr(1, 1) === "9") {
                resto = somaIE % 11;
                primeiroDigitoVerificador = (resto === 0) ? 0 : (11 - resto);
            } else {
                resto = somaIE % 10;
                primeiroDigitoVerificador = (resto === 0) ? 0 : (10 - resto);
            }

            let inscricaoEstadualComPrimeiroDigitoVerificador = inscricaoEstadualSemDigitoVerificador + "" + primeiroDigitoVerificador;
            somaIE = 0;
            resto = 0;
            for (let i = 0; i < 8; i++) {
                somaIE += inscricaoEstadualComPrimeiroDigitoVerificador[i] * pesosMultiplicadorSegundo[i];
            }
            let segundoDigitoVerificador = 0;
            if (numeroInscricao.substr(1, 1) === "6" || numeroInscricao.substr(1, 1) === "7" || numeroInscricao.substr(1, 1) === "9") {
                resto = somaIE % 11;
                segundoDigitoVerificador = (resto === 0 || resto === 1) ? 0 : (11 - resto);
            }
            else {
                resto = somaIE % 10;
                segundoDigitoVerificador = (resto === 0 || resto === 1) ? 0 : (10 - resto);
            }

            return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + segundoDigitoVerificador + "" + primeiroDigitoVerificador)) ? true : false;
        }

    }
};

export const ValidarInscricaoEstadualSE = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 9) {
        return false;
    }
    let pesosMultiplicador = [9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 8);
    let somaIE = 0;
    for (let i = 0; i < 8; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let resto = somaIE % 11;
    resto = (11 - resto);
    let digitoVerificador = (resto === 10 || resto === 11) ? 0 : resto;

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualAL = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 9) {
        return false;
    }
    let pesosMultiplicador = [9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 8);
    let somaIE = 0;
    for (let i = 0; i < 8; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let resto = somaIE % 11;
    resto = (11 - resto);
    let digitoVerificador = (resto === 10) ? 0 : resto;

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualPE = (numeroInscricao) => {
    if (retornaSoNumeros(numeroInscricao).length === 9) {
        let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
        if (!apenasNumeros) {
            return false;
        }
        let numeroInscricao = retornaSoNumeros(numeroInscricao);
        if (numeroInscricao.length !== 9) {
            return false;
        }
        let pesosMultiplicadorPrimeiro = [8, 7, 6, 5, 4, 3, 2];
        let pesosMultiplicadorSegundo = [9, 8, 7, 6, 5, 4, 3, 2];
        let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 7);
        let somaIE = 0;
        for (let i = 0; i < 7; i++) {
            somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicadorPrimeiro[i];
        }
        let resto = somaIE % 11;
        let primeiroDigitoVerificador = (resto === 0 || resto === 1) ? 0 : (11 - resto);

        let inscricaoEstadualComPrimeiroDigitoVerificador = inscricaoEstadualSemDigitoVerificador + "" + primeiroDigitoVerificador;
        somaIE = 0;
        resto = 0;
        for (let i = 0; i < 8; i++) {
            somaIE += inscricaoEstadualComPrimeiroDigitoVerificador[i] * pesosMultiplicadorSegundo[i];
        }
        resto = somaIE % 11;
        let segundoDigitoVerificador = (resto === 0 || resto === 1) ? 0 : (11 - resto);

        return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + primeiroDigitoVerificador + "" + segundoDigitoVerificador)) ? true : false;
    } else {
        let apenasNumeros = (retornaSoNumeros(numeroInscricao) === "") ? true : false;
        if (!apenasNumeros) {
            return false;
        }
        let numeroInscricao = retornaSoNumeros(numeroInscricao);
        if (numeroInscricao.length !== 14) {
            return false;
        }
        let pesosMultiplicadorPrimeiro = [5, 4, 3, 2, 1, 9, 8, 7, 6, 5, 4, 3, 2];
        let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 13);
        let somaIE = 0;
        for (let i = 0; i < 13; i++) {
            somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicadorPrimeiro[i];
        }
        let resto = somaIE % 11;
        resto = (11 - resto);
        let primeiroDigitoVerificador = (resto > 9) ? (10 - resto) : resto;

        return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + primeiroDigitoVerificador)) ? true : false;
    }
};

export const ValidarInscricaoEstadualPB = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 9) {
        return false;
    }
    let pesosMultiplicador = [9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 8);
    let somaIE = 0;
    for (let i = 0; i < 8; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let resto = somaIE % 11;
    resto = (11 - resto);
    let digitoVerificador = (resto === 10 || resto === 11) ? 0 : resto;

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualRN = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    if (retornaSoLetras(numeroInscricao).length === 9) {
        let numeroInscricao = retornaSoNumeros(numeroInscricao);
        if (numeroInscricao.length !== 9) {
            return false;
        }
        let pesosMultiplicadorPrimeiro = [9, 8, 7, 6, 5, 4, 3, 2];
        let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 8);
        let somaIE = 0;
        for (let i = 0; i < 8; i++) {
            somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicadorPrimeiro[i];
        }
        let resto = (somaIE * 10) % 11;
        let primeiroDigitoVerificador = (resto === 10) ? 0 : resto;

        return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + primeiroDigitoVerificador)) ? true : false;
    } else {
        let numeroInscricao = retornaSoNumeros(numeroInscricao);
        if (numeroInscricao.length !== 10) {
            return false;
        }
        let pesosMultiplicadorPrimeiro = [10, 9, 8, 7, 6, 5, 4, 3, 2];
        let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 9);
        let somaIE = 0;
        for (let i = 0; i < 9; i++) {
            somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicadorPrimeiro[i];
        }
        let resto = (somaIE * 10) % 11;
        let primeiroDigitoVerificador = (resto === 10) ? 0 : resto;

        return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + primeiroDigitoVerificador)) ? true : false;
    }
};

export const ValidarInscricaoEstadualPI = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 9) {
        return false;
    }

    let pesosMultiplicador = [9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 8);
    let somaIE = 0;
    for (let i = 0; i < 8; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let resto = somaIE % 11;
    resto = (11 - resto);
    let digitoVerificador = (resto === 10 || resto === 11) ? 0 : resto;

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualMA = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 9) {
        return false;
    }
    let pesosMultiplicador = [9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 8);
    let somaIE = 0;
    for (let i = 0; i < 8; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let resto = somaIE % 11;
    let digitoVerificador = (resto === 0 || resto === 1) ? 0 : (11 - resto);

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualCE = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 9) {
        return false;
    }
    let pesosMultiplicador = [9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 8);
    let somaIE = 0;
    for (let i = 0; i < 8; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let resto = somaIE % 11;
    resto = (11 - resto);
    let digitoVerificador = (resto === 10 || resto === 11) ? 0 : resto;

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualGO = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 9) {
        return false;
    }
    let pesosMultiplicador = [9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 8);
    let somaIE = 0;
    for (let i = 0; i < 8; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let resto = somaIE % 11;
    let digitoVerificador = 0;
    if (inscricaoEstadualSemDigitoVerificador === "11094402" && (resto === 0 || resto === 1)) {
        digitoVerificador = parseInt(numeroInscricao.substr(8, 1));
    } else if (resto === 0) {
        digitoVerificador = 0;
    } else if (resto === 1 && (parseInt(inscricaoEstadualSemDigitoVerificador) >= 10103105 || parseInt(inscricaoEstadualSemDigitoVerificador) <= 10119997)) {
        digitoVerificador = 1;
    } else if (resto === 1 && (parseInt(inscricaoEstadualSemDigitoVerificador) <= 10103105 || parseInt(inscricaoEstadualSemDigitoVerificador) >= 10119997)) {
        digitoVerificador = 0;
    } else {
        digitoVerificador = (11 - resto);
    }

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualTO = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 11) {
        return false;
    }
    let pesosMultiplicador = [9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 2) + "" + numeroInscricao.substr(4, 6);
    let somaIE = 0;
    for (let i = 0; i < 8; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let resto = somaIE % 11;
    let digitoVerificador = (resto < 2) ? 0 : (11 - resto);

    return (numeroInscricao === (numeroInscricao.substr(0, 10) + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualMT = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 11) {
        return false;
    }
    let pesosMultiplicador = [3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 10);
    let somaIE = 0;
    for (let i = 0; i < 10; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let resto = somaIE % 11;
    let digitoVerificador = (resto === 1 || resto === 0) ? 0 : (11 - resto);

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualMS = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 9) {
        return false;
    }
    let pesosMultiplicador = [9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 8);
    let somaIE = 0;
    for (let i = 0; i < 8; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let resto = somaIE % 11;
    let digitoVerificador = (resto === 0) ? 0 : ((11 - resto) > 9) ? 0 : (11 - resto);

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualDF = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 13) {
        return false;
    }
    let pesosMultiplicadorPrimeiro = [4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    let pesosMultiplicadorSegundo = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 11);
    let somaIE = 0;
    for (let i = 0; i < 11; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicadorPrimeiro[i];
    }

    let resto = somaIE % 11;
    resto = (11 - resto);
    let digitoVerificadorPrimeiro = (resto === 10 || resto === 11) ? 0 : resto;

    somaIE = 0;
    resto = 0;
    let inscricaoEstadualComPrimeiroDigito = inscricaoEstadualSemDigitoVerificador + "" + digitoVerificadorPrimeiro;

    for (let i = 0; i < 12; i++) {
        somaIE += inscricaoEstadualComPrimeiroDigito[i] * pesosMultiplicadorSegundo[i];
    }
    resto = somaIE % 11;
    resto = (11 - resto);
    let digitoVerificadorSegundo = (resto === 10 || resto === 11) ? 0 : resto;

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificadorPrimeiro + "" + digitoVerificadorSegundo)) ? true : false;
};

export const ValidarInscricaoEstadualAM = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 9) {
        return false;
    }
    let pesosMultiplicador = [9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 8);
    let somaIE = 0;
    for (let i = 0; i < 8; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let resto = (somaIE < 11) ? somaIE : somaIE % 11;
    let digitoVerificador = (resto <= 1) ? 0 : (11 - resto);

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualAC = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 13) {
        return false;
    }
    let pesosMultiplicadorPrimeiro = [4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    let pesosMultiplicadorSegundo = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 11);
    let somaIE = 0;
    for (let i = 0; i < 11; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicadorPrimeiro[i];
    }
    let resto = somaIE % 11;
    resto = (11 - resto);
    let digitoVerificadorPrimeiro = (resto === 10 || resto === 11) ? 0 : resto;

    somaIE = 0;
    resto = 0;
    let inscricaoEstadualComPrimeiroDigito = inscricaoEstadualSemDigitoVerificador + "" + digitoVerificadorPrimeiro;

    for (let i = 0; i < 12; i++) {
        somaIE += inscricaoEstadualComPrimeiroDigito[i] * pesosMultiplicadorSegundo[i];
    }
    resto = somaIE % 11;
    resto = (11 - resto);
    let digitoVerificadorSegundo = (resto === 10 || resto === 11) ? 0 : resto;

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificadorPrimeiro + "" + digitoVerificadorSegundo)) ? true : false;
};

export const ValidarInscricaoEstadualPA = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 9) {
        return false;
    }
    let pesosMultiplicador = [9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 8);
    let somaIE = 0;
    for (let i = 0; i < 8; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let resto = somaIE % 11;
    let digitoVerificador = (resto === 1 || resto === 0) ? 0 : (11 - resto);

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualRO = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 14) {
        return false;
    }
    let pesosMultiplicador = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 13);
    let somaIE = 0;
    for (let i = 0; i < 13; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let resto = somaIE % 11;
    resto = (11 - resto);
    let digitoVerificador = (resto === 10 || resto === 11) ? (resto - 10) : resto;

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualRR = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 9) {
        return false;
    }
    let pesosMultiplicador = [1, 2, 3, 4, 5, 6, 7, 8];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 8);
    let somaIE = 0;
    for (let i = 0; i < 8; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let resto = somaIE % 9;
    let digitoVerificador = resto;

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};

export const ValidarInscricaoEstadualAP = (numeroInscricao) => {
    let apenasNumeros = (retornaSoLetras(numeroInscricao) === "") ? true : false;
    if (!apenasNumeros) {
        return false;
    }
    numeroInscricao = retornaSoNumeros(numeroInscricao);
    if (numeroInscricao.length !== 9) {
        return false;
    }
    let pesosMultiplicador = [9, 8, 7, 6, 5, 4, 3, 2];
    let inscricaoEstadualSemDigitoVerificador = numeroInscricao.substr(0, 8);
    let somaIE = 0;
    for (let i = 0; i < 8; i++) {
        somaIE += inscricaoEstadualSemDigitoVerificador[i] * pesosMultiplicador[i];
    }
    let valorAcrescentadoNaSoma;
    let digitoVerificadorPadrao;
    if (inscricaoEstadualSemDigitoVerificador >= '03000001' && inscricaoEstadualSemDigitoVerificador <= '03017000') {
        valorAcrescentadoNaSoma = 5;
        digitoVerificadorPadrao = 0;
    } else {
        if (inscricaoEstadualSemDigitoVerificador >= '03017001' && inscricaoEstadualSemDigitoVerificador <= '03019022') {
            valorAcrescentadoNaSoma = 9;
            digitoVerificadorPadrao = 1;
        } else {
            valorAcrescentadoNaSoma = 0;
            digitoVerificadorPadrao = 0;
        }
    }
    let resto = (valorAcrescentadoNaSoma + somaIE) % 11;
    resto = (11 - resto);
    let digitoVerificador = (resto === 10) ? 0 : (resto === 11) ? digitoVerificadorPadrao : resto;

    return (numeroInscricao === (inscricaoEstadualSemDigitoVerificador + "" + digitoVerificador)) ? true : false;
};