import React, { useState, useEffect } from "react";
import { Row, Col, Input, Button, Form, notification, Tooltip, Table, Tag, Modal, Select } from "antd";
import { SearchOutlined, BarcodeOutlined, ReloadOutlined, EditOutlined, DeleteOutlined, ArrowUpOutlined, ArrowDownOutlined, ExclamationCircleOutlined, CloseOutlined, SaveOutlined } from "@ant-design/icons";
import moment from "moment";

import api from "../../services/api";
import { useStateValue } from "../../state";
import { getLocal, getNomeUsuario } from "../../services/auth";
import { FormatNumber } from "../../ValueObjects";
import { ModalBandeiraCartao, ModalEditarParcela } from "../../components/modals";
import { LocalizarCliente, LocalizarParcela } from "./pages";
import { dadosPdvActions, listagemActions, manutencaoActions } from "../../actions";
import { Data, Editar, Excluir, InputPreco, BreadcrumbPage, FiltroSearch } from "../../components";
import { carregarDadosPdv, compararDatas, diferencaDatas, info, novaAbaNavegador } from "../../services/funcoes";

export default function Parcelas() {

    const [formulario] = Form.useForm();
    const [{ manutencao, dadosPdv }, dispatch] = useStateValue();
    const [pesquisaCodBarras, setPesquisaCodBarras] = useState(true);
    const [listaParcelasCliente, setListaParcelasCliente] = useState(false);
    const [dadosCliente, setDadosCliente] = useState({});
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [listaParcelasReceber, setListaParcelasReceber] = useState([]);
    const [dadosLinhaEditar, setDadosLinhaEditar] = useState({});
    const [openModalEditarParcela, setOpenModalEditarParcela] = useState(false);
    const [listaTotalizadores, setListaTotalizadores] = useState([]);
    const [listaContaCorrente, setListaContaCorrente] = useState([]);
    const [listaContaGerencial, setListaContaGerencial] = useState([]);
    const [listaContaGerencialAgrupador, setListaContaGerencialAgrupador] = useState([]);
    const [listaFormaPagamento, setListaFormaPagamento] = useState([]);
    const [parametrosEmpresa, setParametrosEmpresa] = useState({})
    const [listaCondicaoPagamento, setListaCondicaoPagamento] = useState([]);
    const [listaParcelasCartao, setListaParcelasCartao] = useState([]);
    const [totalSelecionados, setTotalSelecionados] = useState([]);
    const [listaParcelasPeriodo, setListaParcelasPeriodo] = useState([]);
    const [formParametros] = Form.useForm();
    const [exibirParcelasPeriodo, setExibirParcelasPeriodo] = useState(false);
    const [openModalCartao, setOpenModalCartao] = useState(false);
    const [carregando, setCarregando] = useState(false);
    const [dadosCartao, setDadosCartao] = useState({});

    const tipoFormaPagamento = {
        fpDinheiro: 1,
        fpCheque: 2,
        fpCartaoCredito: 3,
        fpCartaoDebito: 4,
        fpCreditoLoja: 5,
        fpValeAlimentacao: 10,
        fpValeRefeicao: 11,
        fpValePresente: 12,
        fpValeCombustivel: 13,
        fpBoleto: 15,
        fpDeposito: 16,
        fpPixDinamico: 17,
        fpTransferencia: 18,
        fpPixEstatico: 20,
        fpSemPagamento: 90,
        fpOutro: 99,
    };

    const tipoFormaPagExistente = [
        tipoFormaPagamento.fpDinheiro,
        tipoFormaPagamento.fpCheque,
        tipoFormaPagamento.fpCartaoCredito,
        tipoFormaPagamento.fpCartaoDebito,
        tipoFormaPagamento.fpDeposito,
        tipoFormaPagamento.fpPixDinamico,
        tipoFormaPagamento.fpPixEstatico,
        tipoFormaPagamento.fpTransferencia
    ];

    const tipoParcelamentoCartao = {
        estabelecimento: 0,
        comprador: 1
    };

    const limparListas = () => {
        setPesquisaCodBarras(true);
        setDadosCliente({});
        setListaParcelasReceber([]);
        setListaTotalizadores({});
        setTotalSelecionados([]);
        formulario.resetFields();
    }

    async function verificaPdv() {
        let res = await carregarDadosPdv();
        dispatch({ type: dadosPdvActions.CHANGE, data: { pdv: res } });
    }

    useEffect(() => {
        if (!!!dadosPdv.pdv) {
            verificaPdv();
        }
    }, [])

    useEffect(() => {
        api.get(`ParametroEmpresa/BuscarParametros`).then(res => {
            setParametrosEmpresa(res.data);
        }).catch(
            error => {
                console.log(error);
            }
        )
    }, [])

    useEffect(() => {
        let lista = {};
        lista.valorTotal = 0;
        lista.valorTotalParcelas = 0;
        lista.valorTotalJuros = 0;
        lista.valorDesconto = 0;
        lista.valorAcrescimo = 0;
        lista.valorAReceber = 0;
        let valorRecebido = 0;
        if (listaParcelasReceber.length > 0) {
            listaParcelasReceber.map(item => {
                let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
                lista.valorTotalParcelas += parseFloat(item.valorOriginal);
                lista.valorTotalJuros += parseFloat(item.valorJuros);
                lista.valorDesconto += parseFloat(item.valorDesconto);
                lista.valorAReceber += parseFloat((valorTotal - parseFloat(item.valorDesconto) + parseFloat(item.valorJuros)).toFixed(2));

                valorRecebido += parseFloat(item.valorAReceber);
            });
            //if (alterarValorPago) {
            formulario.setFieldsValue({ valorPago: parseFloat(valorRecebido).toFixed(2) });
            //}

            calcularSobra(lista);
        }
    }, [listaParcelasReceber]);


    async function carregarDados() {
        api.get(`contaCorrente/listar?filtro=&ordem=+ctc_descricao&PageSize=1000`).then(res => {
            setListaContaCorrente(res.data.items);
            formulario.setFieldsValue({ ctc_id: dadosPdv.pdv?.ctc_id, ctg_id: parametrosEmpresa.par_ctgrecebimentoparcela });
        });

        api.get(`ContaGerencial/ListarAtivosEstruturadoPelaCategoria?CodReceitaDespesa=2&filtro=&PageNumber=1&PageSize=1000`).then(res => {
            setListaContaGerencialAgrupador(res.data.filter((item) => { return item.podeSelecionar === false }));
            setListaContaGerencial(res.data.filter((item) => { return item.podeSelecionar === true }));
        });

        api.get(`FormaPagamento/Listar?filtro=&PageNumber=1&PageSize=1000`).then(res => {
            let key = 0;
            res.data.items.forEach((item) => {
                item.key = key++;
            })
            setListaFormaPagamento(res.data.items);
        });
        if (!!!formulario.getFieldValue().dataPagamentoRecebimento) {
            formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date()) });
        }
    }

    function buscarCodBarras(valor) {
        carregarDados();
        api.get(`Parcela/ListarParcelas?CodigoBarras=${valor}&PagarOuReceber=1`).then(
            res => {
                if (res.data.items.length > 0) {
                    let listaTemp = [...listaParcelasReceber];
                    let retornoParcela = res.data.items[0];
                    if (listaParcelasReceber.filter(item => { return item.prc_codigobarras === retornoParcela.prc_codigobarras }).length > 0) {
                        notification.warning("A parcela já esta adicionada na lista para pagamento!");
                    } else {
                        if (retornoParcela.prc_status !== 2 && !retornoParcela.prc_estornado) {
                            if (!!dadosCliente.pes_id) {
                                setDadosCliente({ pes_id: retornoParcela.pes_id, pes_nome: retornoParcela.pes_nome });
                            }
                            if (retornoParcela.pes_id === (!!dadosCliente.pes_id ? dadosCliente.pes_id : retornoParcela.pes_id)) {
                                let valorReceber;
                                retornoParcela.key = retornoParcela.cpr_id.toString().concat(retornoParcela.prc_id);
                                retornoParcela.vencida = compararDatas(retornoParcela.prc_datavencimento, null, '<');
                                retornoParcela.numeroDiasVencida = 0;
                                retornoParcela.valorJuros = 0;
                                retornoParcela.zerarJuros = false;
                                if (retornoParcela.vencida) {
                                    retornoParcela.numeroDiasVencida = diferencaDatas(retornoParcela.prc_datavencimento);
                                    if (!(!!parametrosEmpresa.par_diastoleranciacobrancajuro)) {
                                        valorReceber = parseFloat((retornoParcela.prc_valor - retornoParcela.valorQuitado).toFixed(2));
                                        retornoParcela.valorJuros = parseFloat((((valorReceber * (parametrosEmpresa.par_percjuromesparcelavencida / 30)) / 100) * retornoParcela.numeroDiasVencida).toFixed(2));
                                    } else if (retornoParcela.numeroDiasVencida > parametrosEmpresa.par_diastoleranciacobrancajuro) {
                                        valorReceber = parseFloat((retornoParcela.prc_valor - retornoParcela.valorQuitado).toFixed(2));
                                        retornoParcela.valorJuros = parseFloat((((valorReceber * (parametrosEmpresa.par_percjuromesparcelavencida / 30)) / 100) * retornoParcela.numeroDiasVencida).toFixed(2));
                                    }
                                }
                                retornoParcela.valorOriginal = parseFloat(retornoParcela.prc_valor);
                                retornoParcela.prc_valor = parseFloat((retornoParcela.prc_valor - retornoParcela.valorQuitado + retornoParcela.valorJuros).toFixed(2));
                                retornoParcela.valorPagoRecebido = parseFloat(retornoParcela.prc_valor);
                                retornoParcela.valorDesconto = 0.00;
                                retornoParcela.valorAcrescimo = 0;
                                retornoParcela.valorAReceber = retornoParcela.prc_valor;
                                listaTemp.push(retornoParcela);
                                setListaParcelasReceber(listaTemp);
                            }
                        } else {
                            if (retornoParcela.prc_status === 2) {
                                notification.warning({ message: 'Aviso!', description: 'Esta Parcela já foi totalmente liquidada!' });
                            }
                            if (retornoParcela.prc_estornado === 1) {
                                notification.warning({ message: 'Aviso!', description: 'A Parcela foi Estornada!' });
                            }
                        }
                    }
                } else {
                    notification.warning({ message: 'Aviso!', description: 'Parcela não encontrada!' });
                }
            });
    };

    function zerarJuros() {
        let listaTemp = [...listaParcelasReceber];
        listaTemp.forEach(item => {
            let valorTotal = parseFloat(!!item.valorOriginal ? item.valorOriginal : 0) - parseFloat(!!item.valorQuitado ? item.valorQuitado : 0);
            item.valorJuros = 0;
            if (parseFloat(item.valorDesconto) > valorTotal) {
                item.valorDesconto = valorTotal;
            }
            item.valorAReceber = parseFloat((valorTotal - parseFloat(item.valorDesconto) + parseFloat(item.valorJuros)).toFixed(2));
            item.valorPago = item.valorAReceber;
            item.prc_valor = item.valorAReceber;
        });
        setListaParcelasReceber(listaTemp);
    }

    function editarParcela(linha) {
        setDadosLinhaEditar(linha);
        setOpenModalEditarParcela(true);
    }

    function salvarDadosModal(values) {
        let listaTemp = [...listaParcelasReceber];
        let linha = listaTemp.filter((item) => { return item.prc_codigobarras === dadosLinhaEditar.prc_codigobarras })[0];
        linha.valorJuros = parseFloat(values.valorJuros);
        linha.valorDesconto = parseFloat(values.valorDesconto);
        linha.valorAcrescimo = 0;
        linha.valorPagoRecebido = parseFloat(values.valorPago);
        linha.valorAReceber = parseFloat(values.valorPago);
        linha.valorParcela = linha.valorAReceber;
        setListaParcelasReceber(listaTemp);
    }

    function excluirRegistro(dados) {
        Modal.confirm({
            title: `Remover da lista a parcela Nº ${dados.prc_numeroparcela} no valor de ${FormatNumber(!!dados.prc_valor ? dados.prc_valor : 0, true)}?`,
            icon: <ExclamationCircleOutlined />,
            onOk() {
                let listaTemp = [...listaParcelasReceber];
                let index = listaTemp.findIndex(item => item.prc_codigobarras === dados.prc_codigobarras);
                listaTemp.splice(index, 1);
                if (listaTemp.length === 0) {
                    setDadosCliente([]);
                }
                setListaParcelasReceber(listaTemp);
            }
        });
    }

    function calcularRecebimentoParcial() {
        let valorRecebido = parseFloat(formulario.getFieldValue('valorPago'));
        if (valorRecebido > 0) {
            let listaTemp = [...listaParcelasReceber];
            for (const item of listaTemp) {
                let valorTotal = parseFloat((!!item.valorOriginal ? item.valorOriginal : 0)) - parseFloat((!!item.valorQuitado ? item.valorQuitado : 0));
                let valorParcela = parseFloat((valorTotal - parseFloat(item.valorDesconto) + parseFloat(item.valorJuros)).toFixed(2));
                if (valorRecebido > valorParcela) {
                    item.valorAReceber = valorParcela;
                } else {
                    item.valorAReceber = valorRecebido;
                }
                item.prc_valor = item.valorAreceber;
                item.valorPago = item.valorAReceber;
                valorRecebido -= valorParcela;
            }
            setListaParcelasReceber(listaTemp);
        } else {
            calcularSobra();
        }
    }

    function calcularSobra(lista = { ...listaTotalizadores }) {
        let sobra = parseFloat((parseFloat(!!formulario.getFieldValue("valorPago") ? formulario.getFieldValue("valorPago") : 0) - parseFloat(lista.valorAReceber)).toFixed(2));
        lista.restanteReceber = 0;
        lista.troco = 0;
        if (sobra < 0) {
            lista.restanteReceber = parseFloat((sobra * -1).toFixed(2));
        } else {
            lista.troco = parseFloat(sobra);
        }
        setListaTotalizadores(lista);
    };

    async function verificarFormaPagamento(values) {
        let formaPagamento = (await api.get(`FormaPagamento/Get?id=${values.fpg_id}`)).data;
        switch (formaPagamento.fpg_tipopagamento) {
            case tipoFormaPagamento.fpDinheiro:
            case tipoFormaPagamento.fpPixDinamico:
            case tipoFormaPagamento.fpPixEstatico:
            case tipoFormaPagamento.fpTransferencia:
            case tipoFormaPagamento.fpDeposito:
                efetuarRecebimento(values, null);
                break;
            case tipoFormaPagamento.fpCheque:
                break;
            case tipoFormaPagamento.fpCartaoDebito:
                values.credito = false;
                calcularParcelasCartao(values);
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...values } } });
                break
            case tipoFormaPagamento.fpCartaoCredito:
                values.credito = true;
                calcularParcelasCartao(values);
                dispatch({ type: manutencaoActions.CHANGE, data: { dados: { ...manutencao.dados, ...values } } });
                break
        };
    };

    function calcularParcelasCartao(values) {
        let definicaoParcelamento = [];
        let parcelas = [];
        let somaParcelas = 0;
        let resto = 0;
        let numeroUltimaParcela;
        if (!!values.cpg_id) {
            api.get(`CondicaoPagamento/ListaParcelamento?codigoCondicaoPagamento=${values.cpg_id}`).then((res) => {
                definicaoParcelamento = res.data;
                if (definicaoParcelamento.length > 0) {
                    definicaoParcelamento.forEach((parcela, indice) => {
                        let valorParcela = 0;
                        valorParcela = ((parcela.pcl_percparcela / 100) * (values.valorPago)).toFixed(2);
                        parcelas.push({
                            prc_numeroparcela: indice + 1,
                            prc_valor: parseFloat(valorParcela)
                        });
                        somaParcelas += parseFloat(valorParcela);
                    });
                    resto = (values.valorPago - parseFloat(somaParcelas)).toFixed(2);
                    resto = parseFloat(resto);
                }
                if (!resto !== 0) {
                    numeroUltimaParcela = 0;
                    if (definicaoParcelamento.length > 0) {
                        numeroUltimaParcela = definicaoParcelamento.length - 1;
                    } else {
                        numeroUltimaParcela = 1;
                    }
                    let valParcela = parseFloat(parcelas[numeroUltimaParcela].prc_valor);
                    let totalParcela = parseFloat(valParcela + resto).toFixed(2);
                    parcelas[numeroUltimaParcela].prc_valor = parseFloat(totalParcela);
                }
                setListaParcelasCartao(parcelas);
            });
        }
    };

    function efetuarRecebimento(values, dadosCartao) {
        var diasTolerancia = 30;
        if (formulario.getFieldValue().dataPagamentoRecebimento) {
            if (formulario.getFieldValue().dataPagamentoRecebimento > new Date()) {
                if (diferencaDatas(formulario.getFieldValue().dataPagamentoRecebimento) > diasTolerancia) {
                    formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date(new Date().setDate(new Date().getDate() + 30))) });
                    let dataFormatada = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('DD/MM/YYYY')
                    info(`Data Movimento informada maior que tolerância de ${diasTolerancia} dias, Data Máxima ${dataFormatada}`);
                    return;
                }
            } else {
                if (diferencaDatas(formulario.getFieldValue().dataPagamentoRecebimento) > diasTolerancia) {
                    formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date(new Date().setDate(new Date().getDate() - 30))) });
                    let dataFormatada = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('DD/MM/YYYY')

                    info(`Data Movimento informada maior que tolerância de ${diasTolerancia} dias, Data Máxima ${dataFormatada}`);
                    return;
                }
            }
            values.dataPagamentoRecebimento = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('YYYY-MM-DD HH:mm:ss');
        } else {
            formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date()).format('YYYY-MM-DD HH:mm:ss') });
        }
        let dataAtual = new Date();
        if (!!!values) {
            values = manutencao.dados;
        }
        values.dataPagamentoRecebimento = formulario.getFieldValue().dataPagamentoRecebimento;
        values.valorTotalPagoRecebido = typeof valoresPagamento != 'undefined' ? parseFloat(values.valorPago) : parseFloat(values.valorPago);
        values.valorTotalParcelas = parseFloat(listaTotalizadores.valorTotalParcelas.toFixed(2));
        values.valorTotalDesconto = parseFloat(listaTotalizadores.valorDesconto.toFixed(2));
        values.valorTotalJuros = parseFloat(listaTotalizadores.valorTotalJuros.toFixed(2));
        values.valorTotalAcrescimo = 0;
        values.pes_id = dadosCliente.pes_id;

        values.formaPgto = tratarDadosFormaPagamento(values);
        values.parcelasQuitadas = listaParcelasReceber;
        if (!!dadosCartao) {
            let recebimentoCartao = [];
            let parcelas = listaParcelasCartao;
            if (values.credito) {
                if (dadosCartao.tipoParcelamento === tipoParcelamentoCartao.estabelecimento) {
                    let numeroDias = parseInt(dadosCartao.bandeira.bce_nrodiasrecebimentocredito);
                    parcelas.forEach((parcela) => {
                        recebimentoCartao.push({
                            tipoParcelamento: tipoParcelamentoCartao.estabelecimento,
                            bce_id: dadosCartao.bandeira.bce_id,
                            rcc_parcelarecebimento: parcela.prc_numeroparcela,
                            rcc_valor: parcela.prc_valor,
                            rcc_nsu: dadosCartao.nsu,
                            rcc_previsãorecebimento: new Date(dataAtual.setDate(dataAtual.getDate() + numeroDias))
                        })
                    })
                } else {
                    dataAtual = new Date();
                    recebimentoCartao.push({
                        tipoParcelamento: tipoParcelamentoCartao.comprador,
                        bce_id: dadosCartao.bandeira.bce_id,
                        rcc_parcelarecebimento: 1,
                        rcc_valor: values.valorPago,
                        rcc_nsu: dadosCartao.nsu,
                        rcc_precisaorecebimento: new Date(dataAtual.setDate(dataAtual.getDate() + parseInt(dadosCartao.bandeira.bce_nrodiasrecebimentocredito)))
                    })
                }
            } else {
                dataAtual = new Date();
                recebimentoCartao.push({
                    tipoParcelamento: null,
                    bce_id: dadosCartao.bandeira.bce_id,
                    rcc_parcelarecebimento: 1,
                    rcc_valor: values.valorPago,
                    rcc_nsu: dadosCartao.nsu,
                    rcc_previsaorecebimento: new Date(dataAtual.setDate(dataAtual.getDate() + parseInt(dadosCartao.bandeira.bce_nrodiasrecebimentodebito)))
                })
            }
            values.recebimentoCartao = recebimentoCartao;
        }

        setCarregando(true);
        api.post('/ContasPagarReceber/RecebimentoParcelas', values).then(
            (res) => {
                if (res.data) {
                    let dadosParcelas = [];
                    values.parcelasQuitadas.forEach((parcela) => {
                        if (parcela.valorPagoRecebido > 0) {
                            dadosParcelas.push({
                                pes_id: parcela.pes_id,
                                prc_id: parcela.prc_id,
                                loc_id: getLocal(),
                                usu_nome: getNomeUsuario(),
                                fpg_id: values.fpg_id,
                                cpg_id: values.cpg_id
                            });
                        }
                    });
                    let dadosReceber = btoa(JSON.stringify(dadosParcelas));
                    novaAbaNavegador(`ContasPagarReceber/ImprimirComprovantePagamento?hashParcelas=${dadosReceber}`);
                    limparListas();
                }
            }
        ).catch(
            (erro) => {
                notification.warning({ message: `Aviso!`, description: `Não foi possivel liquidar parcelas!` });
            }
        ).finally(() => {
            setCarregando(false);
        });
    }

    function tratarDadosFormaPagamento(values) {
        let dadosFormaPagamento = {
            fpg_id: values.fpg_id,
            ctc_id: !!values.ctc_id ? values.ctc_id : null,
            cpg_id: !!values.cpg_id ? values.cpg_id : null,
            valorFormaPgto: values.valorTotalPagoRecebido,
            valorDescontoFormaPgto: 0,
            valorJurosFormaPgto: 0,
            bce_id: dadosCartao?.bandeira?.bce_id,
            rcc_nsu: dadosCartao?.nsu
        }
        return [dadosFormaPagamento];
    }

    async function validarFormasPagamento(forma) {
        let dados = (await api.get(`FormaPagamento/Get?id=${forma}`)).data;
        switch (dados.fpg_tipopagamento) {
            case tipoFormaPagamento.fpCartaoDebito:
            case tipoFormaPagamento.fpCartaoCredito:
                if (dados.fpg_tipointegracao == null)
                    setOpenModalCartao(true);
                break;
        }
    };

    function buscarCondicaoPagamento(valor) {
        api.get(`CondicaoPagamento/Listar?codigoFormaPagamento=${valor}&PageNumber=1&PageSize=1000`).then(res => {
            setListaCondicaoPagamento(res.data.items);
        });
        formulario.setFieldsValue({ cpg_id: null });
    };

    function cancelaBuscaCodBarras() {
        setPesquisaCodBarras(!pesquisaCodBarras);
        setListaParcelasReceber([]);
    };

    function filtrarPeriodo() {
        let valor = formParametros.getFieldValue().pesquisa;
        if (!!formParametros.getFieldValue().dataInicial && !!formParametros.getFieldValue().dataFinal) {
            let dataInicial = moment(formParametros.getFieldValue().dataInicial).format('YYYY-MM-DD');
            let dataFinal = moment(formParametros.getFieldValue().dataFinal).format('YYYY-MM-DD');
            api.get(`Parcela/ListarParcelas?filtro=${!!valor ? valor : ''}&DataInicial=${dataInicial}&DataFinal=${dataFinal}&StatusParcela=0,1&PagarOuReceber=1&Estornado=false&PageSize=1000&Order=prc_datavencimento`).then(res => {
                setListaParcelasPeriodo(res.data.items);
            });
            if (!exibirParcelasPeriodo) setExibirParcelasPeriodo(true);
            setShowSearchBar(true);
        }
    };

    function validaIntervaloData() {
        var diasTolerancia = 30;
        if (formulario.getFieldValue().dataPagamentoRecebimento) {
            if (formulario.getFieldValue().dataPagamentoRecebimento > new Date()) {
                if (diferencaDatas(formulario.getFieldValue().dataPagamentoRecebimento) > diasTolerancia) {
                    formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date(new Date().setDate(new Date().getDate() + 30))) });
                    let dataFormatada = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('DD/MM/YYYY')
                    info(`Data Movimento informada maior que tolerância de ${diasTolerancia} dias, Data Máxima ${dataFormatada}`);
                }
            } else {
                if (diferencaDatas(formulario.getFieldValue().dataPagamentoRecebimento) > diasTolerancia) {
                    formulario.setFieldsValue({ dataPagamentoRecebimento: moment(new Date(new Date().setDate(new Date().getDate() - 30))) });
                    let dataFormatada = moment(formulario.getFieldValue().dataPagamentoRecebimento).format('DD/MM/YYYY')
                    info(`Data Movimento informada maior que tolerância de ${diasTolerancia} dias, Data Máxima ${dataFormatada}`);
                }
            }
        }
    };

    function pagamentoCartao(dados, dadosCartao) {
        setDadosCartao(dadosCartao);
    };

    return (
        <div>
            <div>
                <BreadcrumbPage pagina="Parcelas" pagina_seleciona="Recebimento de Parcelas" />
                <Form layout="vertical" form={formParametros}>
                    {!showSearchBar && pesquisaCodBarras && !listaParcelasCliente &&
                        <Row gutter={[8, 0]}>
                            <Col xs={24} sm={14} md={19} lg={19} xl={20} xxl={21}>
                                <Form.Item label="Localize a Parcela">
                                    <Input.Search suffix={<BarcodeOutlined />} placeholder="Informe o Código de Barras" onSearch={valor => {
                                        if (!!valor) buscarCodBarras(valor)
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={10} md={5} lg={5} xl={4} xxl={3}>
                                <Button type="primary" icon={<SearchOutlined />} onClick={() => {
                                    if (!!dadosCliente.pes_id) {
                                        setListaParcelasCliente(true);
                                    } else {
                                        dispatch({ type: listagemActions.CHANGE, data: { ordem: '+pes_nome', filtro: '' } });
                                        setPesquisaCodBarras(false);
                                    };
                                    cancelaBuscaCodBarras();
                                }} block className="t-mob">
                                    Buscar Cliente
                                </Button>
                            </Col>
                        </Row>
                    }
                    {(!showSearchBar && !pesquisaCodBarras && !listaParcelasCliente) &&
                        <Row gutter={[8, 0]} align="middle">
                            <Col xs={24} sm={17} md={17} lg={8} xl={9} xxl={12}>
                                <Form.Item name="pesquisa">
                                    <FiltroSearch labelInput="Localizar Clientes" placeholderInput="Pesquisa por Cliente... (Nome, CPF, CNPJ)" />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={7} md={7} lg={5} xl={4} xxl={4}>
                                <Form.Item>
                                    <Button icon={<SearchOutlined />} onClick={() => setPesquisaCodBarras(!pesquisaCodBarras)} className="t-mob-573" block>
                                        Buscar por Cód. Barras
                                    </Button>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={4} xl={4} xxl={3}>
                                <Data label="Data Vencimento Inicial" name="dataInicial" onBlur={filtrarPeriodo} />
                            </Col>
                            <Col xs={24} sm={8} md={6} lg={4} xl={4} xxl={3}>
                                <Data label="Data Vencimento Final" name="dataFinal" onBlur={filtrarPeriodo} />
                            </Col>
                            <Col xs={10} sm={8} md={5} lg={3} xl={3} xxl={2}>
                                <Form.Item>
                                    <Button type="primary" block icon={<SearchOutlined />} onClick={filtrarPeriodo} className="t-mob-573">
                                        Aplicar
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    }
                </Form>
                {pesquisaCodBarras && !listaParcelasCliente && listaParcelasReceber.length > 0 &&
                    <div>
                        <div className="tabela m-t-8">
                            <Table pagination={false} dataSource={listaParcelasReceber} columns={
                                [
                                    {
                                        title: 'Nº Doc.',
                                        width: 100,
                                        render: ({ cpr_numerodocumento }) => (
                                            <div>
                                                <b>{cpr_numerodocumento || '--'}</b>
                                            </div>
                                        ),
                                    }, {
                                        title: 'Parcela',
                                        width: 75,
                                        render: ({ prc_numeroparcela, cpr_numeroparcelas }) => (
                                            <Tag color="processing" className="w-100">
                                                <b>{prc_numeroparcela} - {cpr_numeroparcelas}</b>
                                            </Tag>
                                        ),
                                    },
                                    {
                                        title: 'Vencimento',
                                        width: 110,
                                        render: ({ prc_datavencimento, vencida }) => (
                                            <div>
                                                <b className={vencida ? "c-red" : ""}>{moment(prc_datavencimento).format('DD/MM/YYYY')}</b>
                                            </div>
                                        ),
                                    },
                                    {
                                        title: 'Vlr. Original (R$)',
                                        align: 'right',
                                        render: ({ valorOriginal }) => (
                                            <div>
                                                <b>
                                                    {FormatNumber(!!valorOriginal ? valorOriginal : 0, true)}
                                                </b>
                                            </div>
                                        ),
                                    }, {
                                        title: () => {
                                            return (
                                                <div>
                                                    <Row gutter={[5, 0]} justify="end">
                                                        <Col>Juros</Col>
                                                        <Col>
                                                            <Tooltip title="Zerar Juros">
                                                                <Button size="small" icon={<ReloadOutlined />} onClick={zerarJuros} />
                                                            </Tooltip>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            )
                                        },
                                        align: 'right',
                                        render: ({ valorJuros }) => (
                                            <div>
                                                <b className={!!valorJuros && parseFloat(valorJuros) > 0 ? 'c-red' : ''}>
                                                    {FormatNumber(!!valorJuros ? valorJuros : 0, true)}
                                                </b>
                                            </div>
                                        ),
                                    }, {
                                        title: 'Descontos (R$)',
                                        align: 'right',
                                        render: ({ valorDesconto }) => (
                                            <div>
                                                <b>
                                                    {FormatNumber(!!valorDesconto ? valorDesconto : 0, true)}
                                                </b>
                                            </div>
                                        ),
                                    }, {
                                        title: 'Recebido (R$)',
                                        align: 'right',
                                        render: ({ valorQuitado }) => (
                                            <div>
                                                <b>
                                                    {FormatNumber(!!valorQuitado ? valorQuitado : 0, true)}
                                                </b>
                                            </div>
                                        ),
                                    }, {
                                        title: 'A Receber (R$)',
                                        align: 'right',
                                        width: 145,
                                        render: ({ valorPagoRecebido }) => (
                                            <div>
                                                <b>
                                                    {FormatNumber(!!valorPagoRecebido ? valorPagoRecebido : 0, true)}
                                                </b>
                                            </div>
                                        ),
                                    },
                                    {
                                        dataIndex: '',
                                        key: 'x',
                                        align: 'center',
                                        width: 65,
                                        fixed: 'right',
                                        title: 'Ações',
                                        render: (record) => (
                                            <div>
                                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                                    <Col>
                                                        <Editar onClick={() => editarParcela(record)} icon={<EditOutlined />} />
                                                    </Col>
                                                    <Col>
                                                        <Excluir onClick={() => { excluirRegistro(record) }} icon={<DeleteOutlined />} />
                                                    </Col>
                                                </Row>
                                            </div>
                                        ),
                                    },
                                ]
                            } expandable={{
                                expandedRowRender: ({ prc_codigobarras, cpr_numerodocumento }) =>
                                    <div>
                                        <Row gutter={[8, 0]}>
                                            <Col span={12}>
                                                Cód. Barras: <b> {prc_codigobarras || 'Não Informado'}</b>
                                            </Col>
                                            <Col span={12}>
                                                Nº Documento: <b> {cpr_numerodocumento || 'Não Informado'}</b>
                                            </Col>
                                        </Row>
                                    </div>,
                                expandIcon: ({ expanded, onExpand, record }) =>
                                    expanded ? (
                                        <ArrowUpOutlined onClick={e => onExpand(record, e)} />
                                    ) : (
                                        <ArrowDownOutlined onClick={e => onExpand(record, e)} />
                                    )
                            }} scroll={{ x: 900 }} columnWidth={30} />
                        </div>
                        <Form layout="vertical" form={formulario} name="formRecebParcelas" onFinish={verificarFormaPagamento}>
                            <Row gutter={[8, 8]} className="m-t-16">
                                <Col xs={24} sm={6} md={3} lg={3} xl={3}>
                                    <Data label="Data Movimento" name="dataPagamentoRecebimento" onBlur={() => validaIntervaloData()} />
                                </Col>
                                <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                                    <Form.Item label="Formas de Pagamento" name="fpg_id" rules={[{ required: true, message: 'Selecione a Forma de Pagamento' }]}>
                                        <Select allowClear onChange={valor => { buscarCondicaoPagamento(valor); validarFormasPagamento(valor) }} showSearch options={listaFormaPagamento.filter((pagamento) => { return tipoFormaPagExistente.includes(pagamento.fpg_tipopagamento) }).map((item) => {
                                            return { label: item.fpg_descricao, value: item.fpg_id, key: item.fpg_id }
                                        })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione a Forma de Pagamento..." />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={6} lg={5} xl={5} xxl={5}>
                                    <Form.Item label="Conta Corrente" name="ctc_id" rules={[{ required: true, message: 'Selecione uma Conta Corrente' }]}>
                                        <Select allowClear disabled={true} showSearch options={listaContaCorrente.map((item) => {
                                            return { label: item.ctc_descricao, value: item.ctc_id, key: item.ctc_id }
                                        })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione uma Conta Corrente..." />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={6} lg={6} xl={6} xxl={6}>
                                    <Form.Item label="Conta Gerencial" name="ctg_id" rules={[{ required: true, message: 'Selecione uma Conta Gerencial' }]}>
                                        <Select allowClear showSearch optionFilterProp="children" placeholder="Selecione uma Conta Gerencial...">
                                            {listaContaGerencialAgrupador.map(item => (
                                                <Select.OptGroup label={item.ctg_descricao}>
                                                    {listaContaGerencial.filter((conta) => { return conta.ctc_id === item.ctc_id }).map(conta => (
                                                        <Select.Option key={conta.ctg_id} value={conta.ctg_id} label={conta.ctg_descricao}>{conta.ctg_descricao}</Select.Option>
                                                    ))}
                                                </Select.OptGroup>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={8} md={6} lg={4} xl={4} xxl={4}>
                                    <InputPreco onBlur={() => calcularRecebimentoParcial()} onPressEnter={() => calcularRecebimentoParcial()} name={'valorPago'} label={'Valor Recebido (R$)'} />
                                </Col>
                            </Row>
                            <Row gutter={[8, 8]}>
                                <Col span={6}>
                                    <Form.Item label="Condições de Pagamento" name="cpg_id" rules={[{ required: true, message: 'Selecione a Condição de Pagamento' }]}>
                                        <Select showSearch options={listaCondicaoPagamento.map((item) => {
                                            return { label: item.cpg_descricao, value: item.cpg_id, key: item.key }
                                        })} filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0} placeholder="Selecione a Condição de Pagamento..." />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                        <div className="text-right m-t-16">
                            {(!!listaTotalizadores.valorTotalParcelas && listaTotalizadores.valorTotalParcelas !== 0) &&
                                <div className="f-14">
                                    Valor Parcelas (R$): <b className="f-18">{FormatNumber(listaTotalizadores.valorTotalParcelas, true)}</b>
                                </div>
                            }
                            {(!!listaTotalizadores.valorTotalJuros && listaTotalizadores.valorTotalJuros !== 0) &&
                                <div className="f-14 c-red">
                                    Valor Juros (R$): <b className="f-18">{FormatNumber(listaTotalizadores.valorTotalJuros, true)}</b>
                                </div>
                            }
                            {(!!listaTotalizadores.valorDesconto && listaTotalizadores.valorDesconto !== 0) &&
                                <div className="f-14 c-primary">
                                    Valor Descontos: (R$) <b className="f-18">{FormatNumber(listaTotalizadores.valorDesconto, true)}</b>
                                </div>
                            }
                            {(!!listaTotalizadores.valorAReceber && listaTotalizadores.valorAReceber !== 0) &&
                                <div className="f-18">
                                    Valor à Receber (R$): <b className="f-21">{FormatNumber(listaTotalizadores.valorAReceber, true)}</b>
                                </div>
                            }
                            {(!!listaTotalizadores.troco && listaTotalizadores.troco !== 0) &&
                                <div className="f-18">
                                    Troco (R$): <b className="f-25 c-green">{FormatNumber(listaTotalizadores.troco, true)}</b>
                                </div>
                            }
                            {(!!listaTotalizadores.restanteReceber && listaTotalizadores.restanteReceber !== 0) &&
                                <div className="f-18">
                                    Restante a Receber (R$): <b className="f-25 c-red">{FormatNumber(listaTotalizadores.restanteReceber, true)}</b>
                                </div>
                            }
                        </div>
                        <Row justify="end" gutter={[8, 0]} className="m-t-19">
                            <Col>
                                <Button onClick={() => { limparListas(); setPesquisaCodBarras(!pesquisaCodBarras); }} icon={<CloseOutlined />} size="middle" htmlType="button">
                                    Cancelar
                                </Button>
                            </Col>
                            <Col>
                                <Button onClick={() => formulario.submit()} loading={carregando} icon={<SaveOutlined />} size="middle" type="primary" htmlType="submit">
                                    Efetuar Recebimento
                                </Button>
                            </Col>
                        </Row>
                    </div>
                }
                {(!pesquisaCodBarras && !exibirParcelasPeriodo) &&
                    <LocalizarCliente showSearchBar={showSearchBar} setShowSearchBar={setShowSearchBar} />
                }

                {(!pesquisaCodBarras && exibirParcelasPeriodo) &&
                    <LocalizarParcela exibirParcelasPeriodo={{ exibirParcelasPeriodo, setExibirParcelasPeriodo }} listaParcelasPeriodo={listaParcelasPeriodo} selecionados={{ totalSelecionados, setTotalSelecionados }} setListaParcelasCliente={setListaParcelasCliente} listaParcelasReceber={listaParcelasReceber} dadosCliente={dadosCliente} setDadosCliente={setDadosCliente} showSearchBar={showSearchBar} setShowSearchBar={setShowSearchBar} />
                }

                {listaParcelasCliente &&
                    <LocalizarParcela showSearchBar={showSearchBar} setShowSearchBar={setShowSearchBar} />
                }
                <ModalEditarParcela exibirModal={openModalEditarParcela} fecharModal={() => setOpenModalEditarParcela(false)} dadosLinha={dadosLinhaEditar} salvarDadosModal={salvarDadosModal} />
                <ModalBandeiraCartao exibirModal={openModalCartao} fecharModal={() => setOpenModalCartao(false)} fecharModalOK={() => setOpenModalCartao(false)} retornoModal={pagamentoCartao} />
            </div>
        </div>
    );
}