import React, { useState, useEffect } from "react";
import { Modal, Tabs } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { Telefone, Email, Endereco } from "./pages";
import { isObjetoDiffVazio } from "../../../services/funcoes"

export default function ModalContatoCliente({ dadosCliente, setDadosCliente, exibirModalContatoCliente, fecharModalContatoCliente }) {

    const [abaSelecionada, setAbaSelecionada] = useState("1");
    const [dadosTelefone, setDadosTelefone] = useState([]);
    const [dadosEmail, setDadosEmail] = useState([]);
    const [dadosEndereco, setDadosEndereco] = useState([]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalContatoCliente();
            }
        });
    };

    useEffect(() => {
        if (isObjetoDiffVazio(dadosCliente)) {
            carregarDados(dadosCliente);
        }

    }, [dadosCliente])

    async function carregarDados(cli) {
        let telefone = (await api.get(`PessoaTelefone/ListaTelefone?IdPessoa=${cli.pes_id}`)).data;
        setDadosTelefone(telefone);
        let email = (await api.get(`PessoaEmail/ListaEmail?IdPessoa=${cli.pes_id}`)).data;
        setDadosEmail(email);
        let endereco = (await api.get(`PessoaEndereco/ListaEndereco?IdPessoa=${cli.pes_id}`)).data;
        setDadosEndereco(endereco);
    }

    return (
        <Modal centered
            title="Contatos do Cliente"
            open={exibirModalContatoCliente}
            getContainer={false}
            onCancel={onCloseModal}
            maskClosable={false}
            footer={false}
            width={680}
        >
            <>
                <Tabs type="card" centered activeKey={abaSelecionada} onChange={(tab) => setAbaSelecionada(tab)}>
                    <Tabs.TabPane tab="Telefone(s)" key="1">
                        <Telefone dadosTelefone={dadosTelefone} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="E-mail(s)" key="2">
                        <Email dadosEmail={dadosEmail} />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Endereço(s)" key="3">
                        <Endereco dadosEndereco={dadosEndereco} />
                    </Tabs.TabPane>
                </Tabs>
            </>
        </Modal>
    );
}