import React, { useState, useEffect } from "react";
import { Row, Col, Button, Tag, Table, Divider, Modal, Form, InputNumber, notification } from "antd";
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined, ExclamationOutlined, SaveOutlined } from "@ant-design/icons";
import { FormatNumber } from '../../ValueObjects';

export default function TabelaNota({ dadosNfceDevolucao, setDadosNfceDevolucao }) {

    const [form] = Form.useForm();
    const [dadosItens, setDadosItens] = useState([]);

    useEffect(() => {
        if (!!dadosNfceDevolucao && dadosNfceDevolucao.itensNf && dadosNfceDevolucao.itensNf.length > 0) {
            dadosNfceDevolucao.itensNf.forEach((item) => {
                item.editando = false;
            });
            setDadosItens(dadosNfceDevolucao.itensNf)
        }
    }, [dadosNfceDevolucao])

    function editarItem(registro, idx, indice) {
        let dadosTemp = { ...dadosNfceDevolucao };
        let novosItens = dadosTemp.itensNf.map(item => ({
            ...item,
            editando: item.nfi_numeroitem === registro.nfi_numeroitem,
        }));
        setDadosItens([...novosItens]);
        form.setFieldsValue({ [`qtdeRestante${idx + 1}`]: registro.qtdeRestante });
    }

    function excluirItem(record) {
        Modal.confirm({
            title: 'Aviso!',
            icon: <ExclamationOutlined />,
            content: 'Deseja remover o item da nota?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                let dadosTemp = { ...dadosNfceDevolucao };
                let dadosRet = dadosTemp.itensNf.filter((item) => (item.nfi_numeroitem !== record.nfi_numeroitem));
                dadosTemp.itensNf = dadosRet;
                setDadosNfceDevolucao(dadosTemp);
            }
        });
    }

    function ajustarQuantidade(item, indice) {
        let dadosTemp = { ...dadosNfceDevolucao };
        let itemTemp = { ...item };
        if ((form.getFieldValue(`qtdeRestante${indice + 1}`) + itemTemp.qtdeDevolvida) > itemTemp.nfi_qtde) {
            notification.warning({ message: 'Aviso!', description: 'Quantidade maior do que a original' });
            return false;
        }
        dadosTemp.itensNf[indice].qtdeRestante = form.getFieldValue(`qtdeRestante${indice + 1}`);
        //dadosTemp.itensNf[indice].nfi_qtde = form.getFieldValue(`qtdeRestante${indice + 1}`);
        setDadosItens(dadosTemp.itensNf);
        setDadosNfceDevolucao(dadosTemp);
    }

    return (
        <div className="tabela m-t-16">
            <Form name="formularioDevolucaoItens" layout="vertical" form={form}>
                <Table dataSource={dadosItens} pagination={false} columns={[
                    {
                        title: 'Nº',
                        width: 75,
                        align: 'center',
                        render: ({ nfi_numeroitem }) => (
                            <div>
                                <Tag color="processing" className="w-100">
                                    <b>{nfi_numeroitem}</b>
                                </Tag>
                            </div>
                        ),
                    },
                    {
                        title: 'Itens da Nota',
                        render: ({ pro_descricao }) => (
                            <div>
                                <b>{pro_descricao}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'CFOP',
                        align: 'center',
                        width: 100,
                        render: ({ nfi_cfop }) => (
                            <div>
                                <b>{nfi_cfop}</b>
                            </div>
                        ),
                    },

                    {
                        title: 'Quant.',
                        align: 'center',
                        width: 95,
                        render: ({ qtdeRestante, nfi_numeroitem, editando }) => (
                            <div>
                                <Form.Item name={`qtdeRestante${nfi_numeroitem}`} >
                                    {editando ? <InputNumber controls={false} /> : qtdeRestante}
                                </Form.Item>
                            </div>
                        ),
                    },

                    {
                        title: 'Unid.',
                        align: 'center',
                        width: 95,
                        render: ({ nfi_unidademedida }) => (
                            <div>
                                <b>{nfi_unidademedida}</b>
                            </div>
                        ),
                    },

                    {
                        title: 'Unit. (R$)',
                        align: 'right',
                        width: 120,
                        render: ({ nfi_valorunitario }) => (
                            <div>
                                <b>{FormatNumber(nfi_valorunitario, true)}</b>
                            </div>
                        ),
                    },

                    {
                        title: 'Desc. (R$)',
                        align: 'right',
                        width: 120,
                        render: ({ nfi_valordesconto }) => (
                            <div>
                                <b>{FormatNumber(nfi_valordesconto, true)}</b>
                            </div>
                        ),
                    },

                    {
                        title: 'Acresc. (R$)',
                        align: 'right',
                        width: 120,
                        render: ({ nfi_valoroutros }) => (
                            <div>
                                <b>{FormatNumber(nfi_valoroutros, true)}</b>
                            </div>
                        ),
                    },

                    {
                        title: 'Total (R$)',
                        align: 'right',
                        width: 120,
                        render: ({ nfi_valorunitario, qtdeRestante }) => (
                            <div>
                                <b>{FormatNumber(nfi_valorunitario * qtdeRestante, true)}</b>
                            </div>
                        ),
                    },
                    {
                        title: 'Ações',
                        dataIndex: '',
                        align: 'center',
                        fixed: 'right',
                        key: 'x',
                        width: 65,
                        render: ({ editando }, record, idx, indice) => (
                            <div>
                                <Row align="middle" justify="center" gutter={[5, 0]}>
                                    <Col>
                                        {!editando &&
                                            <Button icon={<EditOutlined />} onClick={() => editarItem(record, idx, indice)} />
                                        }
                                        {editando &&
                                            <Button icon={<SaveOutlined />} onClick={() => ajustarQuantidade(record, idx, indice)} />
                                        }
                                    </Col>
                                    <Col>
                                        <Button icon={<DeleteOutlined />} onClick={() => excluirItem(record)} />
                                    </Col>
                                </Row>
                            </div>
                        ),
                    },
                ]} locale={{
                    emptyText: (
                        <Row>
                            <Col span={24}>
                                <Divider orientation="center">
                                    <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                                </Divider>
                            </Col>
                        </Row>
                    )
                }} scroll={{ x: 900 }} />
            </Form >
        </div >
    );
}