import React, { useState, useEffect } from "react";
import { Row, Col, Form, Input, Select, Checkbox, notification, Modal } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";

export default function ModalOutroContato({ form, listaOutrosContatos, exibirModalOutroContato, fecharModalOutroContato }) {

    const [listasTiposContatos, setListasTiposContatos] = useState([]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            zIndex: 2010,
            onOk() {
                form.resetFields();
                fecharModalOutroContato();
            }
        });
    };

    const onFinish = values => {
        let indice = form.getFieldValue().indice;
        delete values.indice;
        let lista = [...listaOutrosContatos.dadosOutrosContatos];
        let nomeLista = listasTiposContatos.filter(tp => tp.toc_id === form.getFieldValue().toc_id);
        values.toc_descricao = nomeLista[0].toc_descricao;
        if (indice >= 0) {
            lista[indice] = values;
        }
        else {
            let verificaOcupacaoAdd = lista.filter(tp => tp.toc_id === form.getFieldValue().toc_id && tp.out_descricao === form.getFieldValue().out_descricao);
            if (verificaOcupacaoAdd.length > 0) {
                fecharModalOutroContato();
                notification.info({ message: 'Contato já adicionada!' });
            } else {
                values.out_ativo = true;
                lista.push(values);
            }
        }
        listaOutrosContatos.setDadosOutrosContatos(lista);
        fecharModalOutroContato();
    };

    useEffect(() => carregarDados(), []);

    function carregarDados() {
        api.get(`TipoOutroContato`).then(res => {
            if (res.status === 200)
                setListasTiposContatos(res.data.items);
        }).catch((erro) => console.error(erro))
    };

    return (
        <Modal centered
            title="Cadastro de Outros Contatos"
            open={exibirModalOutroContato}
            getContainer={false}
            onCancel={() => {
                onCloseModal();
            }}
            onOk={() => form.submit()}
            okText={
                <>
                    <PlusOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <div>
                <Form layout="vertical" name="manutencaoOutroContato" form={form} onFinish={onFinish}>
                    <Row gutter={[8, 0]}>
                        <Form.Item name="pes_id" hidden>
                            <Input />
                        </Form.Item>
                        <Col xs={24} sm={10} md={10} lg={10} xl={10}>
                            <Form.Item label="Tipo de Contato" name="toc_id" rules={[{ required: true, message: 'Selecione o Tipo de Contato' }]}>
                                <Select
                                    showSearch
                                    allowClear
                                    optionFilterProp="children"
                                    placeholder="Selecione um Tipo de Contato">
                                    {listasTiposContatos.map((tc) => (
                                        <Select.Option key={tc.key} value={tc.toc_id} label={tc.toc_descricao}>{tc.toc_descricao}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={14} md={14} lg={14} xl={14}>
                            <Form.Item label="Descrição" name="out_descricao" rules={[{ required: true, message: 'Informe um Contato' }]}>
                                <Input placeholder="Informe um Contato" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Item name="out_ativo" valuePropName="checked">
                                <Checkbox>Ativo</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Modal>
    );
}