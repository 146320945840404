import React, { useEffect, useState } from "react";
import { Row, Col, Card, Typography, Menu, Button, Modal } from "antd";
import { ExclamationCircleOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
import { Icon } from '@ant-design/compatible';

import api from "../../services/api";
import { ModalAberturaCaixa, ModalSuprimentoCaixa, ModalSangriaCaixa, ModalControleCaixa, ModalFechamentoCaixa } from "../../components/modals/";
import { useStateValue } from "../../state";
import { carregarDadosPdv } from "../../services/funcoes";
import { dadosPdvActions } from "../../actions";

export default function OperacoesFiscais() {

    const [{ dadosPdv }, dispatch] = useStateValue();
    const [caixaAberto, setCaixaAberto] = useState(false);
    const [openModalSuprimentoCaixa, setOpenModalSuprimentoCaixa] = useState(false);
    const [openModalSangriaCaixa, setOpenModalSangriaCaixa] = useState(false);
    const [openModalAberturaCaixa, setOpenModalAberturaCaixa] = useState(false);
    const [openModalControleCaixa, setOpenModalControleCaixa] = useState(false);
    const [openModalFechamentoCaixa, setOpenModalFechamentoCaixa] = useState(false);


    useEffect(() => {
        if (!!dadosPdv.pdv) {
            carregarCaixaAberto();
        } else {
            verificaPdv();
        }
    }, [])

    async function verificaPdv() {
        let res = await carregarDadosPdv();
        if (!!res) {
            dispatch({ type: dadosPdvActions.CHANGE, data: { pdv: res } });
            carregarCaixaAberto(res.pdv_id);
        }
    }

    async function carregarCaixaAberto(pdv_id) {
        api.get(`ControleCaixa/ListaControleCaixaAberto/${dadosPdv.pdv?.pdv_id ?? pdv_id}`).then(
            res => {
                if (!res.data) {
                    modalAberturaCaixa();
                } else {
                    setCaixaAberto(true);
                    window.caixaAberto = true;
                }
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }

    function modalAberturaCaixa() {
        Modal.confirm({
            title: 'Abrir Caixa!',
            icon: <ExclamationCircleOutlined />,
            content: 'Não foi encontrado o registro de caixa aberto, abrir caixa?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                setOpenModalAberturaCaixa(true);
            }
        });
    }

    function abrirModalSuprimento() {
        if (caixaAberto) {
            setOpenModalSuprimentoCaixa(true)
        } else {
            modalAberturaCaixa();
        }
    }

    function abrirModalSangria() {
        if (caixaAberto) {
            setOpenModalSangriaCaixa(true)
        } else {
            modalAberturaCaixa();
        }
    }

    return (
        <Card title={
            <Typography.Title style={{ textAlign: 'center' }} level={5}>
                OPERAÇÕES FISCAIS
            </Typography.Title>
        } className="hv-100 menu-operacao">
            <Row>
                <Col span={24}>
                    <Menu mode="inline">
                        <div className="button-opf">
                            <Button type="text" icon={<DoubleRightOutlined />} onClick={() => abrirModalSuprimento()}>
                                SUPRIMENTO DE CAIXA
                            </Button>
                        </div>
                        <div className="button-opf">
                            <Button type="text" icon={<DoubleRightOutlined />} onClick={() => abrirModalSangria()}>
                                SANGRIA
                            </Button>
                        </div>
                        <div className="button-opf">
                            <Button type="text" icon={<DoubleRightOutlined />} onClick={() => setOpenModalControleCaixa(true)}>
                                LISTA DE CAIXAS
                            </Button>
                        </div>
                        {!!caixaAberto &&
                            <div>
                                <Button type="text" icon={<DoubleRightOutlined />} onClick={() => setOpenModalFechamentoCaixa(true)}>
                                    FECHAR CAIXA
                                </Button>
                            </div>}
                    </Menu>
                </Col>
                <Col span={24} className="col-button">
                    {!!caixaAberto === false &&
                        <Button type="primary" block size="large" onClick={() => setOpenModalAberturaCaixa(true)}>
                            <Icon type="Plus" /> ABRIR CAIXA
                        </Button>
                    }
                    {!!caixaAberto === true &&
                        <Button type="primary" block size="large" onClick={() => setOpenModalFechamentoCaixa(true)}>
                            <Icon type="Close" /> FECHAR CAIXA
                        </Button>
                    }
                </Col>
            </Row>
            <ModalAberturaCaixa setCaixaAberto={setCaixaAberto} exibirModalAberturaCaixa={openModalAberturaCaixa} fecharModalAberturaCaixa={() => setOpenModalAberturaCaixa(false)} />
            <ModalControleCaixa exibirModalControleCaixa={openModalControleCaixa} fecharModalControleCaixa={() => setOpenModalControleCaixa(false)} />
            <ModalSuprimentoCaixa exibirModalSuprimentoCaixa={openModalSuprimentoCaixa} fecharModalSuprimentoCaixa={() => setOpenModalSuprimentoCaixa(false)} />
            <ModalSangriaCaixa exibirModalSangriaCaixa={openModalSangriaCaixa} fecharModalSangriaCaixa={() => setOpenModalSangriaCaixa(false)} />
            <ModalFechamentoCaixa setCaixaAberto={setCaixaAberto} exibirModalFechamentoCaixa={openModalFechamentoCaixa} fecharModalFechamentoCaixa={() => setOpenModalFechamentoCaixa(false)} />
        </Card >
    );
}