import React, { useState } from "react";
import { Button, Tooltip } from "antd";

import { AberturaChamado } from "../../components";
import { MdSupportAgent } from "react-icons/md";

export default function BotaoAbrirChamado() {

    const [activeOpen, setActiveOpen] = useState(false);

    const handleOnClickOpen = () => {
        setActiveOpen(true);
    }

    return (
        <>
            <Tooltip title="Abrir chamado para o Suporte" placement="top">
                <Button
                    type="link"
                    size="large"
                    onClick={() => handleOnClickOpen()}
                    style={{ color: "white", fontSize: '26px', verticalAlign: 'middle' }}
                    title="Suporte" // Adiciona o tooltip com a mensagem
                >
                    <MdSupportAgent />
                </Button>
            </Tooltip>
            <AberturaChamado activeOpen={activeOpen} setActiveOpen={setActiveOpen} />
        </>
    );
}