import React from "react";
import { Row, Col, Form, Modal, Descriptions, Input, notification } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";
import { getLocal } from "../../../services/auth";

export default function ModalCancelarVenda({ detalhesNF, exibirModalCancelarVenda, fecharModalCancelarVenda, atualizaLista, setAtualizaLista }) {

    const [form] = Form.useForm();

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalCancelarVenda();
            }
        });
    };

    const inutilizarNota = () => {
        let dados = { ...detalhesNF };
        let mensagemCancelamento = '';
        dados.loc_id = parseInt(getLocal());
        dados.justificativa = form.getFieldValue().justificativaCancelamento;
        if (!dados.justificativa || dados.justificativa.length < 15) {
            notification.warning({ message: 'Aviso!', description: 'A Justificativa deve ter no minimo 15 caracteres!' });
            return false;
        }
        api.post(`NotaFiscal/Cancelar`, dados).then(
            res => {
                if (!!res.data && !!res.data.retEvento && !!res.data.retEvento.length > 0) {
                    mensagemCancelamento = res.data.retEvento[0].infEvento.cStat + '-' + res.data.retEvento[0].infEvento.xMotivo;
                    if (res.data.retEvento[0].infEvento.cStat === 135 || res.data.retEvento[0].infEvento.cStat === 101) {
                        notification.success({ message: `NF-e Nº: ${detalhesNF.ntf_numero} Cancelada com sucesso!` });
                    } else {
                        notification.warning({ message: `Não foi possível efetuar o cancelamento da NF-e Nº: ${detalhesNF.ntf_numero} !`, description: mensagemCancelamento });
                    }
                } else if (!!res.data) {
                    notification.success({ message: `NF-e Nº: ${detalhesNF.ntf_numero} cancelada com sucesso!` });
                }
            }
        ).catch(
            error => {
                notification.warning({ description: `Não foi possível cancelar a NF-e Nº: ${detalhesNF.ntf_numero} !`, message: 'Aviso' });
            }
        ).finally(
            () => {
                form.resetFields();
                setAtualizaLista(true);
                fecharModalCancelarVenda();
            }
        )
    }

    return (
        <Modal centered
            title="Cancelamento da Venda"
            open={exibirModalCancelarVenda}
            getContainer={false}
            onCancel={onCloseModal}
            onOk={() => inutilizarNota()}
            okText={
                <>
                    <PlusOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
            width={720}
        >
            <Descriptions size="small">
                <Descriptions.Item label="Número do Cupom">
                    <b> {detalhesNF.ntf_numero} </b>
                </Descriptions.Item>
                <Descriptions.Item label="Série">
                    <b> {detalhesNF.ntf_serie} - {detalhesNF.ser_serie} </b>
                </Descriptions.Item>
            </Descriptions>
            <Form layout="vertical" name="manutencaoCancelamentoVenda" form={form}>
                <Row>
                    <Col span={24}>
                        <Form.Item label="Justificativa" name="justificativaCancelamento">
                            <Input.TextArea placeholder="Informe a Justificativa" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}