
import { manutencaoActions } from '../actions';
import { getNavegacao, getTela, setNavegacao } from '../services/auth';

export const gravarTela = (caminho, manutencao) => {
    let tela = JSON.parse(getNavegacao());
    if (!!tela.geral && !!tela.geral[caminho]) {
        let constante = {}
        constante = JSON.parse(getTela());
        if (!!constante.formulario && constante.formulario === caminho) {
            if (!!manutencao && !!manutencao.dados) {
                if (!!manutencao.dados.nomeTabela) {
                    constante[manutencao.dados.nomeTabela] = manutencao.dados;
                }
                tela.geral[caminho].dados = JSON.stringify(constante);
            } else {
                tela.geral[caminho].dados = null;
            }
        }
    } else {
        incluirNovo(tela, caminho)
    }
    setNavegacao(tela);
};

export const acrescDados = (caminho, name, dados, dispatch, manutencao = null) => {
    buscarTela(caminho);
    let tela = JSON.parse(getNavegacao());
    let constante = {};
    if (!!tela.geral[caminho] && !!tela.geral[caminho].dados) {
        constante = JSON.parse(tela.geral[caminho].dados);
        constante[name] = dados;
    } else {
        incluirNovo(tela, caminho);
        if (!!tela.geral[caminho] && !!tela.geral[caminho].dados) {
            constante = JSON.parse(tela.geral[caminho].dados);
            constante[name] = dados;
        }
    }
    if (!!tela.geral[caminho]) {
        tela.geral[caminho].dados = JSON.stringify(constante);
        setNavegacao(tela);
    }
    dados.nomeTabela = name;
    let listaTbDados = !!manutencao?.listaTabelaDados ? [...manutencao.listaTabelaDados] : [];
    let listaSelPg = !!manutencao?.listaSelectPaginacao ? [...manutencao.listaSelectPaginacao] : [];
    dispatch({ type: manutencaoActions.CHANGE, data: { dados: dados, listaTabelaDados: listaTbDados, listaSelectPaginacao: listaSelPg } });
};

function incluirNovo(tela, caminho) {
    let dadosTela = getTela();
    if (!!dadosTela) {
        if (!!tela.geral) {
            tela.geral[caminho] = { 'Aberto': true };
            if (JSON.parse(dadosTela).formulario === caminho) {
                tela.geral[caminho].dados = dadosTela;
            }
        }
    }
};

export const buscarTela = (caminho) => {
    var tela = JSON.parse(getNavegacao());
    if (!!tela.geral[caminho]) {
        return tela.geral[caminho].dados;
    } else {
        gravarTela(caminho);
        if (getTela()) {
            buscarTela(caminho);
        }
    }
};

export const limparTela = (caminho, todos = false) => {
    var tela = JSON.parse(getNavegacao());
    if (todos) {
        setNavegacao({ geral: {} });
    } else {
        if (!!tela.geral[caminho]) {
            delete tela.geral[caminho].dados;
            setNavegacao(tela);

        }
    }
};