import React, { useState, useEffect } from "react";
import { Row, Col, Form, Select, notification, Modal } from "antd";
import { ExclamationCircleOutlined, PlusOutlined, CloseOutlined } from "@ant-design/icons";

import api from "../../../services/api";

export default function ModalOcupacao({ form, listaOcupacoes, exibirModalOcupacao, fecharModalOcupacao }) {

    const [listasDeOcupacoes, setListasDeOcupacoes] = useState([]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            zIndex: 2010,
            onOk() {
                form.resetFields();
                fecharModalOcupacao();
            }
        });
    };

    const onFinish = values => {
        let indice = form.getFieldValue().indice;
        delete values.indice;
        let lista = [...listaOcupacoes.dadosOcupacoes];
        let nomeLista = listasDeOcupacoes.filter(o => o.cbo_id === form.getFieldValue().cbo_id);
        values.cbo_descricao = nomeLista[0].cbo_descricao;
        if (indice >= 0) {
            lista[indice] = values;
        } else {
            let verificaOcupacaoAdd = lista.filter(o => o.cbo_id === form.getFieldValue().cbo_id);
            if (verificaOcupacaoAdd.length > 0) {
                onCloseModal();
                notification.info({ message: 'Ocupação já adicionada!' });
            } else {
                if (lista.length > 0) {
                    values.poc_principal = false;
                } else {
                    values.poc_principal = true;
                }
                lista.push(values);
            }
        }
        listaOcupacoes.setDadosOcupacoes(lista);
        fecharModalOcupacao();
    };

    useEffect(() => carregarDados(), []);

    function carregarDados() {
        api.get(`Cbo/Listar`).then(
            res => {
                if (res.status === 200)
                    setListasDeOcupacoes(res.data.items);
            }
        ).catch(
            (erro) => console.error(erro)
        );
    };

    return (
        <Modal centered
            title="Cadastro de Ocupações"
            open={exibirModalOcupacao}
            getContainer={false}
            onCancel={() => {
                onCloseModal();
            }}
            onOk={() => form.submit()}
            okText={
                <>
                    <PlusOutlined /> Salvar
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            } 
            maskClosable={false}
        >
            <Form layout="vertical" name="manutencaoOcupacao" form={form} onFinish={onFinish}>
                <Row align="middle" gutter={[8, 8]}>
                    <Col span={24}>
                        <Form.Item label="Ocupação" name="cbo_id">
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                placeholder="Selecione uma Ocupação"
                            >
                                {listasDeOcupacoes.map((ocup) => (
                                    <Select.Option value={ocup.cbo_id} key={ocup.key} label={ocup.cbo_descricao}>
                                        {ocup.cbo_id} - {ocup.cbo_descricao}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
}