import React, { useEffect, useState } from "react";
import { Row, Col, Form, Select, Input, Modal, Typography, notification } from "antd";
import { ExclamationCircleOutlined, CheckOutlined, CloseOutlined } from "@ant-design/icons";
import api from "../../../services/api";
import { Cedulas, InputPreco } from "../../../components";
import { useStateValue } from "../../../state";
import { carregarDadosPdv } from "../../../services/funcoes";
import { dadosPdvActions } from "../../../actions";

export default function ModalSangriaCaixa({ exibirModalSangriaCaixa, fecharModalSangriaCaixa }) {

    const [{ dadosPdv }, dispatch] = useStateValue();
    const [form] = Form.useForm();
    const [parametrosEmpresa, setParametrosEmpresa] = useState({});
    const [listaContaGerencial, setListaContaGerencial] = useState([]);

    const onCloseModal = () => {
        Modal.confirm({
            title: 'Cancelar?',
            icon: <ExclamationCircleOutlined />,
            content: 'Deseja cancelar a operação?',
            okText: 'Sim',
            cancelText: 'Não',
            centered: true,
            onOk() {
                fecharModalSangriaCaixa();
            }
        });
    };

    useEffect(() => {
        if (exibirModalSangriaCaixa) {
            api.get(`ParametroEmpresa/BuscarParametros`).then(
                res => {
                    setParametrosEmpresa(res.data);
                    carregarDados();
                }
            ).catch(
                error => {
                    console.log(error);
                }
            )
        }
    }, [exibirModalSangriaCaixa]);

    useEffect(() => {
        if (!!listaContaGerencial && listaContaGerencial.length > 0) {
            if (!!parametrosEmpresa.par_contagerencialsangriapdv) {
                form.setFieldsValue({ ctg_id: parametrosEmpresa.par_contagerencialsangriapdv });
            }
        }
    }, [listaContaGerencial])

    function carregarDados() {
        api.get(`ContaGerencial/ListarAtivos`).then(
            res => {
                setListaContaGerencial(res.data);
            }
        ).catch(
            error => {
                console.log(error);
            }
        )
    }

    useEffect(() => {
        if (!!!dadosPdv.pdv) {
            verificaPdv();
        }
    }, [])

    async function verificaPdv() {
        let res = await carregarDadosPdv();
        dispatch({ type: dadosPdvActions.CHANGE, data: { pdv: res } });
    }

    function salvarSangria() {
        let dados = form.getFieldsValue();
        let dadosConta = listaContaGerencial.filter((item) => (item.ctg_id === dados.ctg_id))[0];
        dados.mcc_entradasaida = 1;
        dados.ctg = {
            ctg_descricao: dadosConta.ctg_descricao,
            ctg_id: dadosConta.ctg_id
        }
        dados.ctc_id = dadosPdv.pdv?.ctc_id;
        dados.mcc_valor = parseFloat(dados.mcc_valor);
        dados.mcc_complemento = !!dados.mcc_complemento ? dados.mcc_complemento : '';
        api.post(`MovimentoContaCorrente/IncluirLancamentoCaixa`, dados).then(
            res => {
                notification.success({ message: 'Sucesso!', description: 'Sangria realizada com Sucesso!' });
            }
        ).catch(
            error => {
                console.log(error);
                notification.warning({ message: 'Aviso!', description: 'Erro ao realizar a sangria de caixa!' });
            }
        ).finally(() => {
            form.resetFields();
            fecharModalSangriaCaixa();
        })
    }

    return (
        <Modal centered
            title="Sangria do Caixa"
            open={exibirModalSangriaCaixa}
            onCancel={onCloseModal}
            onOk={() => salvarSangria()}
            getContainer={false}
            okText={
                <>
                    <CheckOutlined /> Ok
                </>
            }
            cancelText={
                <>
                    <CloseOutlined /> Cancelar
                </>
            }
            maskClosable={false}
        >
            <div className="col-modal">
                <Form layout="vertical" name="manutencaoSangriaCaixa" form={form}>
                    <Row>
                        <Col span={24}>
                            <Typography.Text>
                                Informe os dados para a sangria do caixa.
                            </Typography.Text>
                        </Col>
                    </Row>
                    <Row gutter={[8, 0]} className="m-t-8">
                        <Col span={8}>
                            <InputPreco label="Valor (R$)" name="mcc_valor" />
                        </Col>
                        <Col span={16}>
                            <Form.Item label="Conta Gerencial" name="ctg_id" rules={[{ required: true, message: 'Informa uma Conta Gerencial' }]}>
                                <Select allowClear showsearch optionFilterProp="children" placeholder="Informe a Conta Gerencial">
                                    {listaContaGerencial.map((item) => (
                                        <Select.Option value={item.ctg_id} key={item.ctg_id} label={item.ctg_descricao}>{item.ctg_descricao}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="Observação">
                                <Input.TextArea placeholder="Informe uma Observação" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Cedulas />
                </Form>
            </div>
        </Modal>
    );
}