import React from "react";
import { Row, Col, Table, Divider } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

import { FormatNumber } from '../../ValueObjects';

export default function TabelaFormaPagamento({ formasPagamentos }) {

    return (
        <div className="tabela m-t-16">
            <Table columns={[
                {
                    title: 'Forma de Pagamento',
                    render: ({ fpg_descricao, cpg_descricao }) => (
                        <div>
                            <Row align="middle" gutter={[5, 0]}>
                                <Col>
                                    <b>{fpg_descricao} {!!cpg_descricao ? ` - ${cpg_descricao}` : ''}</b>
                                </Col>
                            </Row>
                        </div>
                    ),
                },
                {
                    title: 'Troco (R$)',
                    align: 'right',
                    render: ({ nfp_valortroco }) => (
                        <div>
                            <b>{FormatNumber(nfp_valortroco, true)}</b>
                        </div>
                    ),
                },
                {
                    title: 'Total (R$)',
                    align: 'right',
                    render: ({ nfp_valor, nfp_valortroco }) => (
                        <div>
                            <b>{FormatNumber(nfp_valor - nfp_valortroco, true)}</b>
                        </div>
                    ),
                },
            ]} dataSource={formasPagamentos} pagination={false} locale={{
                emptyText: (
                    <Row>
                        <Col span={24}>
                            <Divider orientation="center">
                                <ExclamationCircleOutlined /> Nenhum Registro Encontrado
                            </Divider>
                        </Col>
                    </Row>
                )
            }} />
        </div>
    );
}